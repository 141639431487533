import { defineStore } from 'pinia';

export enum CollectionStatus {
  Normal = 'normal',
  WIP = 'wip',
}

export interface CollectionChild {
  title: string;
  titleCn: string;
  id: string;
  lastUpdated: string;
  wipId: string;
  CollectionStatus: CollectionStatus;
}
export interface Collection {
  category: string;
  id: string;
  children?: CollectionChild[];
}

export interface Category {
  id: number;
  created_at: string;
  updated_at: string;
  category_name: string;
}

export const useCollectionDrawerData = defineStore('collectionDrawerData', {
  state: () => ({
    collections: [] as Collection[],
    categories: [] as Category[],
    currentSelectedCollection: {} as Collection,
    currentSelectedCategory: '',
  }),
  getters: {},
  actions: {
    setCollectionDrawerData(collections: Collection[]) {
      this.collections = collections;
    },
    addCollectionDrawerData(collection: Collection) {
      // check if category exist, push all children to it
      const categoryIndex = this.collections.findIndex((category) => category.category === collection.category);
      if (categoryIndex !== -1) {
        this.collections[categoryIndex].children?.push(...(collection.children ?? []));
      } else {
        this.collections.push(collection);
      }
    },
    setCurrentSelectedCollection(collection: Collection) {
      this.currentSelectedCollection = collection;
    },
    setCurrentSelectedCategory(category: string) {
      this.currentSelectedCategory = category;
    },
    setCategories(categories: Category[]) {
      this.categories = categories;
    },
    clearCategories() {
      this.categories = [];
    },
    getLastUpdatedByTitle(titleToFind: string): string | null {
      for (const collection of this.collections) {
        if (collection.children) {
          const matchingChild = collection.children.find((child) => child.title === titleToFind);
          if (matchingChild) {
            return matchingChild.lastUpdated;
          }
        }
      }
      return null;
    },
    getChildByWipId(wipId: string): Collection | null {
      for (const collection of this.collections) {
        if (collection.children) {
          const childrenWithWipId = collection.children.filter((child) => child.wipId === wipId);
          if (childrenWithWipId.length > 0) {
            return {
              category: collection.category,
              id: collection.id,
              children: childrenWithWipId,
            };
          }
        }
      }
      return null;
    },
    getChildByCollectionName(collectionName: string): Collection | null {
      for (const collection of this.collections) {
        if (collection.children && collection.category !== 'work in progress') {
          const childrenWithCollectionName = collection.children.filter((child) => child.title === collectionName);
          if (childrenWithCollectionName.length > 0) {
            return {
              category: collection.category,
              id: collection.id,
              children: childrenWithCollectionName,
            };
          }
        }
      }
      return null;
    },
  },
});
