<template>
  <div v-if="isShow" class="full-page-mask">
    <div class="loading-block">
      <XStatus type="loading">{{ message }}</XStatus>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { XStatus } from '@asus-aics/xui';
export default defineComponent({
  name: 'FullPageLoading',
  components: { XStatus },
  props: {
    isShow: {
      type: Boolean,
      required: true,
    },
    message: {
      type: String,
      default: '',
    },
  },
  setup() {
    return {};
  },
});
</script>

<style scoped lang="scss">
.full-page-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-block {
  border-radius: 16px;
  background-color: var(--black-and-white-white, #fff);
  display: flex;
  padding: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 14px;
}
</style>
