<template>
  <XNavDrawer :model-value="nodeGroup" :auto-active="autoActiveCollection" :active-key="activeKey"></XNavDrawer>
</template>

<script lang="ts">
import { defineComponent, computed, ComputedRef, ref, watch, nextTick } from 'vue';
import { XNavDrawer, XNavDrawerNodeGroup, XNavDrawerNode } from '@asus-aics/xui';
import { Collection, useCollectionDrawerData } from '@/store/collectionDrawerData';
import { storeToRefs } from 'pinia';
import { useContentPageDataStore } from '@/store/contentPageData';
import { scrollToActiveCollection, expandCollection } from '@/utils/shared/drawerUtils';

export default defineComponent({
  name: 'CollectionDrawer',
  components: { XNavDrawer },
  props: {
    activeKey: {
      type: String,
      default: '0',
    },
  },
  emits: ['load-page', 'load-page-code-generate'],
  setup(_, { emit }) {
    const collectionDrawerDataStore = useCollectionDrawerData();
    const contentPageDataStore = useContentPageDataStore();
    const { collections, categories, currentSelectedCategory } = storeToRefs(collectionDrawerDataStore);
    const { contents } = storeToRefs(contentPageDataStore);

    const addEmptyCategories = (nodes: XNavDrawerNode[]): XNavDrawerNodeGroup[] => {
      const emptyCategories = categories.value
        .filter((category) => {
          return !collections.value.some((collection) => {
            return collection.category === category.category_name;
          });
        })
        .map((category) => {
          return {
            key: `${category.category_name}/`,
            name: category.category_name,
            callback: () => {
              // do nothing (because it needs callback to use right css class)
            },
            children: [],
          };
        });

      return [
        {
          nodes: [...nodes, ...emptyCategories],
        },
      ];
    };

    const collectionsToXNavDrawerNodeGroup = (collections: Collection[]): XNavDrawerNodeGroup[] => {
      const nodes: XNavDrawerNode[] = collections.map((_collection) => {
        const collection = {
          key: `${_collection.id}/`,
          name: _collection.category,
          callback: () => {
            // do nothing (because it needs callback to use right css class)
          },
        };
        if (_collection.children) {
          return {
            ...collection,
            children: _collection.children.map((child) => {
              const name = child.titleCn ? `${child.title} (${child.titleCn})` : child.title;
              return {
                key: `${_collection.id}/${child.id}`,
                name,
                callback: () => {
                  loadPage(_collection, child.title, child.titleCn, child.wipId, child.lastUpdated);
                },
              };
            }),
          };
        } else {
          return collection;
        }
      });

      return addEmptyCategories(nodes);
    };

    const autoActiveCollection = ref('');
    const nodeGroup: ComputedRef<XNavDrawerNodeGroup[]> = computed(() => {
      if (collections.value.length === 0) {
        return [];
      }
      return collectionsToXNavDrawerNodeGroup(collections.value);
    });

    const loadPage = (
      collection: Collection,
      childTitle: string,
      childTitleCn: string,
      wipId: string,
      lastUpdated: string
    ) => {
      collectionDrawerDataStore.setCurrentSelectedCollection(collection);
      emit('load-page', collection.category, childTitle, childTitleCn, undefined, wipId, lastUpdated);
    };

    const loadPageCodeGenerate = (collection: Collection, childTitle: string, childTitleCn: string, wipId: string) => {
      emit('load-page-code-generate', collection.category, childTitle, childTitleCn, wipId);
    };

    watch(
      () => [contents.value.collectionName, contents.value.collectionNameCn],
      () => {
        if (!contents.value.collectionName) {
          return;
        }
        expandCollection(currentSelectedCategory.value);
        nextTick(() => {
          if (contents.value.wipName) {
            const name = contents.value.collectionNameCn
              ? `${contents.value.wipName} (${contents.value.collectionNameCn})`
              : contents.value.wipName;
            scrollToActiveCollection(name);
          } else {
            const name = contents.value.collectionNameCn
              ? `${contents.value.collectionName} (${contents.value.collectionNameCn})`
              : contents.value.collectionName;
            scrollToActiveCollection(name);
          }
        });
      }
    );

    return {
      nodeGroup,
      autoActiveCollection,
      loadPageCodeGenerate,
    };
  },
});
</script>
