import { XNotificationNewCaller, XNotificationType } from '@asus-aics/xui';

const useNotification = () => {
  const showNotification = (type: XNotificationType, title: string) => {
    const id = Date.now();
    XNotificationNewCaller({
      id,
      type,
      placement: 'top-right',
      title,
      noAutoHide: false,
      autoHideDelay: 2000,
      hasCloseButton: true,
    });
  };
  return { showNotification };
};
export { useNotification };
