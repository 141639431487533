import { v4 as uuidv4 } from 'uuid';

interface ProcessedSearchText {
  text: string;
  match: boolean;
  key: string;
}

const processSearchText = (searchText: string): ProcessedSearchText[] => {
  const results: ProcessedSearchText[] = [];
  const pattern = /<b>(.*?)<\/b>/gi;
  let lastIndex = 0;
  let match;

  while ((match = pattern.exec(searchText)) !== null) {
    if (match.index > lastIndex) {
      results.push({
        text: searchText.substring(lastIndex, match.index),
        match: false,
        key: uuidv4(),
      });
    }

    results.push({
      text: match[1],
      match: true,
      key: uuidv4(),
    });

    lastIndex = pattern.lastIndex;
  }

  if (lastIndex < searchText.length) {
    results.push({
      text: searchText.substring(lastIndex),
      match: false,
      key: uuidv4(),
    });
  }

  return results;
};

export { processSearchText };
