import { defineStore } from 'pinia';

export const useTwoFaData = defineStore('twoFaData', {
  state: () => ({
    firstPartCode: '',
    secondPartCode: '',
  }),
  getters: {},
  actions: {
    setFirstPartCode(firstPartCode: string) {
      this.firstPartCode = firstPartCode;
    },
    setSecondPartCode(secondPartCode: string) {
      this.secondPartCode = secondPartCode;
    },
    clearSecondPartCode() {
      this.secondPartCode = '';
    },
  },
});
