import { XTableEntryOption } from '@asus-aics/xui';

export const resourceContentTableColSchema: XTableEntryOption[] = [
  {
    index: 'propertyName',
    align: 'left',
    title: 'Property Name',
    width: '266px',
    // cellStyle: { padding: '0 8px' },
    cellStyle: { minHeight: '48px', cursor: 'pointer' },
    headStyle: { padding: '0 8px' },
  },
  {
    index: 'propertyNameCn',
    align: 'left',
    title: 'Property Name(CN)',
    width: '178px',
    cellStyle: { padding: '0 4px', minHeight: '48px', cursor: 'pointer' },
    headStyle: { padding: '0 4px' },
  },
  {
    index: 'type',
    title: 'Type',
    align: 'left',
    width: '88px',
    cellStyle: { padding: '0 4px', minHeight: '48px', cursor: 'pointer' },
    headStyle: { padding: '0 4px' },
  },
  {
    index: 'required',
    align: 'left',
    title: 'Required',
    width: '77px',
    cellStyle: { padding: '0 4px', minHeight: '48px', cursor: 'pointer' },
    headStyle: { padding: '0 4px' },
  },
  {
    index: 'source',
    title: 'Source',
    align: 'left',
    width: '220px',
    cellStyle: { padding: '0 4px', minHeight: '48px', cursor: 'pointer' },
    headStyle: { padding: '0 4px' },
  },
  {
    index: 'sourceNote',
    title: 'Source Note',
    align: 'left',
    width: '329px',
    cellStyle: { padding: '0 4px', minHeight: '48px', cursor: 'pointer' },
    headStyle: { padding: '0 4px' },
  },
  {
    index: 'note',
    title: 'Note',
    align: 'left',
    width: 'auto',
    cellStyle: { padding: '0 4px', minHeight: '48px', cursor: 'pointer' },
    headStyle: { padding: '0 4px' },
  },
  {
    index: 'tags',
    title: 'Tags',
    align: 'left',
    width: '68px',
    cellStyle: { padding: '0 4px', minHeight: '48px', cursor: 'pointer' },
    headStyle: { padding: '0 4px' },
  },
  {
    index: 'action',
    title: '',
    align: 'middle',
    width: '40px',
    cellStyle: { padding: '0 4px', minHeight: '48px' },
    headStyle: { padding: '0 4px' },
  },
];
