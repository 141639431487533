<template>
  <div class="full-page-container">
    <LoginModal
      v-if="activeModal === 'login'"
      @forgot-password-clicked="setModal('forgetPassword')"
      @sign-up-clicked="setModal('signUp')"
      @show-two-fa-modal="(firstPartCode: string, action: string) => showTwoFAModal(firstPartCode, action)"
    />
    <TwoFAModal
      v-if="activeModal === 'twoFA'"
      :from-action="activeTwoFaType"
      @back-to-home="setModal('login')"
      @show-success-registration-modal="setModal('successRegistration')"
    />
    <ForgetPasswordModal v-if="activeModal === 'forgetPassword'" @back-to-home="setModal('login')" />
    <SignUpModal
      v-if="activeModal === 'signUp'"
      @back-to-home="setModal('login')"
      @show-two-fa-modal="(firstPartCode: string, action: string) => showTwoFAModal(firstPartCode, action)"
    />
    <SuccessRegistrationModal v-if="activeModal === 'successRegistration'" @back-to-home="setModal('login')" />
    <TooManyAttemptsModal
      :show="showExceedAttemptsModal"
      :primary-action="
        () => {
          setModal('login');
          closeModal();
        }
      "
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

import LoginModal from '@/components/views/LoginPage/LoginModal.vue';
import TwoFAModal from '@/components/views/LoginPage/TwoFAModal.vue';
import ForgetPasswordModal from '@/components/views/LoginPage/ForgetPasswordModal.vue';
import TooManyAttemptsModal from '@/components/views/LoginPage/TooManyAttemptsModal.vue';
import SignUpModal from '@/components/views/LoginPage/SignUpModal.vue';
import SuccessRegistrationModal from '@/components/views/LoginPage/SuccessRegistrationModal.vue';
import { useTwoFaData } from '@/store/views/LoginPage/twoFaData';
import { useTooManyAttemptsModal } from '@/composables/views/LoginPage/useTooManyAttemptsModal';

export default defineComponent({
  name: 'LoginPage',
  components: {
    LoginModal,
    TwoFAModal,
    ForgetPasswordModal,
    TooManyAttemptsModal,
    SignUpModal,
    SuccessRegistrationModal,
  },
  setup() {
    const { showModal: showExceedAttemptsModal, closeModal } = useTooManyAttemptsModal();

    const twoFaDataStore = useTwoFaData();
    const { setFirstPartCode, clearSecondPartCode } = twoFaDataStore;

    type ModalType = 'login' | 'twoFA' | 'forgetPassword' | 'signUp' | 'successRegistration';
    const activeModal = ref<ModalType>('login');

    type TwoFaType = 'login' | 'signUp';
    const activeTwoFaType = ref<TwoFaType>('login');

    const setModal = (modal: ModalType) => {
      activeModal.value = modal;
    };

    // Currently, we only have twoFA modal for login and sign up, but in api definition we have twoFA for forget password too
    // For now, we just use login and sign up, but in the future if we need to develop new feature, make sure to add new modal type here
    const showTwoFAModal = (firstPartCode: string, modalType: string) => {
      setFirstPartCode(firstPartCode);
      clearSecondPartCode();
      switch (modalType) {
        case 'login':
          activeTwoFaType.value = 'login';
          break;
        case 'sign_up':
          activeTwoFaType.value = 'signUp';
          break;
        default:
          break;
      }
      setModal('twoFA');
    };

    return { activeModal, setModal, showTwoFAModal, showExceedAttemptsModal, closeModal, activeTwoFaType };
  },
});
</script>

<style scoped lang="scss">
.full-page-container {
  width: 100vw;
  min-height: 100vh;
  padding: 24px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('/login-background.jpg');
  background-size: 100vw 100vh;
  background-attachment: fixed;
}
</style>
