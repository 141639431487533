import { defineStore } from 'pinia';

export const useLoginUserDataStore = defineStore('loginUserData', {
  state: () => ({
    email: '',
    flowToken: '',
  }),
  getters: {},
  actions: {
    setEmail(email: string) {
      this.email = email;
    },
    setFlowToken(flowToken: string) {
      this.flowToken = flowToken;
    },
  },
});
