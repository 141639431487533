import { XTableEntryOption } from '@asus-aics/xui';

function sortByAlphabet(cellA: string, cellB: string): number {
  if (cellA === undefined || cellB === undefined) {
    return 0;
  }

  return cellA.localeCompare(cellB);
}

export const collectionListTableColSchema: XTableEntryOption[] = [
  {
    index: 'category',
    align: 'left',
    title: 'Category',
    width: '300px',
    sort: sortByAlphabet,
    cellStyle: { minHeight: '48px' },
    headStyle: { padding: '0 8px' },
  },
  {
    index: 'collection',
    align: 'left',
    title: 'Collection',
    width: '400px',
    sort: sortByAlphabet,
    cellStyle: { padding: '0 4px', minHeight: '48px' },
    headStyle: { padding: '0 4px' },
  },
  {
    index: 'collectionCn',
    title: 'Collection (CN)',
    align: 'left',
    width: '400px',
    sort: sortByAlphabet,
    cellStyle: { padding: '0 4px', minHeight: '48px' },
    headStyle: { padding: '0 4px' },
  },
  {
    index: 'lastUpdated',
    align: 'left',
    title: 'Last Updated',
    width: '1fr',
    sort: sortByAlphabet,
    cellStyle: { padding: '0 4px', minHeight: '48px' },
    headStyle: { padding: '0 4px' },
  },
];
