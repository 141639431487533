<template>
  <div class="content-tab-wrapper" ref="contentTabWrapper">
    <div class="text-block">
      <span class="xv-body--body-md medium-emphasis-text">1.1 Scope and Usage</span>
      <template v-if="!isEditing">
        <span class="xv-body--body-md" style="white-space: pre-wrap">
          <template v-for="(textObject, index) in scopeAndUsageText" :key="index">
            <div
              v-if="textObject.isHyperlink"
              v-dompurify-html="getATagHtml(textObject.text, textObject.url)"
              class="hyperlink"
            ></div>
            <span v-else class="xv-body--body-md high-emphasis-text">{{ textObject.text }}</span>
          </template>
        </span>
      </template>
      <template v-else>
        <XTextArea
          class="long-text-area"
          :class="{ 'error-border': editingContents.scopeAndUsageError }"
          :style="{ borderColor: editingContents.scopeAndUsageError ? 'rgb(var(--x-select-error))' : '' }"
          placeholder="Scope and Usage"
          :model-value="editingContents.scopeAndUsage"
          @update:model-value="
            (value) => {
              editingContents.scopeAndUsage = value;
              validateScopeAndUsage();
            }
          "
        ></XTextArea>
        <XErrorMessage v-if="editingContents.scopeAndUsageError" :message="editingContents.scopeAndUsageErrorMessage" />
      </template>
    </div>
    <XDivider />
    <div class="text-block">
      <span class="xv-body--body-md medium-emphasis-text">1.2 Background and Context</span>
      <template v-if="!isEditing">
        <span class="xv-body--body-md" style="white-space: pre-wrap">
          <template v-for="(textObject, index) in backgroundAndContextText" :key="index">
            <div
              v-if="textObject.isHyperlink"
              v-dompurify-html="getATagHtml(textObject.text, textObject.url)"
              class="hyperlink"
            ></div>
            <span v-else class="xv-body--body-md high-emphasis-text">{{ textObject.text }}</span>
          </template>
        </span>
      </template>
      <template v-else>
        <XTextArea
          class="long-text-area"
          placeholder="Background and Context"
          :class="{ 'error-border': editingContents.backgroundAndContextError }"
          :style="{ borderColor: editingContents.backgroundAndContextError ? 'rgb(var(--x-select-error))' : '' }"
          :model-value="editingContents.backgroundAndContext"
          @update:model-value="
            (value) => {
              editingContents.backgroundAndContext = value;
              validateBackgroundAndContext();
            }
          "
        ></XTextArea>
        <XErrorMessage
          v-if="editingContents.backgroundAndContextError"
          :message="editingContents.backgroundAndContextErrorMessage"
        />
      </template>
    </div>
    <XDivider />
    <div class="text-block">
      <span class="xv-body--body-md medium-emphasis-text">1.3 Service to this Resource</span>
      <span v-if="!isEditing" class="xv-body--body-md high-emphasis-text" style="white-space: pre-wrap">
        {{ serviceToThisResourceText }}
      </span>
      <template v-else>
        <XTextArea
          class="short-text-area"
          :class="{ 'error-border': editingContents.serviceToThisResourceError }"
          :style="{ borderColor: editingContents.serviceToThisResourceError ? 'rgb(var(--x-select-error))' : '' }"
          placeholder="Service to this Resource"
          :model-value="editingContents.serviceToThisResource"
          @update:model-value="
            (value) => {
              editingContents.serviceToThisResource = value;
              validateServiceToThisResource();
            }
          "
        ></XTextArea>
        <XErrorMessage
          v-if="editingContents.serviceToThisResourceError"
          :message="editingContents.serviceToThisResourceErrorMessage"
        />
      </template>
    </div>
    <XDivider />
    <div class="text-block">
      <div class="text-title-with-button">
        <span class="xv-body--body-md medium-emphasis-text">1.4 Index</span>
        <XButton v-if="isEditing && isWip" @click="addNewIndexAndScrollToBottom" icon="add" size="sm" outline
          >New Index</XButton
        >
      </div>
      <template v-if="isEditing && isWip">
        <TransitionGroup v-if="editingContents.index.length > 0" name="index-list">
          <div
            v-for="(contentIndex, index) in editingContents.index"
            class="edit-index-container"
            :key="contentIndex.uuid"
          >
            <div class="edit-index-item">
              <div class="edit-index-name-row">
                <span class="xv-body--body-md medium-emphasis-text edit-index-input-height edit-index-leading-number">{{
                  index + 1
                }}</span>
                <div class="edit-index-text-with-input" :style="{ minWidth: '386px' }">
                  <span class="xv-body--body-md high-emphasis-text">Name</span>
                  <XInputText
                    placeholder="Enter Index Name"
                    :model-value="contentIndex.useDefaultName ? '' : contentIndex.name"
                    :error="contentIndex.nameError"
                    :message="contentIndex.nameErrorMessage"
                    @update:model-value="
                      (value) => {
                        contentIndex.name = value;
                        validateIndexName(index);
                      }
                    "
                    :disabled="contentIndex.useDefaultName"
                  ></XInputText>
                </div>
                <div class="edit-index-check-box-container edit-index-input-height">
                  <XCheckbox v-model="contentIndex.useDefaultName">Use default name</XCheckbox>
                  <XCheckbox v-model="contentIndex.unique">Unique</XCheckbox>
                </div>
                <div class="edit-index-text-with-input" :style="{ width: '162px' }">
                  <span class="xv-body--body-md high-emphasis-text">expireAfterSeconds</span>
                  <XInputText
                    type="number"
                    placeholder="Enter number"
                    :error="contentIndex.expireAfterSecondsError"
                    :model-value="contentIndex.expireAfterSeconds"
                    @update:model-value="
                      (value) => {
                        onExpireAfterSecondsChange(index, value);
                      }
                    "
                  ></XInputText>
                </div>
                <XIconButton @click="() => showDeleteIndexModal(index)" icon="delete" text="" size="md" />
              </div>
            </div>
            <TransitionGroup name="index-list">
              <div v-for="(field, fieldIndex) in contentIndex.fields" class="edit-index-field-row" :key="field.uuid">
                <div class="edit-index-text-with-input" :style="{ minWidth: '370px' }">
                  <span v-if="fieldIndex === 0" class="xv-body--body-md high-emphasis-text">Field</span>
                  <XSelect
                    class="x-input"
                    min-width="370px"
                    menu-min-width="370px"
                    :data-options="fieldList"
                    :model-value="field.name"
                    :fallback-label="field.doesFieldExist ? '' : field.name"
                    @update:model-value="(value: string) => {(field.name = value); field.doesFieldExist = true;validateField(index, fieldIndex, value)}"
                    :error="field.isError"
                    :style="{
                      'border-radius': 'var(--x-input-bd-radius)',
                      border: field.isError ? '' : 'var(--x-input-bd)',
                      background: 'var(--x-input-bg)',
                    }"
                  />
                  <XErrorMessage v-if="field.isError" :message="field.errorMessage" />
                </div>
                <div class="edit-index-text-with-input" :style="{ minWidth: '162px' }">
                  <span v-if="fieldIndex === 0" class="xv-body--body-md high-emphasis-text">Direction</span>
                  <XSelect
                    class="x-input"
                    min-width="162px"
                    menu-min-width="162px"
                    :data-options="sortList"
                    v-model="field.sort"
                    style="
                      border-radius: var(--x-input-bd-radius);
                      border: var(--x-input-bd);
                      background: var(--x-input-bg);
                    "
                  />
                </div>
                <div class="edit-index-text-with-input" :style="{ minWidth: '162px' }">
                  <span v-if="fieldIndex === 0" class="xv-body--body-md high-emphasis-text">　</span>
                  <XIconButton @click="showDeleteFieldModal(index, fieldIndex)" icon="delete" text="" size="md" />
                </div>
              </div>
            </TransitionGroup>
            <XButton
              v-if="isEditing && isWip"
              @click="(event) => addNewFieldAndScrollToButton(index, event)"
              class="edit-index-add-field-button"
              icon="add"
              size="sm"
              outline
              >New Field</XButton
            >
          </div>
        </TransitionGroup>
        <span v-else class="low-emphasis-text">No items</span>
      </template>
      <template v-else-if="indexTextWithUuid.length > 0">
        <div class="index-container">
          <div v-for="indexText in indexTextWithUuid" :key="indexText.id">
            <div class="index-name-row">
              <span
                class="xv-body--body-md medium-emphasis-text"
                :style="{
                  width: '48px',
                }"
                >Name</span
              >
              <span class="xv-body--body-md high-emphasis-text" style="white-space: pre-wrap">{{
                indexText.name +
                (indexText.unique ? ' (unique)' : '') +
                (indexText.expireAfterSeconds ? ` (expireAfterSeconds: ${indexText.expireAfterSeconds})` : '') +
                '\n'
              }}</span>
            </div>
            <div class="fields-and-text">
              <span
                class="xv-body--body-md medium-emphasis-text"
                :style="{
                  width: '48px',
                }"
                >Fields</span
              >
              <div class="field-container">
                <div class="field-and-error xv-body--body-md" v-for="field in indexText.fields">
                  <span :class="{ 'error-text': !field.doesFieldExist }">{{
                    field.name + '(' + field.sort + ')' + '\n'
                  }}</span>
                  <div v-if="!field.doesFieldExist" class="field-error-icon-text">
                    <XIcon
                      icon="info-filled"
                      color="var(--xv-status--error)"
                      :style="{ width: '16px', height: '16px' }"
                    />
                    <span class="error-text xv-body--body-md">This field no longer exists</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <span v-else class="xv-body--body-md high-emphasis-text">--</span>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, watch, defineComponent, computed, nextTick } from 'vue';
import { XButton, XCheckbox, XDivider, XInputText, XTextArea, XIconButton, XSelect, XIcon } from '@asus-aics/xui';
import XErrorMessage from '@/components/XErrorMessage.vue';
import { v4 as uuidv4 } from 'uuid';
import { useContentPageDataStore } from '@/store/contentPageData';
import { storeToRefs } from 'pinia';
import { sanitizeUrl } from '@braintree/sanitize-url';
import scrollIntoView from 'scroll-into-view-if-needed';

export default defineComponent({
  name: 'ContentTab',
  components: {
    XDivider,
    XTextArea,
    XButton,
    XInputText,
    XCheckbox,
    XIconButton,
    XSelect,
    XErrorMessage,
    XIcon,
  },
  props: {
    globalIsEditing: Boolean,
    isWip: Boolean,
  },
  emits: ['on-show-delete-index-modal', 'on-show-delete-field-modal'],
  setup(props, { emit }) {
    const isEditing = ref(false);
    const contentTabWrapper = ref(null);
    const store = useContentPageDataStore();
    const { contents, editingContents, fieldList, sortList } = storeToRefs(store);
    const {
      addNewIndex,
      addNewField,
      validateField,
      validateScopeAndUsage,
      validateBackgroundAndContext,
      validateServiceToThisResource,
      validateIndexName,
      validateExpireAfterSeconds,
    } = store;

    const showDeleteIndexModal = (index) => {
      emit('on-show-delete-index-modal', index);
    };

    const showDeleteFieldModal = (index, fieldIndex) => {
      emit('on-show-delete-field-modal', index, fieldIndex);
    };

    const scopeAndUsageText = computed(() => {
      if (!contents.value.scopeAndUsage || contents.value.scopeAndUsage.length === 0) {
        return [{ text: '--', isHyperlink: false }];
      }
      return parseTextWithLinks(contents.value.scopeAndUsage);
    });
    const backgroundAndContextText = computed(() => {
      if (!contents.value.backgroundAndContext || contents.value.backgroundAndContext.length === 0) {
        return [{ text: '--', isHyperlink: false }];
      }
      return parseTextWithLinks(contents.value.backgroundAndContext);
    });
    const serviceToThisResourceText = computed(() => {
      if (!contents.value.serviceToThisResource || contents.value.serviceToThisResource.length === 0) {
        return '--';
      }
      return contents.value.serviceToThisResource;
    });
    const indexTextWithUuid = computed(() => {
      if (!contents.value.index) {
        return [];
      }
      return contents.value.index.map((value) => {
        return {
          ...value,
          id: uuidv4(),
        };
      });
    });

    const parseTextWithLinks = (inputText: string) => {
      const regex = /\[(.{1,100}?)\]\((https?:\/\/[^)]+)\)/g;

      const matches = [];
      let lastIndex = 0;
      let match;

      while ((match = regex.exec(inputText))) {
        const [, text, url] = match;

        if (lastIndex < match.index) {
          matches.push({
            text: inputText.slice(lastIndex, match.index),
            isHyperlink: false,
          });
        }

        matches.push({
          text,
          url,
          isHyperlink: true,
        });

        lastIndex = regex.lastIndex;
      }

      if (lastIndex < inputText.length) {
        matches.push({
          text: inputText.slice(lastIndex),
          isHyperlink: false,
        });
      }

      return matches;
    };

    const getATagHtml = (text: string, url: string) => {
      return `<a href="${sanitizeUrl(url)}" class="hyperlink" target="_blank" style="
        display: inline-block;
        color: var(--xv-primary--400);
        text-decoration: underline;
      ">${text}</a>`;
    };

    const formatFields = (fields) => {
      if (!Array.isArray(fields)) {
        return fields;
      }
      return fields.map((field) => field.name + '(' + field.sort + ')').join(', ');
    };

    const scrollToBottom = () => {
      if (contentTabWrapper.value) {
        const element = contentTabWrapper.value as HTMLElement;
        element.scroll({
          top: element.scrollHeight,
          behavior: 'smooth',
        });
      }
    };
    const addNewIndexAndScrollToBottom = () => {
      addNewIndex();
      nextTick(() => scrollToBottom());
    };
    const scrollToElement = (element: HTMLElement) => {
      scrollIntoView(element, {
        behavior: 'smooth',
        block: 'end',
        scrollMode: 'if-needed',
      });
    };
    const addNewFieldAndScrollToButton = (index, event) => {
      addNewField(index);
      const element = event.target as HTMLElement;

      nextTick(() => scrollToElement(element));
    };

    const onExpireAfterSecondsChange = (index, value) => {
      // if last input is dot, prevent input
      editingContents.value.index[index].expireAfterSeconds = value;
      validateExpireAfterSeconds(index);
    };

    watch(
      () => props.globalIsEditing,
      (newBool, _) => {
        nextTick(() => {
          isEditing.value = newBool;
          if (isEditing.value) {
            editingContents.value.index.forEach((index, indexIndex) => {
              index.fields.forEach((field, fieldIndex) => {
                if (!field.doesFieldExist) validateField(indexIndex, fieldIndex, field.name);
              });
            });
          }
        });
      },
      { immediate: true }
    );
    return {
      isEditing,
      indexTextWithUuid,
      contents,
      editingContents,
      scopeAndUsageText,
      backgroundAndContextText,
      serviceToThisResourceText,
      sanitizeUrl,
      getATagHtml,
      formatFields,
      fieldList,
      sortList,
      addNewField,
      showDeleteIndexModal,
      showDeleteFieldModal,
      contentTabWrapper,
      addNewIndexAndScrollToBottom,
      validateField,
      validateScopeAndUsage,
      validateBackgroundAndContext,
      validateServiceToThisResource,
      addNewFieldAndScrollToButton,
      validateIndexName,
      validateExpireAfterSeconds,
      onExpireAfterSecondsChange,
    };
  },
});
</script>

<style scoped lang="scss">
.low-emphasis-text {
  color: var(--xv-text--low-emphasis-text);
}
.medium-emphasis-text {
  color: var(--xv-text--medium-emphasis-text);
}
.high-emphasis-text {
  color: var(--xv-text--high-emphasis-text);
}
.hyperlink {
  display: inline-block;
  color: var(--xv-primary--400);
  text-decoration: underline;
}
.content-tab-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  overflow-y: auto;
}
.text-block {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.long-text-area {
  height: 200px;
}

.short-text-area {
  height: 36px;
}

.text-title-with-button {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: -4px;
}

.edit-index {
  &-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  &-item {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  &-leading-number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
  }
  &-input-height {
    height: 40px;
  }
  &-name-row {
    display: flex;
    align-items: end;
    gap: 8px;
  }
  &-text-with-input {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  &-check-box-container {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 0 8px;
  }

  &-field-row {
    display: flex;
    // temp number for no handle
    padding-left: 44px;
    gap: 8px;
  }
  &-add-field-button {
    // temp number for no handle
    margin-left: 44px;
    width: 130px;
  }
}

.error-border {
  --x-select-error: var(--xv-status--error--rgb);
  --x-select-bd-error: solid 1px rgb(var(--x-select-error));
  border: solid 1px rgb(var(--x-select-error));
}
.index-list-enter-active {
  transition: all 2s ease;
}
.index-list-enter-from {
  background-color: var(--xv-container--surface-active-hovered);
}

.index-list-leave-active {
  transition: all 0.5s ease;
}
.index-list-leave-to {
  opacity: 0;
}

.error-text {
  color: var(--xv-status--error);
}
.index-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.index-name-row {
  display: flex;
  align-items: center;
  gap: 16px;
}

.fields-and-text {
  display: flex;
  gap: 16px;
}
.field-container {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.field-and-error {
  display: flex;
  gap: 8px;
}

.field-error-icon-text {
  display: flex;
  align-items: center;
  gap: 2px;
}
</style>
