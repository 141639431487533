<template>
  <div class="input-with-top-label">
    <div class="label-with-required-mark">
      <span class="x-input x-input-label">{{ label }}</span>
      <XTooltip
        :content="content"
        :options="{
          theme: 'blue-card',
          arrow: false,
        }"
      >
        <XIcon icon="info-outline" color="var(--xv-text--disabled-text)"></XIcon>
      </XTooltip>
    </div>
    <XInputText
      :fill="fill"
      :placeholder="placeholder"
      :model-value="modelValue"
      :disabled="disabled"
      @update:model-value="(value) => $emit('update:modelValue', value)"
      :error="error"
    ></XInputText>
    <XErrorMessage v-if="error" :message="message" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { XInputText, XTooltip, XIcon } from '@asus-aics/xui';
import XErrorMessage from '@/components/XErrorMessage.vue';
export default defineComponent({
  name: 'XInputTextWithInfo',
  components: { XInputText, XTooltip, XIcon, XErrorMessage },
  props: {
    label: {
      type: String,
      default: '1',
    },
    fill: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    modelValue: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  setup() {
    return {};
  },
});
</script>

<style scoped lang="scss">
.input {
  &-container {
    display: flex;
    flex-direction: column;
    height: 644px;
    padding: 36px 0;

    gap: 8px;
    overflow-y: auto;
  }
  &-with-top-label {
    display: flex;
    flex-direction: column;
  }
  &-row {
    display: flex;
    flex-direction: row;
    gap: 24px;
  }
}
.label-with-required-mark {
  display: flex;
}
</style>
