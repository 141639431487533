<template>
  <XDialogue id="single" :show="show" :width="700" :height="234">
    <div class="dialogue-container">
      <div class="icon-wrapper">
        <XIcon icon="warning-outline" color="var(--xv-status--warning)" style="width: 54px; height: 54px" />
      </div>
      <div class="description-and-action">
        <div class="description">
          <span class="xv-text--title-lg">Do you want to save your changes?</span>
          <span class="xv-text--paragraph-lg medium-emphasis-text">Any unsaved changes will be discarded</span>
        </div>
        <div class="action-footer">
          <div class="dont-save-area">
            <XButton size="lg" theme="tertiary" outline :style="{ width: '204px' }" @click="dontSave"
              >Discard Changes</XButton
            >
          </div>
          <div class="cancel-and-save-area">
            <XButton class="action-button" size="lg" theme="tertiary" outline @click="closeDialogue">Cancel</XButton>
            <XButton class="action-button" size="lg" @click="save">Save</XButton>
          </div>
        </div>
      </div>
    </div>
  </XDialogue>
</template>
<script lang="ts">
import { XButton, XDialogue, XIcon } from '@asus-aics/xui';
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'UnsavedChangesModal',
  components: { XButton, XDialogue, XIcon },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['on-modal-close', 'on-dont-save', 'on-save'],
  setup(_, { emit }) {
    const closeDialogue = () => {
      emit('on-modal-close');
    };

    const dontSave = () => {
      emit('on-dont-save');
    };

    const save = () => {
      emit('on-save');
    };

    return {
      closeDialogue,
      dontSave,
      save,
    };
  },
});
</script>
<style scoped lang="scss">
.dialogue-container {
  display: flex;
  height: 100%;
  padding: 32px;
  gap: 24px;
}
.icon-wrapper {
  border-radius: 50px;
  width: 72px;
  height: 72px;
  background: var(--orange-50, #fef5e8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.description-and-action {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 32px;
  flex: 1 0 0;
}

.description {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.action-footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.cancel-and-save-area {
  display: flex;
  gap: 16px;
}
.medium-emphasis-text {
  color: var(--xv-text--medium-emphasis-text);
}

.action-button {
  width: 128px;
}
</style>
