<template>
  <XDialogue
    id="single"
    :show="showFullResourceContentModal"
    :width="1080"
    :height="756"
    :closeOnBackdrop="true"
    @esc="onCloseFullResourceContentModal"
    @update:show="(value) => (showFullResourceContentModal = value)"
  >
    <div class="dialogue-container">
      <div class="data-container">
        <div class="data-row">
          <div class="data-field">
            <span class="xv-body--body-md medium-emphasis-text data-field-label">Property Name</span>
            <span class="xv-body--body-lg high-emphasis-text data-field-input">{{
              fullResourceContentModalOptions.propertyName
            }}</span>
          </div>
        </div>
        <div class="data-row">
          <div class="data-field">
            <span class="xv-body--body-md medium-emphasis-text data-field-label">Property Name(CN)</span>
            <span class="xv-body--body-lg high-emphasis-text data-field-input">{{
              fullResourceContentModalOptions.propertyNameCn
            }}</span>
          </div>
        </div>
        <div class="data-row">
          <div class="data-field">
            <span class="xv-body--body-md medium-emphasis-text data-field-label">Parent</span>
            <span class="xv-body--body-lg high-emphasis-text data-field-input">{{
              fullResourceContentModalOptions.parent
            }}</span>
          </div>
        </div>
        <div class="data-row">
          <div class="data-field" :style="{ width: '260px' }">
            <span class="xv-body--body-md medium-emphasis-text data-field-label">Type</span>
            <span class="xv-body--body-lg high-emphasis-text data-field-input">{{
              fullResourceContentModalOptions.type
            }}</span>
          </div>
          <div class="data-field" :style="{ width: '200px' }">
            <span class="xv-body--body-md medium-emphasis-text data-field-label">Required</span>
            <span class="xv-body--body-lg high-emphasis-text data-field-input">{{
              fullResourceContentModalOptions.required ? 'true' : 'false'
            }}</span>
          </div>
          <div class="data-field">
            <span class="xv-body--body-md medium-emphasis-text data-field-label">Default</span>
            <span class="xv-body--body-lg high-emphasis-text data-field-input">{{
              fullResourceContentModalOptions.default
            }}</span>
          </div>
        </div>
        <div class="data-row">
          <div class="data-field">
            <span class="xv-body--body-md medium-emphasis-text data-field-label">Source</span>
            <span class="xv-body--body-lg high-emphasis-text data-field-input">{{
              fullResourceContentModalOptions.source
            }}</span>
          </div>
        </div>
        <div class="data-row">
          <div class="data-field">
            <span class="xv-body--body-md medium-emphasis-text data-field-label">Source Note</span>
            <span class="xv-body--body-lg high-emphasis-text data-field-input">{{
              fullResourceContentModalOptions.sourceNote
            }}</span>
          </div>
        </div>
        <div class="data-row">
          <div class="data-field">
            <span class="xv-body--body-md medium-emphasis-text data-field-label">Note</span>
            <span class="xv-body--body-lg high-emphasis-text data-field-input">{{
              fullResourceContentModalOptions.note
            }}</span>
          </div>
        </div>
        <div class="data-row">
          <div class="data-field">
            <span class="xv-body--body-md medium-emphasis-text data-field-label">Tags</span>
            <span class="xv-body--body-lg high-emphasis-text data-field-input">{{
              fullResourceContentModalOptions.tags
            }}</span>
          </div>
        </div>
      </div>
      <div class="action-container">
        <div class="button-group">
          <XButton theme="tertiary" outline size="lg" min-width="128px" @click="onCloseFullResourceContentModal"
            >Close</XButton
          >
        </div>
      </div>
    </div>
  </XDialogue>
</template>
<script lang="ts">
import '@/assets/scss/scroll_bar.scss';
import { XButton, XDialogue } from '@asus-aics/xui';
import { defineComponent } from 'vue';
import { useModalState } from '@/store/modalState';
import { storeToRefs } from 'pinia';
export default defineComponent({
  name: 'FullResourceContentModal',
  components: {
    XButton,
    XDialogue,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const modalState = useModalState();
    const { fullResourceContentModalOptions, showFullResourceContentModal } = storeToRefs(modalState);
    const { onCloseFullResourceContentModal } = modalState;
    return { fullResourceContentModalOptions, onCloseFullResourceContentModal, showFullResourceContentModal };
  },
});
</script>
<style scoped lang="scss">
.medium-emphasis-text {
  color: var(--xv-text--medium-emphasis-text);
}
.high-emphasis-text {
  color: var(--xv-text--high-emphasis-text);
}

.dialogue-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 32px;
  gap: 0;
}

.action-container {
  display: flex;
  justify-content: flex-end;
  padding: 24px 0 32px 0;
}

.button-group {
  display: flex;
  gap: 16px;
}
.data {
  &-container {
    display: flex;
    flex-direction: column;
    height: 644px;
    padding: 36px 0 16px 0;

    gap: 16px;
    overflow-y: auto;
  }
  &-row {
    display: flex;
    gap: 24px;
  }
  &-field {
    display: flex;
    flex-direction: column;
    &-label {
      height: 32px;
    }
    &-input {
      white-space: pre-wrap;
    }
  }
}
</style>
