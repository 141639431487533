<template>
  <span class="text-with-require-text"
    ><slot></slot><span v-if="required" class="x-input-label-required-mark">*</span></span
  >
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TextWithRequire',
  components: {},
  props: {
    required: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {};
  },
});
</script>

<style scoped lang="scss">
.text-with-require-text {
  font-size: var(--xv-body--body-lg--font-size);
  font-weight: var(--xv-text--body-lg--font-weight);
  line-height: var(--xv-text--body-lg--line-height);
  color: var(--xv-text--medium-emphasis-text);
}
</style>
