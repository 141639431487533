<template>
  <div class="collection-list-title-bar">
    <h2 class="xv-title--title-md high-emphasis-text">Collection List</h2>
    <XButton v-if="user.isAdmin" icon="add" @click="$emit('newCollectionClick')">New Collection</XButton>
  </div>
  <XTable
    lazy-render
    :options="collectionListTableColSchema"
    :data="collectionList"
    key-index="title"
    :enable-shadow="true"
    interactive
    :interactive-options="{
      rowColor: '#EBEBEB',
      columnColor: null,
      intersectionColor: '#EBEBEB',
    }"
    :selected="selected"
    :style="{ cursor: 'pointer' }"
    @rowClick="(item, key) => handleRowClick(item, key)"
  >
    <template #cell-category="{ item }">
      <XTooltip ellipsis :content="item.category">{{ item.category }}</XTooltip>
    </template>
    <template #cell-collection="{ item }">
      <XTooltip ellipsis :content="item.collection">{{ item.collection }}</XTooltip>
    </template>
    <template #cell-collectionCn="{ item }">
      <XTooltip ellipsis :content="item.collectionCn">{{ item.collectionCn }}</XTooltip>
    </template>
    <template #cell-lastUpdated="{ item }">
      <XTooltip ellipsis :content="item.lastUpdated">{{ formatDate(item.lastUpdated) }}</XTooltip>
    </template>
  </XTable>
</template>

<script lang="ts">
import { ref, defineComponent, computed } from 'vue';
import { XTable, XTooltip, XButton } from '@asus-aics/xui';
import { formatDate } from '@asus-aics/x-fe-utils';
import { storeToRefs } from 'pinia';

import { useCollectionDrawerData } from '@/store/collectionDrawerData';
import { collectionListTableColSchema } from '@/utils/collectionListOptions.options';
import { useInfoDataStore } from '@/store/infoData';

interface CollectionList {
  category: string;
  id: string;
  collection: string;
  collectionCn: string;
  lastUpdated: string;
  wipId: string;
}

export default defineComponent({
  name: 'CollectionList',
  components: { XTable, XTooltip, XButton },
  emits: ['onRowClick', 'newCollectionClick'],
  setup(_, { emit }) {
    const store = useCollectionDrawerData();
    const InfoData = useInfoDataStore();

    const { collections } = storeToRefs(store);
    const { user } = storeToRefs(InfoData);

    function transformData(collection) {
      const transformedData = [];

      if (collection.children) {
        for (const child of collection.children) {
          const { category } = collection;
          const { id, title, titleCn, lastUpdated, wipId } = child;

          transformedData.push({
            category,
            id,
            collection: title,
            collectionCn: titleCn,
            lastUpdated,
            wipId,
          });
        }
      }

      return transformedData;
    }

    const collectionList = computed(() => {
      const transformedData = [];

      for (const collection of collections.value) {
        transformedData.push(...transformData(collection));
      }
      return transformedData;
    });

    const selected = ref();
    const handleRowClick = (item: CollectionList, key: string) => {
      selected.value = key;
      emit('onRowClick', item.category, item.collection, item.collectionCn, undefined, item.wipId, item.lastUpdated);

      // customize the action you want when you click on a data
    };
    return {
      collectionListTableColSchema,
      collectionList,
      selected,
      handleRowClick,
      formatDate,
      user,
    };
  },
});
</script>

<style scoped lang="scss">
.collection-list-title-bar {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
</style>
