import LoginPage from '@/views/LoginPage.vue';
import MainPage from '@/views/MainPage.vue';
import { createRouter, createWebHistory, RouterOptions, RouteRecordRaw } from 'vue-router';

const tabNameTranslate = (tabName: string) => {
  switch (tabName) {
    case 'Collection List':
      return 'main';
    case 'DAL APIs':
      return 'dal';
    default:
      return '';
  }
};
const routes: Readonly<RouteRecordRaw[]> = [
  {
    path: '/',
    name: 'LoginPage',
    component: LoginPage,
  },
  {
    path: '/main',
    name: 'MainPage',
    component: MainPage,
  },
] as Readonly<RouteRecordRaw[]>;

const router = createRouter({
  history: createWebHistory('/'),
  routes,
} as RouterOptions);

export { tabNameTranslate };
export default router;
