import { DialoguePlugin, XNotificationsNewModule } from '@asus-aics/xui';
import { createApp } from 'vue';
import router from './router';
import App from './App.vue';
import * as ExtXFeEngine from '@asus-aics/x-fe-engine';
// #region windicss
import 'virtual:windi.css';
import '@asus-aics/xhis-widget-framework/dist/style.css';
// #endregion windicss
import '@asus-aics/xui/xui.css';
import '@asus-aics/xhis-form-builder-v1/style.css';
import { FormBuilderPlugin } from '@asus-aics/xhis-form-builder-v1';
import { createPinia } from 'pinia';
import { setApiVersion } from './api/dataApi';
import VueDOMPurifyHTML from 'vue-dompurify-html';
import hljs from 'highlight.js/lib/core';
import typescript from 'highlight.js/lib/languages/typescript';
import hljsVuePlugin from '@highlightjs/vue-plugin';
import VueMermaidString from 'vue-mermaid-string';
import 'highlight.js/styles/stackoverflow-light.css';
import '@/assets/scss/code_highlight_style.css';

hljs.registerLanguage('typescript', typescript);

const app = createApp(App);
app.use(router);
app.use(DialoguePlugin);
app.use(FormBuilderPlugin);
app.use(XNotificationsNewModule);
app.use(createPinia());
app.use(VueDOMPurifyHTML, {
  hooks: {
    afterSanitizeAttributes: (node) => {
      // Do something with the node
      if ('target' in node) {
        node.setAttribute('target', '_blank');
        node.setAttribute('rel', 'noreferrer noopener');
      }
    },
  },
});
app.use(hljsVuePlugin);
app.use(VueMermaidString);
app.mount('#app');

declare global {
  interface Window {
    ExtXFeEngine?: unknown;
  }
}
window.ExtXFeEngine = ExtXFeEngine;
