import { defineStore } from 'pinia';

export const useCodeGenerateTabDataStore = defineStore('codeGenerateTabData', {
  state: () => ({
    wipName: '',
    editingWipName: '',
    codeGenerateText: '',
  }),
  getters: {},
  actions: {},
});
