<template>
  <div class="sign-up-modal">
    <div class="sign-up-modal-header">
      <span class="xv-headline--headline-sm">CDP Portal</span>
      <span class="xv-body--body-md">Sign Up</span>
    </div>
    <div class="sign-up-modal-body">
      <XInputText
        label="First Name"
        placeholder="Enter first name"
        type="text"
        required
        v-model="firstName"
        :error="firstNameError"
        @keydown.enter="performSignUp"
        autofocus
      />
      <XInputText
        label="Last Name"
        placeholder="Enter last name"
        type="text"
        required
        v-model="lastName"
        :error="lastNameError"
        @keydown.enter="performSignUp"
      />
      <div class="sign-up-modal-body-select-field">
        <TextWithRequire required>Project</TextWithRequire>
        <XSelect
          min-width="336px"
          max-width="336px"
          menu-min-width="336px"
          menu-max-width="336px"
          fallback-label="Select Project"
          :style="{
            backgroundColor: 'var(--xv-container--surface)',
            border: '1px solid var(--xv-text--dividing-line)',
            color: projectFontColor,
          }"
          :data-options="projectOptions"
          :model-value="selectedProject"
          @update:model-value="(value: string) => (selectedProject = value)"
        />
      </div>
      <div class="sign-up-modal-body-select-field">
        <TextWithRequire required>Position</TextWithRequire>
        <XSelect
          min-width="336px"
          max-width="336px"
          menu-min-width="336px"
          menu-max-width="336px"
          fallback-label="Select position"
          :style="{
            backgroundColor: 'var(--xv-container--surface)',
            border: '1px solid var(--xv-text--dividing-line)',
            color: positionFontColor,
          }"
          :data-options="positionOptions"
          :model-value="selectedPosition"
          @update:model-value="(value: string) => (selectedPosition = value)"
        />
      </div>
      <XInputText
        label="Email"
        placeholder="your@email.com"
        :error="emailError"
        required
        type="email"
        v-model="email"
        @keydown.enter="performSignUp"
        >Email</XInputText
      >
      <XInputText
        label="New Password"
        placeholder="Create new password"
        :error="!isPasswordValid || !isPasswordMatching || passwordError"
        required
        type="password"
        v-model="newPassword"
        @keydown.enter="performSignUp"
      />
      <XInputText
        label="Confirm New Password"
        placeholder="Confirm new password"
        :error="!isPasswordValid || !isPasswordMatching || passwordError"
        required
        type="password"
        v-model="confirmNewPassword"
        @keydown.enter="performSignUp"
      />
      <PasswordValidation
        :is-password-length-valid="isPasswordLengthValid"
        :is-password-contain-uppercase="isPasswordContainUppercase"
        :is-password-contain-number="isPasswordContainNumber"
      />
      <div class="sign-up-modal-body-error-message-container">
        <XErrorMessage v-for="message in errorMessages" :message="message" />
      </div>
    </div>
    <div class="sign-up-modal-footer">
      <XCheckbox
        name="terms-of-use"
        :style="{ whiteSpace: 'pre-wrap', alignItems: 'baseline' }"
        v-model="isTermsOfUseChecked"
        ><span class="sign-up-modal-footer-checkbox-description"
          >I have read and agree to CDP Portal’s
          <a
            href="https://www.asus.com/tw/terms_of_use_notice_privacy_policy/official-site/"
            target="_blank"
            rel="noopener noreferrer"
            >Terms of Use</a
          >
          and
          <a
            href="https://www.asus.com/tw/terms_of_use_notice_privacy_policy/privacy_policy/"
            target="_blank"
            rel="noopener noreferrer"
            >Privacy Policy</a
          ></span
        ></XCheckbox
      >
      <XButton :disabled="isSignUpDisabled" size="lg" @click="performSignUp()">Sign Up</XButton>
      <div class="log-in-container">
        <span class="log-in-text">Already an existing user?</span>
        <XButton display="text" size="sm" @click="$emit('back-to-home')">Log In</XButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from 'vue';
import { XInputText, XSelect, XCheckbox, XButton, SelectMenuItem, XNotificationType } from '@asus-aics/xui';
import PasswordValidation from '@/components/views/LoginPage/PasswordValidation.vue';
import TextWithRequire from '@/components/shared/TextWithRequire.vue';
import XErrorMessage from '@/components/XErrorMessage.vue';
import { usePasswordValidator } from '@/composables/views/LoginPage/usePasswordValidator';
import { signUp, getSignUpInfo } from '@/api/authApi';
import { useNotification } from '@/composables/useNotification';
import { useLoadingStore } from '@/store/shared/useLoadingStore';

import {
  GENERIC_ERROR_MESSAGE,
  PASSWORDS_NOT_MATCH_ERROR,
  PASSWORD_REQUIREMENTS_ERROR,
} from '@/utils/shared/constants';
import { useLoginUserDataStore } from '@/store/views/LoginPage/loginUserData';
import { SignUpError, getSignUpErrorMessages } from '@/api/authApiError';
import { useSignUpData } from '@/composables/views/LoginPage/useSignUpData';
export default defineComponent({
  name: 'Components',
  components: { XInputText, XSelect, XCheckbox, XButton, PasswordValidation, TextWithRequire, XErrorMessage },
  emits: ['back-to-home', 'sign-up-clicked', 'show-two-fa-modal'],
  setup(_, { emit }) {
    const {
      firstName,
      firstNameError,
      lastName,
      lastNameError,
      email,
      emailError,
      selectedProject,
      projectFontColor,
      selectedPosition,
      positionFontColor,
      newPassword,
      confirmNewPassword,
      passwordError,
      isTermsOfUseChecked,
      errorMessages,
      resetErrorMessages,
    } = useSignUpData();

    const {
      isPasswordLengthValid,
      isPasswordContainUppercase,
      isPasswordContainNumber,
      isPasswordMatching,
      isPasswordValid,
      updatePasswordMatching,
      updatePasswordValidation,
    } = usePasswordValidator(newPassword, confirmNewPassword);

    const projectOptions = ref<SelectMenuItem[]>();
    const positionOptions = ref<SelectMenuItem[]>();

    const { showNotification } = useNotification();
    const { showLoading, hideLoading } = useLoadingStore();
    const { setEmail, setFlowToken } = useLoginUserDataStore();

    const isSignUpDisabled = computed(() => {
      return (
        !firstName.value ||
        !lastName.value ||
        !email.value ||
        !newPassword.value ||
        !confirmNewPassword.value ||
        !isTermsOfUseChecked.value ||
        !selectedProject.value ||
        !selectedPosition.value
      );
    });

    const validatePassword = () => {
      updatePasswordMatching();
      if (!isPasswordMatching.value) {
        errorMessages.value.push(PASSWORDS_NOT_MATCH_ERROR);
      } else {
        updatePasswordValidation();
        if (!isPasswordValid.value) {
          errorMessages.value.push(PASSWORD_REQUIREMENTS_ERROR);
        }
      }
    };

    const performSignUp = async () => {
      if (isSignUpDisabled.value) return;
      showLoading(false);
      resetErrorMessages();
      validatePassword();
      if (!isPasswordValid.value || !isPasswordMatching.value) {
        hideLoading();
        return;
      }

      try {
        const {
          firstPartCode: _firstPartCode,
          flowToken: _flowToken,
          action,
        } = await signUp({
          username: email.value,
          first_name: firstName.value,
          last_name: lastName.value,
          password: newPassword.value,
          project: selectedProject.value,
          position: selectedPosition.value,
        });
        setEmail(email.value);
        setFlowToken(_flowToken);
        emit('show-two-fa-modal', _firstPartCode, action);
      } catch (error) {
        if (error instanceof SignUpError) {
          const {
            firstNameError: _firstNameError,
            lastNameError: _lastNameError,
            emailError: _emailError,
            passwordError: _passwordError,
            errorMessages: _errorMessages,
          } = getSignUpErrorMessages(error);

          firstNameError.value = _firstNameError;
          lastNameError.value = _lastNameError;
          emailError.value = _emailError;
          passwordError.value = _passwordError;
          errorMessages.value = _errorMessages;
        }
      }
      hideLoading();
    };

    onMounted(async () => {
      showLoading(false);
      try {
        const { positions, projects } = await getSignUpInfo();
        projectOptions.value = projects.map((project) => ({
          label: project,
          value: project,
        }));
        positionOptions.value = positions.map((position) => ({
          label: position,
          value: position,
        }));
      } catch (error) {
        showNotification(XNotificationType.Error, GENERIC_ERROR_MESSAGE);
        emit('back-to-home');
      }
      hideLoading();
    });
    return {
      isPasswordLengthValid,
      isPasswordContainUppercase,
      isPasswordContainNumber,
      isPasswordMatching,
      isPasswordValid,
      newPassword,
      confirmNewPassword,
      projectOptions,
      positionOptions,
      selectedProject,
      selectedPosition,
      email,
      emailError,
      firstName,
      lastName,
      isSignUpDisabled,
      performSignUp,
      isTermsOfUseChecked,
      firstNameError,
      lastNameError,
      projectFontColor,
      positionFontColor,
      errorMessages,
      passwordError,
    };
  },
});
</script>

<style lang="scss">
.sign-up-modal-footer {
  .x-checkbox-input {
    margin: auto;
    margin-top: calc((var(--xv-text--body-md--line-height) - 18px) / 2);
  }
}
</style>

<style scoped lang="scss">
a {
  font-size: var(--xv-text--label-md--font-size);
  font-weight: var(--xv-text--label-md--font-weight);
  line-height: var(--xv-text--label-md--line-height);
  color: var(--xv-primary--500);

  text-decoration: none;
}
.sign-up-modal {
  display: flex;
  width: 400px;
  flex-direction: column;
  border-radius: 16px;
  background: var(--container-surface, #fff);

  box-shadow: 0px 4px 30px 0px rgba(157, 205, 245, 0.2), 0px 4px 16px 0px rgba(66, 66, 66, 0.2);

  &-header {
    padding: 24px 24px 24px 32px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
  }

  &-body {
    padding: 0 32px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    &-error-message-container {
      display: flex;
      flex-direction: column;
      gap: 2px;
    }

    &-select-field {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
  }

  &-footer {
    padding: 16px 32px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    &-checkbox-description {
      font-size: var(--xv-text--body-md--font-size);
      font-weight: var(--xv-text--body-md--font-weight);
      line-height: var(--xv-text--body-md--line-height);
      color: var(--xv-text--high-emphasis-text);
    }
    &-button-group {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
}

.x-select-white-theme {
  background-color: var(--xv-container-surface);
  border: 1px solid var(--xv-text--dividing-line);
}

.log-in {
  &-container {
    display: flex;
    align-items: center;
    gap: 4px;
    padding-top: 8px;
    width: 100%;
    justify-content: center;
  }
  &-text {
    font-size: var(--xv-text--body-md--font-size);
    font-weight: var(--xv-text--body-md--font-weight);
    line-height: var(--xv-text--body-md--line-height);
    color: var(--xv-text--medium-emphasis-text);
  }
}
</style>
