import { ref } from 'vue';

const showModal = ref(false);
const useTooManyAttemptsModal = () => {
  function openModal() {
    showModal.value = true;
  }

  function closeModal() {
    showModal.value = false;
  }

  return {
    showModal,
    openModal,
    closeModal,
  };
};

export { useTooManyAttemptsModal };
