import { defineStore } from 'pinia';

export interface SearchResult {
  searchText: string;
  category: string;
  collectionName: string;
  collectionNameCn: string;
  contentType: string;
  wipId: string;
  key: string;
  rank: number;
}

export const useSearchResultDataStore = defineStore('searchResultData', {
  state: () => ({
    searchResults: [] as SearchResult[],
  }),
  getters: {},
  actions: {
    clearSearchResults() {
      this.searchResults = [];
    },
    setSearchResults(searchResults: SearchResult[]) {
      this.searchResults = searchResults;
    },
  },
});
