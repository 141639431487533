import { getGraphDefinitionText } from '@/utils/views/DalPage/graphUtils';
import { defineStore } from 'pinia';

export interface DalApiListItem {
  id: number;
  name: string;
  relatedCollections: string[];
}

export interface DalApiListCategory {
  type: string;
  children: DalApiListItem[];
}

export interface DalApiListTable {
  id: number;
  apiName: string;
  apiCategory: string;
  relatedCollections: string[];
}

export interface DalApiInput {
  id: number;
  objectString: string;
}

export interface DalApiOutput {
  id: number;
  objectString: string;
}

export interface DalApiNode {
  id: number;
  name: string;
  type: string;
  content: string;
}

export interface DalApiEdge {
  id: number;
  from: number;
  to: number;
  content: string;
}
export interface DalApi {
  id: number;
  apiName: string;
  apiType: string;
  apiString: string;
  relatedCollections: string[];
  input: DalApiInput[];
  output: DalApiOutput[];
  nodes: DalApiNode[];
  edges: DalApiEdge[];
}

export const useApiListDataStore = defineStore('apiListData', {
  state: () => ({
    apiList: [] as DalApiListCategory[],
    dalApiData: {} as DalApi,
    currentSelectedApi: {} as DalApiListItem,
    currentSelectedCategory: '',
  }),
  getters: {
    // Real Type: DalApiListTable[]
    // Due to table component type definition, we need to use Record<string, unknown>[]
    apiListTable(): Record<string, unknown>[] {
      const apiListTable: Record<string, unknown>[] = [];

      this.apiList.forEach((category) => {
        category.children.forEach((api) => {
          apiListTable.push({
            id: api.id,
            apiName: api.name,
            apiCategory: category.type,
            relatedCollections: api.relatedCollections,
          });
        });
      });

      return apiListTable;
    },
    graphDefinition(state) {
      return getGraphDefinitionText(state.dalApiData?.nodes, state.dalApiData?.edges);
    },
  },
  actions: {
    setApiListCategory(apiList: DalApiListCategory[]) {
      this.apiList = apiList;
    },
    setCurrentSelectedApi(api: DalApiListItem) {
      this.currentSelectedApi = api;
    },
    setCurrentSelectedCategory(category: string) {
      this.currentSelectedCategory = category;
    },
    setDalApiData(dalApiData: DalApi) {
      this.dalApiData = dalApiData;
    },
    getNodeById(id: number): DalApiNode {
      const node = this.dalApiData.nodes.find((node) => node.id === id);
      if (!node) {
        throw new Error(`Node with id ${id} not found`);
      }
      return node;
    },
  },
});
