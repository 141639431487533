import { defineStore } from 'pinia';
import { OpPicture, OpOriginPicture } from '@/utils/galleryFakeData.data';

interface OriginPictureList {
  opPictures: OpPicture[];
  opOriginPictures: OpOriginPicture[];
}

interface Picture {
  name: string;
  uuid: string;
  thumbnailUrl: string;
  imageUrl: string;
  updatedAt: string;
}
export interface PictureList {
  pictures: Picture[];
}

export const useSourceImageDataStore = defineStore('sourceImageData', {
  state: () => ({
    pictureList: { pictures: [] } as PictureList,
    editingPictureList: { pictures: [] } as PictureList,
    currentEditingPicture: {} as Picture,
    pendingDeletePictureUuid: [] as string[],
  }),
  getters: {
    getOriginPictureList(): OriginPictureList {
      if (!this.pictureList.pictures) {
        return {
          opPictures: [],
          opOriginPictures: [],
        } as OriginPictureList;
      }
      const opPictures: OpPicture[] = [];
      const opOriginPictures: OpOriginPicture[] = [];
      this.pictureList.pictures.forEach(async (picture) => {
        opPictures.push({
          pictureId: picture.name,
          thumbnail: picture.thumbnailUrl.split(',')[1],
        });
        opOriginPictures.push({
          pictureId: picture.name,
          pictureData: picture.imageUrl.split(',')[1],
        });
      });
      return {
        opPictures,
        opOriginPictures,
      };
    },
    getEditingOriginPictureList(): OriginPictureList {
      if (!this.editingPictureList.pictures) {
        return {
          opPictures: [],
          opOriginPictures: [],
        } as OriginPictureList;
      }
      const opPictures: OpPicture[] = [];
      const opOriginPictures: OpOriginPicture[] = [];
      this.editingPictureList.pictures.forEach((picture) => {
        opPictures.push({
          pictureId: picture.name,
          thumbnail: picture.thumbnailUrl,
        });
        opOriginPictures.push({
          pictureId: picture.name,
          pictureData: picture.imageUrl,
        });
      });
      return {
        opPictures,
        opOriginPictures,
      };
    },
  },
  actions: {
    clearPictureList() {
      this.pictureList = { pictures: [] } as PictureList;
    },
    setPictureList(pictureList: PictureList) {
      this.pictureList = pictureList;
    },
    clearEditingPictureList() {
      this.editingPictureList = { pictures: [] } as PictureList;
    },
    clearPendingDeletePictureUuid() {
      this.pendingDeletePictureUuid = [];
    },
    setEditingPictureList(pictureList: PictureList) {
      this.editingPictureList = pictureList;
    },
    deleteEditingPicture(index: number) {
      this.editingPictureList.pictures.splice(index, 1);
    },
    deleteEditingPictureByUuid(uuid: string) {
      const index = this.editingPictureList.pictures.findIndex((p) => p.uuid === uuid);
      this.deleteEditingPicture(index);
    },
    addPendingDeletePictureUuid(uuid: string) {
      this.pendingDeletePictureUuid.push(uuid);
    },
    updateEditingPictureName(index: number, pictureTitle: string) {
      if (this.editingPictureList?.pictures?.[index]) {
        this.editingPictureList.pictures[index].name = pictureTitle;
      }
    },
    updateEditingPictureWhenPictureUpdate() {
      const updatedEditingPictureList: Picture[] = [];

      for (const newPicture of this.pictureList.pictures) {
        const editingPicture = this.editingPictureList.pictures.find((p) => p.uuid === newPicture.uuid);

        if (editingPicture) {
          updatedEditingPictureList.push(editingPicture);
        } else {
          updatedEditingPictureList.push(newPicture);
        }
      }

      this.editingPictureList.pictures = updatedEditingPictureList;
      this.isPictureListUpdated = true;
    },
  },
});
