<template>
  <XButtonGroup outline>
    <XButtonGroupItem
      v-for="item in itemOptions"
      :label="item.label"
      :on="item.value === itemValue"
      :theme="item.value === itemValue ? 'primary' : 'neutral'"
      :disabled="item.disabled"
      @click="$emit('update:itemValue', item.value)"
      :class="item.value !== itemValue ? 'not-active-button' : ''"
    />
  </XButtonGroup>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';
import { XButtonGroup, XButtonGroupItem } from '@asus-aics/xui';

interface ItemOptions {
  label: string;
  value: string;
  disabled: boolean;
}
export default defineComponent({
  name: 'TabSwitch',
  components: { XButtonGroup, XButtonGroupItem },
  props: {
    itemOptions: {
      type: Array as PropType<ItemOptions[]>,
      required: true,
    },
    itemValue: {
      type: String,
      required: true,
    },
  },
  emits: ['update:itemValue'],
  setup() {
    return {};
  },
});
</script>
