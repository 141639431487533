<template>
  <div class="forget-password-modal">
    <div class="forget-password-modal-header">
      <span class="xv-headline--headline-sm">CDP Portal</span>
      <span class="xv-body--body-md">Reset Password</span>
    </div>
    <div class="forget-password-modal-body">
      <template v-if="!isPostResetPassword">
        <XInputText
          label="Email"
          placeholder="your@email.com"
          :error="!!emailError"
          :message="emailError"
          required
          type="email"
          v-model="email"
          @keydown.enter="performPasswordReset"
          autofocus
          >Email</XInputText
        >
      </template>
      <template v-else>
        <div class="forget-password-modal-body-description">
          <span class="xv-body--body-md">Please enter the verification code sent to {{ email }}</span>
          <span class="xv-body--body-md forget-password-modal-body-description-warning"
            >The code will expire in 10 minutes</span
          >
        </div>
        <div class="forget-password-modal-body-input-fields">
          <XInputText
            label="New Password"
            placeholder="Create new password"
            :error="!isPasswordValid || !isPasswordMatching"
            required
            type="password"
            v-model="newPassword"
            @keydown.enter="performPasswordReset"
          />
          <XInputText
            label="Confirm New Password"
            placeholder="Confirm new password"
            :error="!isPasswordValid || !isPasswordMatching"
            required
            type="password"
            v-model="confirmNewPassword"
            @keydown.enter="performPasswordReset"
          />
          <PasswordValidation
            :is-password-length-valid="isPasswordLengthValid"
            :is-password-contain-uppercase="isPasswordContainUppercase"
            :is-password-contain-number="isPasswordContainNumber"
          />
          <div class="forget-password-modal-body-verification">
            <span class="forget-password-modal-body-verification-title">Verification Code</span>
            <div class="forget-password-modal-body-verification-code">
              <span>{{ firstPartCode }}</span>
              <span>-</span>
              <XInputText
                class="forget-password-modal-body-verification-input-field"
                size="md"
                placeholder="Enter code"
                type="number"
                v-model="secondPartCode"
                :error="!isCodeValid"
                @keydown.enter="performPasswordReset"
              />
            </div>
            <XButton size="sm" :disabled="isResendCodeDisabled" display="text" @click="resendCode">{{
              resendCodeText
            }}</XButton>
            <XErrorMessage v-if="errorMessage" :message="errorMessage" />
          </div>
        </div>
      </template>
    </div>
    <div class="forget-password-modal-footer">
      <div class="forget-password-modal-footer-button-group">
        <XButton size="lg" :disabled="isResetPasswordDisabled" @click="performPasswordReset">Reset Password</XButton>
        <XButton size="lg" outline theme="tertiary" @click="$emit('back-to-home')">Back To Home</XButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { XButton, XInputText, XNotificationType, XToastTheme } from '@asus-aics/xui';
import { resendTwoFACode, resetPassword, postResetPassword } from '@/api/authApi';
import XErrorMessage from '@/components/XErrorMessage.vue';
import PasswordValidation from '@/components/views/LoginPage/PasswordValidation.vue';
import { useToast } from '@/composables/shared/useToast';
import { useNotification } from '@/composables/useNotification';
import { useLoadingStore } from '@/store/shared/useLoadingStore';
import { usePasswordValidator } from '@/composables/views/LoginPage/usePasswordValidator';
import {
  PASSWORDS_NOT_MATCH_ERROR,
  PASSWORD_REQUIREMENTS_ERROR,
  RESEND_CODE_COUNTDOWN,
  VERIFICATION_CODE_SENT_MESSAGE,
} from '@/utils/shared/constants';
import { TooManyRequestsError } from '@/api/authApiError';
import { useTooManyAttemptsModal } from '@/composables/views/LoginPage/useTooManyAttemptsModal';

export default defineComponent({
  name: 'ForgetPasswordModal',
  components: { XButton, XInputText, XErrorMessage, PasswordValidation },
  emits: ['back-to-home'],
  setup(_, { emit }) {
    const { showToast } = useToast();
    const { showNotification } = useNotification();
    const { showLoading, hideLoading } = useLoadingStore();
    const { openModal } = useTooManyAttemptsModal();

    const email = ref('');
    const emailError = ref('');

    const newPassword = ref('');
    const confirmNewPassword = ref('');
    const secondPartCode = ref('');

    const errorMessage = ref('');

    const isCodeValid = ref(true);

    const {
      isPasswordLengthValid,
      isPasswordContainNumber,
      isPasswordContainUppercase,
      isPasswordValid,
      isPasswordMatching,
      updatePasswordValidation,
      updatePasswordMatching,
    } = usePasswordValidator(newPassword, confirmNewPassword);

    const resendCodeCountdown = ref(RESEND_CODE_COUNTDOWN);
    const isResendCodeDisabled = computed(() => resendCodeCountdown.value > 0);

    const resendCodeText = computed(() => {
      return resendCodeCountdown.value > 0 ? `Resend code in ${resendCodeCountdown.value} seconds` : 'Resend code';
    });

    const startResendCodeCountdown = () => {
      const interval = setInterval(() => {
        resendCodeCountdown.value -= 1;
        if (resendCodeCountdown.value <= 0) {
          clearInterval(interval);
        }
      }, 1000);
    };

    const resendCode = async () => {
      resendCodeCountdown.value = RESEND_CODE_COUNTDOWN;
      startResendCodeCountdown();
      showLoading(false);
      try {
        const response = await resendTwoFACode(email.value, flowToken.value);
        firstPartCode.value = response.firstPartCode;
        showToast(VERIFICATION_CODE_SENT_MESSAGE, XToastTheme.Neutral);
      } catch (error) {
        if (error instanceof TooManyRequestsError) {
          openModal();
        } else {
          showNotification(XNotificationType.Error, error.message);
        }
      }
      hideLoading();
    };

    // first step: send email, second step: reset password (post reset password)
    const isPostResetPassword = ref(false);
    const firstPartCode = ref('');
    const flowToken = ref('');

    const isResetPasswordDisabled = computed(() => {
      if (isPostResetPassword.value) {
        return !newPassword.value || !confirmNewPassword.value || secondPartCode.value.length !== 6;
      } else {
        return !email.value;
      }
    });

    const validatePassword = () => {
      updatePasswordMatching();
      errorMessage.value = '';
      if (!isPasswordMatching.value) {
        errorMessage.value = PASSWORDS_NOT_MATCH_ERROR;
      } else {
        updatePasswordValidation();
        if (!isPasswordValid.value) {
          errorMessage.value = PASSWORD_REQUIREMENTS_ERROR;
        }
      }
    };
    const performPasswordReset = async () => {
      if (isResetPasswordDisabled.value) return;
      if (isPostResetPassword.value) {
        // Reset password
        validatePassword();
        isCodeValid.value = true;
        if (!isPasswordValid.value || !isPasswordMatching.value) {
          return;
        }

        showLoading(false);
        const { success, message, invalidCode } = await postResetPassword(
          email.value,
          newPassword.value,
          firstPartCode.value,
          secondPartCode.value,
          flowToken.value
        );

        isCodeValid.value = !invalidCode;

        if (!success) {
          errorMessage.value = message;
          hideLoading();
          return;
        }

        showToast('Password has been reset', XToastTheme.Neutral);
        emit('back-to-home');
        hideLoading();
      } else {
        // Send email
        emailError.value = '';
        startResendCodeCountdown();

        try {
          const { firstPartCode: _firstPartCode, flowToken: _flowToken } = await resetPassword(email.value);
          firstPartCode.value = _firstPartCode;
          flowToken.value = _flowToken;
          isPostResetPassword.value = true;
        } catch (error) {
          emailError.value = error?.message || 'An error occurred';
        }
      }
    };

    return {
      isPostResetPassword,
      email,
      emailError,
      newPassword,
      confirmNewPassword,
      performPasswordReset,
      isResetPasswordDisabled,
      firstPartCode,
      errorMessage,
      isResendCodeDisabled,
      resendCode,
      resendCodeText,
      secondPartCode,
      isPasswordLengthValid,
      isPasswordContainUppercase,
      isPasswordContainNumber,
      isPasswordValid,
      isCodeValid,
      isPasswordMatching,
    };
  },
});
</script>

<style scoped lang="scss">
.forget-password-modal {
  display: flex;
  width: 400px;
  flex-direction: column;
  border-radius: 16px;
  background: var(--container-surface, #fff);

  box-shadow: 0px 4px 30px 0px rgba(157, 205, 245, 0.2), 0px 4px 16px 0px rgba(66, 66, 66, 0.2);

  &-header {
    padding: 24px 24px 24px 32px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
  }

  &-body {
    padding: 0 32px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    &-description {
      display: flex;
      flex-direction: column;
      gap: 4px;
      &-warning {
        color: var(--xv-status--warning);
      }
    }

    &-input-fields {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    &-error-message {
      color: var(--xv-status--error);
    }
    &-verification {
      display: flex;
      flex-direction: column;
      gap: 4px;
      align-items: start;
      &-title {
        font-size: var(--xv-text--body-md--font-size);
        font-weight: var(--xv-text--body-md--font-weight);
        line-height: var(--xv-text--body-md--line-height);
        color: var(--xv-text--medium-emphasis-text);
      }
      &-code {
        display: flex;
        align-items: center;
        width: 100%;
        gap: 4px;
      }
      &-input-field {
        width: 100%;
      }
    }
  }

  &-footer {
    padding: 16px 32px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    &-button-group {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
}
</style>
