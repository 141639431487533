<template>
  <XDialogue
    id="single"
    :show="showHospitalSelectorModal"
    :width="576"
    :height="356"
    close-on-backdrop
    @esc="() => (showHospitalSelectorModal = false)"
    @update:show="(value) => (showHospitalSelectorModal = value)"
  >
    <div class="hospital-selector">
      <div class="hospital-selector-header">
        <span class="xv-title--title-lg">Hospitals</span>
        <XIconButton icon="window-control-close" @click="showHospitalSelectorModal = false" />
      </div>
      <div class="hospital-selector-body">
        <template v-if="hospitalSelectorModalOptions.hospitalList.length > 0">
          <XList>
            <XListItem
              v-for="hospital in hospitalSelectorModalOptions.hospitalList"
              :selected="hospital === currentHospital"
              ref="hospitalItemRefs"
              @click="onClickHospital(hospital)"
            >
              <span
                class="xv-body--body-md"
                :class="{ 'hospital-selector-list--selected': hospital === currentHospital }"
                >{{ hospital }}</span
              ></XListItem
            >
          </XList>
          <div class="empty-block"></div>
        </template>
        <XStatus v-else type="empty">No items</XStatus>
      </div>
    </div>
  </XDialogue>
</template>

<script lang="ts">
import { defineComponent, nextTick, ref, watch } from 'vue';
import { XDialogue, XIconButton, XList, XListItem, XStatus } from '@asus-aics/xui';
import { useModalState } from '@/store/modalState';
import { storeToRefs } from 'pinia';
import { useInfoDataStore } from '@/store/infoData';
import scrollIntoView from 'scroll-into-view-if-needed';
import { setApiHospital } from '@/api/dataApi';

export default defineComponent({
  name: 'HospitalSelectorModal',
  components: { XDialogue, XIconButton, XList, XListItem, XStatus },
  setup() {
    const hospitalItemRefs = ref([]);
    const modalState = useModalState();
    const infoDataStore = useInfoDataStore();

    const { showHospitalSelectorModal, hospitalSelectorModalOptions } = storeToRefs(modalState);
    const { currentHospital } = storeToRefs(infoDataStore);

    const { setCurrentHospital } = infoDataStore;
    const { onCloseHospitalSelectorModal } = modalState;

    const onClickHospital = (hospital: string) => {
      setCurrentHospital(hospital);
      setApiHospital(hospital);
      onCloseHospitalSelectorModal();
    };

    const scrollToSelectedHospital = () => {
      const selectedHospital = hospitalItemRefs.value.find((item) => item.selected);
      if (selectedHospital) {
        scrollIntoView(selectedHospital.$el, {
          behavior: 'smooth',
          block: 'center',
          scrollMode: 'if-needed',
        });
      }
    };
    watch(showHospitalSelectorModal, (value) => {
      if (value) {
        nextTick(() => scrollToSelectedHospital());
      }
    });
    return {
      showHospitalSelectorModal,
      hospitalSelectorModalOptions,
      currentHospital,
      onClickHospital,
      hospitalItemRefs,
    };
  },
});
</script>

<style scoped lang="scss">
.hospital-selector {
  display: flex;
  flex-direction: column;
  height: 100%;
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 80px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
    border-radius: 20px;
  }
  &-header {
    width: 100%;
    padding: 24px;
    display: flex;
    justify-content: space-between;
  }

  &-body {
    width: 100%;
    padding: 0 8px 12px 20px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    scrollbar-gutter: stable;
  }

  &-list {
    &--selected {
      color: var(--xv-primary--500);
    }
  }
}

.empty-block {
  height: 40px;
}
</style>
