import scrollIntoView from 'scroll-into-view-if-needed';

const expandCollection = (category: string) => {
  const navDrawer = document.querySelector('.x-nav-drawer');
  if (navDrawer) {
    const drawerTextArray = Array.from(navDrawer.querySelectorAll(`.x-nav-drawer-menu-item-text`));
    const targetText = drawerTextArray.find((text) => {
      return text.textContent === category && text.getAttribute('aria-expanded') === 'false';
    });
    if (targetText) {
      (targetText as HTMLElement).click();
    }
  }
};

const scrollToActiveCollection = (collectionName: string) => {
  const navDrawer = document.querySelector('.x-nav-drawer');
  if (navDrawer) {
    const drawerTextArray = Array.from(navDrawer.querySelectorAll(`.x-nav-drawer-menu-item-text`));
    const targetText = drawerTextArray.find((text) => {
      return text.textContent === collectionName;
    });
    if (targetText) {
      scrollIntoView(targetText, {
        behavior: 'smooth',
        block: 'center',
        scrollMode: 'if-needed',
      });
    }
  }
};

export { expandCollection, scrollToActiveCollection };
