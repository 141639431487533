<template>
  <div class="x-error-message-container">
    <div class="x-error-message-icon-container">
      <XIcon icon="alert-filled" color="var(--xv-status--error)" class="x-error-message-icon" />
    </div>
    <span class="x-error-message-text" data-testid="x-error-message-text">{{ message }}</span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { XIcon } from '@asus-aics/xui';
export default defineComponent({
  name: 'XErrorMessage',
  components: { XIcon },
  props: {
    message: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {};
  },
});
</script>

<style scoped lang="scss">
.x-error-message {
  &-container {
    display: flex;
    align-items: start;
  }

  &-icon {
    &-container {
      flex-shrink: 0;
      margin-right: 2px;
      height: var(--xv-text--body-md--line-height);
      display: flex;
      align-items: center;
    }
    width: 16px;
    height: 16px;
  }
  &-text {
    font-size: var(--xv-text--body-md--font-size);
    font-weight: var(--xv-text--body-md--font-weight);
    line-height: var(--xv-text--body-md--line-height);
    color: var(--xv-status--error);
    word-break: break-word;
  }
}
</style>
