import {
  getCollections,
  ApiCollection,
  getContentByCollectionName,
  ApiContent,
  getResourceContentByCollectionName,
  ApiResourceContentItem,
  saveContentByCollectionName,
  ApiPostFieldData,
  saveResourceContentByFieldData,
  getCategories,
  searchContentByKeyword,
  ApiSearchResult,
  getImagesByCollectionName,
  ApiImage,
  deleteImage,
  uploadImage,
  patchImage,
  createWipCollection,
  getContentByWipId,
  getResourceContentByWipId,
  patchWipSchema,
  deleteWipSchema,
  getGenerateCode,
  ApiKeyObj,
  editWipContent,
  getConstantData,
  ApiSaveWipSchema,
  editWipResourceContent,
  deleteResourceContent,
  ApiSaveWipResourceContentItem,
  saveWipResourceContentItem,
  ApiContentIndex,
  getDalApiList,
  ApiDalApiList,
  getDalApiById,
  ApiDalApi,
  getCommitVersion,
  copyDataFromWipToCollection,
  getApiHospitalList,
  getSampleData,
} from '@/api/dataApi';
import { useCodeGenerateTabDataStore } from '@/store/codeGenerateTabData';
import { useCollectionDrawerData, Collection, CollectionStatus } from '@/store/collectionDrawerData';
import { useConstantDataStore } from '@/store/constantData';
import { useContentPageDataStore, Content, Field } from '@/store/contentPageData';
import { useInfoDataStore } from '@/store/infoData';
import { useModalState } from '@/store/modalState';
import { useResourceContentPageDataStore, ResourceContent } from '@/store/resourceContentPageData';
import { SearchResult, useSearchResultDataStore } from '@/store/searchResultData';
import { useSourceImageDataStore, PictureList } from '@/store/sourceImageData';
import { DalApi, DalApiListCategory, useApiListDataStore } from '@/store/views/DalPage/apiListData';
import { useSampleDataStore } from '@/store/views/MainPage/sampleData';
import { uniqueId } from 'lodash';
import { storeToRefs } from 'pinia';

export const clearCollectionsAndUpdateStore = () => {
  const store = useCollectionDrawerData();
  store.setCollectionDrawerData([]);
};

export const getCollectionsAndUpdateStore = async () => {
  const dataTranslate = (data: ApiCollection[]) => {
    const collections: Collection[] = data.map((collection) => {
      const children = collection.children.map((child) => {
        return {
          title: child.collection_name,
          titleCn: child.collection_name_ch,
          id: child.collection_name,
          lastUpdated: child.updated_at,
          wipId: child.wip_id ?? '',
          CollectionStatus: CollectionStatus.Normal,
        };
      });
      return {
        category: collection.category_name,
        id: collection.category_name,
        children,
      };
    });
    return collections;
  };
  const data = await getCollections();
  const store = useCollectionDrawerData();
  store.setCollectionDrawerData(dataTranslate(data));
};

export const clearCategoriesAndUpdateStore = () => {
  const store = useCollectionDrawerData();
  store.clearCategories();
};

export const getCategoriesAndUpdateStore = async () => {
  const data = await getCategories();
  const store = useCollectionDrawerData();
  store.setCategories(data);
};

const parseSortType = (sortObj: ApiKeyObj[]): Field[] => {
  const uuid = uniqueId();
  const doesFieldExist = (bool: boolean): boolean => {
    // if bool is true, null, or undefined, return true, else return false
    return !!(bool ?? true);
  };
  const fieldArray = sortObj.map((field) => {
    return {
      name: field.key,
      sort: field.direction,
      doesFieldExist: doesFieldExist(field.does_field_exist),
      uuid,
    };
  });
  return fieldArray;
};

const contentDataTranslate = (data: ApiContent): Content => {
  const uuid = uniqueId();
  return {
    collectionName: data.collection_name,
    collectionNameCn: data.collection_name_ch,
    wipId: data.wip_id,
    wipName: data.wip_name,
    category_name: data.category_name,
    scopeAndUsage: data.scope_and_usage,
    backgroundAndContext: data.background_and_context,
    serviceToThisResource: data.service_to_this_resource,
    owner: data.owner,
    index: data.index.map((index) => {
      const sortFields = parseSortType(index.key_objs);
      return {
        name: index.index_name,
        fields: sortFields,
        uuid,
        useDefaultName: index.use_default_name,
        unique: index.unique,
        expireAfterSeconds: index.expire_after_seconds,
        wipId: index.wip_id,
      };
    }),
  };
};

export const getContentByCollectionNameAndUpdateStore = async (
  _collectionName: string,
  rank: number | null = null,
  keyword: string | null = null
) => {
  const { clearContentPageData, setContentPageData } = useContentPageDataStore();
  clearContentPageData();
  const data = await getContentByCollectionName(_collectionName, rank, keyword);
  setContentPageData(contentDataTranslate(data));
};

export const getContentByWipIdAndUpdateStore = async (
  wipId: string,
  rank: number | null = null,
  keyword: string | null = null
) => {
  const { clearContentPageData, setContentPageData } = useContentPageDataStore();
  clearContentPageData();
  const data = await getContentByWipId(wipId, rank, keyword);
  setContentPageData(contentDataTranslate(data));
};

const resourceContentDataTranslate = (data: ApiResourceContentItem[], parentName: string): ResourceContent[] => {
  return data.map((apiResource) => {
    const getEnumDataType = (typeString: string): string => {
      if (typeString.startsWith('Enum')) {
        const leftBracketIndex = typeString.indexOf('[');
        const rightBracketIndex = typeString.indexOf(']');
        const enumString = typeString.substring(leftBracketIndex + 1, rightBracketIndex);
        return enumString;
      } else {
        return typeString;
      }
    };
    const resourceContent: ResourceContent = {
      id: apiResource.id,
      elementDataType: apiResource.element_data_type,
      propertyName: apiResource.field_name,
      propertyNameCn: apiResource.field_name_ch,
      fieldPath: apiResource.field_path,
      fieldDataType: getEnumDataType(apiResource.field_data_type),
      required: apiResource.required,
      source: apiResource.source,
      sourceNote: apiResource.source_note,
      note: apiResource.note,
      tags: apiResource.tags,
      layer: apiResource.layer,
      parentName: parentName ?? 'root',
      enum: apiResource.field_options?.enum ?? [],
      enumString: apiResource.enum_string ?? undefined,
      defaultValue: apiResource.field_options?.default ?? undefined,
      children: resourceContentDataTranslate(apiResource.children || [], apiResource.field_name),
    };
    return resourceContent;
  });
};
export const getResourceContentByCollectionNameAndUpdateStore = async (
  collectionName: string,
  rank: number | null = null,
  keyword: string | null = null
) => {
  const { clearResourceContentPageData, setResourceContentCount, setResourceContentPageData } =
    useResourceContentPageDataStore();
  clearResourceContentPageData();
  const data = await getResourceContentByCollectionName(collectionName, rank, keyword);
  setResourceContentCount(data.total_count);
  setResourceContentPageData(resourceContentDataTranslate(data.fields, null));
};

export const getResourceContentByWipIdAndUpdateStore = async (
  wipId: string,
  rank: number | null = null,
  keyword: string | null = null
) => {
  const { clearResourceContentPageData, setResourceContentCount, setResourceContentPageData } =
    useResourceContentPageDataStore();
  clearResourceContentPageData();
  const data = await getResourceContentByWipId(wipId, rank, keyword);
  setResourceContentCount(data.total_count);
  setResourceContentPageData(resourceContentDataTranslate(data.fields, null));
};

const updateContentName = (contentName: string, ContentNameCn: string) => {
  const store = useContentPageDataStore();
  const { contents } = storeToRefs(store);
  contents.value.collectionName = contentName;
  contents.value.collectionNameCn = ContentNameCn;
};
export const saveContentByCollectionNameAndUpdateStore = async (
  category: string,
  collectionName: string,
  collectionNameCh: string,
  scopeAndUsage: string,
  backgroundAndContext: string,
  serviceToThisResource: string
) => {
  const data = {
    category_name: category,
    collection_name: collectionName,
    collection_name_ch: collectionNameCh ?? '',
    scope_and_usage: scopeAndUsage ?? '',
    background_and_context: backgroundAndContext ?? '',
    service_to_this_resource: serviceToThisResource ?? '',
  };
  const response = await saveContentByCollectionName(data);

  // Update store
  await getCollectionsAndUpdateStore();
  await getContentByCollectionNameAndUpdateStore(collectionName);
  updateContentName(collectionName, collectionNameCh);
  return response;
};

export const saveResourceContentByFieldDataAndUpdateStore = async (collectionName: string) => {
  const store = useResourceContentPageDataStore();
  const { currentEditResourceContentItem } = storeToRefs(store);

  const dataTranslate = (data: ResourceContent): ApiPostFieldData => {
    if (!data) {
      return null;
    }
    return {
      field_path: data.fieldPath,
      field_name_ch: data.propertyNameCn,
      note: data.note,
      tags: data.tags ?? '',
      source: data.source,
      source_note: data.sourceNote,
    } as ApiPostFieldData;
  };

  const data = dataTranslate(currentEditResourceContentItem.value);
  const response = await saveResourceContentByFieldData(data);
  await getResourceContentByCollectionNameAndUpdateStore(collectionName);
  return response;
};

export const searchContentByKeywordAndUpdateStore = async (keyword: string) => {
  const dataTranslate = (data: ApiSearchResult[]): SearchResult[] => {
    return data.map((searchResult) => {
      const result: SearchResult = {
        searchText: searchResult.headline,
        category: searchResult.category_name,
        collectionName: searchResult.collection_name,
        collectionNameCn: searchResult.collection_name_ch,
        contentType: searchResult.content_type,
        wipId: searchResult.wip_id ?? '',
        key: searchResult.headline,
        rank: searchResult.rank ?? null,
      };
      return result;
    });
  };
  const store = useSearchResultDataStore();
  store.clearSearchResults();
  if (keyword !== '') {
    const originData = await searchContentByKeyword(keyword);
    const data = dataTranslate(originData);
    store.setSearchResults(data);
  }
};

export const getBase64FromUrl = async (url) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
};

export const getImagesByCollectionNameAndUpdateStore = async (collectionName: string) => {
  const dataTranslate = async (data: ApiImage[]): Promise<PictureList> => {
    if (!data) {
      return {
        pictures: [],
      };
    }
    const newData = await Promise.all(
      data.map(async (image) => {
        const thumbnailBase64 = (await getBase64FromUrl(image.thumbnail_url)) as string;
        const imageBase64 = (await getBase64FromUrl(image.image_url)) as string;
        return {
          name: image.image_name,
          uuid: image.uuid,
          thumbnailUrl: thumbnailBase64,
          imageUrl: imageBase64,
          updatedAt: image.updated_at,
        };
      })
    );
    return {
      pictures: newData,
    };
  };
  const store = useSourceImageDataStore();
  store.clearPictureList();
  const data: ApiImage[] = await getImagesByCollectionName(collectionName);
  const newData = await dataTranslate(data);
  store.setPictureList(newData);
};

export const uploadImageAndUpdateStore = async (collectionName: string, file: File) => {
  const response = await uploadImage(collectionName, file);
  await getImagesByCollectionNameAndUpdateStore(collectionName);
  const store = useSourceImageDataStore();
  store.updateEditingPictureWhenPictureUpdate();
  return response;
};

export const deleteImageAndUpdateStore = async (collectionName: string, uuid: string) => {
  const response = await deleteImage(uuid);
  await getImagesByCollectionNameAndUpdateStore(collectionName);
  return response;
};

export const saveImageAndUpdateStore = async (collectionName: string) => {
  const store = useSourceImageDataStore();
  const { currentEditingPicture } = storeToRefs(store);

  if (currentEditingPicture.value.uuid === null) {
    return {
      status: 'success_no_change',
    };
  }
  await patchImage(currentEditingPicture.value.uuid, currentEditingPicture.value.name);

  await getImagesByCollectionNameAndUpdateStore(collectionName);
  return { status: 'success' };
};

export const createWipCollectionAndUpdateStore = async (collectionName: string = null) => {
  try {
    const response = await createWipCollection(collectionName);
    await getCollectionsAndUpdateStore();
    return response;
  } catch (e) {
    console.error(e);
  }
};

export const patchWipSchemaAndUpdateStore = async (
  wipId: string,
  wipName: string,
  collectionName: string,
  collectionNameCn: string
) => {
  const dataTranslate = (data): ApiSaveWipSchema => {
    return {
      wip_name: wipName,
      collection_name: collectionName,
      collection_name_ch: collectionNameCn,
      scope_and_usage: data.scopeAndUsage,
      background_and_context: data.backgroundAndContext,
      service_to_this_resource: data.serviceToThisResource,
      index: data.index.map((index) => {
        return {
          index_name: index.name,
          key_objs: index.fields.map((field) => {
            return {
              key: field.name,
              direction: field.sort,
            };
          }),
          use_default_name: index.useDefaultName,
          unique: index.unique,
          expire_after_seconds: index.expireAfterSeconds,
        } as ApiContentIndex;
      }),
    };
  };

  try {
    const store = useContentPageDataStore();
    const { editingContents } = storeToRefs(store);
    const response = await patchWipSchema(wipId, dataTranslate(editingContents.value));
    await getCollectionsAndUpdateStore();
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const deleteWipSchemaAndUpdateStore = async (wipId: string) => {
  try {
    const response = await deleteWipSchema(wipId);
    await getCollectionsAndUpdateStore();
    return response;
  } catch (e) {
    console.error(e);
  }
};

export const getGenerateCodeAndUpdateStore = async (wipId: string) => {
  try {
    const store = useCodeGenerateTabDataStore();
    const { codeGenerateText } = storeToRefs(store);
    codeGenerateText.value = '';
    const response = await getGenerateCode(wipId);
    codeGenerateText.value = response.code;
    return response;
  } catch (e) {
    console.error(e);
  }
};

export const editWipContentAndUpdateStore = async (wipId: string) => {
  try {
    const store = useContentPageDataStore();
    const { setFieldList } = store;
    const response = await editWipContent(wipId);
    setFieldList(response.selectable_fields);
    return response;
  } catch (e) {
    console.error(e);
  }
};

export const editWipResourceContentAndUpdateStore = async (wipId: string, id: number = null) => {
  try {
    const store = useResourceContentPageDataStore();
    const { parentOptions } = storeToRefs(store);
    const response = await editWipResourceContent(wipId, id);
    parentOptions.value = response.selectable_parents;
  } catch (e) {
    console.error(e);
  }
};

export const saveWipResourceContentItemAndUpdateStore = async (wipId: string) => {
  try {
    const store = useResourceContentPageDataStore();
    const { currentEditResourceContentItem } = storeToRefs(store);
    const dataTranslate = (data: ResourceContent): ApiSaveWipResourceContentItem => {
      const extractFieldType = (fieldDataType: string): string => {
        const typeMatch = fieldDataType?.match(/^(Array|Object)/);
        return typeMatch ? typeMatch[1] : fieldDataType;
      };

      return {
        id: data.id,
        default: data.defaultValue ?? null,
        element_data_type: data.elementDataType,
        enum_string: data.enumString ?? null,
        field_data_type: extractFieldType(data.fieldDataType),
        parent: data.parentName,
        required: data.required,
        field_name: data.propertyName,
        field_name_ch: data.propertyNameCn,
        note: data.note,
        tags: data.tags,
        source: data.source,
        source_note: data.sourceNote,
      };
    };
    const response = await saveWipResourceContentItem(wipId, dataTranslate(currentEditResourceContentItem.value));
    await getResourceContentByWipIdAndUpdateStore(wipId);

    return response;
  } catch (e) {
    console.error(e);
  }
};
export const deleteResourceContentAndUpdateStore = async (itemId: number) => {
  try {
    const contentStore = useContentPageDataStore();
    const { wipId } = storeToRefs(contentStore);
    await deleteResourceContent(itemId);
    await getResourceContentByWipIdAndUpdateStore(wipId.value);
  } catch (e) {
    console.error(e);
  }
};
export const getConstantDataAndUpdateStore = async () => {
  try {
    const constantStore = useConstantDataStore();
    const contentStore = useContentPageDataStore();
    const { setDataTypes, setElementDataTypes, setDirections } = constantStore;
    const { setSortList } = contentStore;
    const data = await getConstantData();
    setDataTypes(data.data_types);
    setElementDataTypes(data.element_data_types);
    setDirections(data.directions);
    setSortList(data.directions);
  } catch (e) {
    console.error(e);
  }
};

export const getDalApiListAndUpdateStore = async () => {
  try {
    const dataTranslate = (data: ApiDalApiList[]): DalApiListCategory[] => {
      return data.map((category) => {
        return {
          type: category.api_type,
          children: category.children.map((child) => {
            return {
              id: child.id,
              name: child.api_name,
              relatedCollections: child.related_collections,
            };
          }),
        };
      });
    };
    const store = useApiListDataStore();
    const { setApiListCategory } = store;
    const data = await getDalApiList();
    setApiListCategory(dataTranslate(data));
  } catch (e) {
    console.error(e);
  }
};

export const getDalApiByIdAndUpdateStore = async (id: number) => {
  const dataTranslate = (data: ApiDalApi): DalApi => {
    return {
      id: data.id,
      apiName: data.api_name,
      apiType: data.api_type,
      apiString: data.api_string,
      input: data.input.map((input) => {
        return {
          id: input.id,
          objectString: input.object_string,
        };
      }),
      output: data.output.map((output) => {
        return {
          id: output.id,
          objectString: output.object_string,
        };
      }),
      relatedCollections: data.related_collections,
      nodes: data.nodes.map((node) => {
        return {
          id: node.id,
          name: node.node_name,
          type: node.node_type,
          content: node.node_content,
        };
      }),
      edges: data.edges.map((edge) => {
        return {
          id: edge.id,
          from: edge.from_node,
          to: edge.to_node,
          content: edge.edge_content,
        };
      }),
    };
  };
  try {
    const store = useApiListDataStore();
    const { setDalApiData } = store;
    const data = await getDalApiById(id);
    setDalApiData(dataTranslate(data));
  } catch (e) {
    console.error(e);
  }
};

export const getCommitVersionAndUpdateStore = async () => {
  const store = useInfoDataStore();
  const { setCommitVersion } = store;
  try {
    const data = await getCommitVersion();
    setCommitVersion(data.commit.substring(0, 7));
  } catch (e) {
    console.error(e);
    setCommitVersion('unknown');
  }
};

interface CopyDataFromWipToCollectionSuccess {
  collectionName: string;
}
interface CopyDataFromWipToCollectionFail {
  errorMessage: string;
}
type copyDataFromWipToCollectionResponse = CopyDataFromWipToCollectionSuccess | CopyDataFromWipToCollectionFail;
export const copyDataFromWipToCollectionAndGoToCollection = async (
  wipId: string
): Promise<copyDataFromWipToCollectionResponse> => {
  const response = await copyDataFromWipToCollection(wipId);
  // if success in response return success
  if ('status' in response) {
    return {
      collectionName: response.collection_name,
    };
  }
  return {
    errorMessage: response.detail ?? 'Unable to copy data, please try again',
  };
};

export const getHospitalListAndUpdateStore = async () => {
  const modalState = useModalState();
  const { setHospitalSelectorModalOptions } = modalState;

  const apiHospitalList = await getApiHospitalList();
  setHospitalSelectorModalOptions({ hospitalList: apiHospitalList.projects });
};

export const getSampleDataAndUpdateStore = async (collectionName: string) => {
  const { setSampleData, clearSampleData } = useSampleDataStore();
  clearSampleData();
  const sampleData = await getSampleData(collectionName);
  setSampleData(sampleData);
};
