<template>
  <div class="two-fa-modal">
    <div class="two-fa-modal-header">
      <span class="xv-headline--headline-sm">CDP Portal</span>
      <span class="xv-body--body-md">Verification</span>
    </div>
    <div class="two-fa-modal-body">
      <div class="two-fa-modal-body-description">
        <span class="xv-body--body-md">Please enter the verification code sent to {{ email }}</span>
        <span class="xv-body--body-md two-fa-modal-body-description-warning">The code will expire in 10 minutes</span>
      </div>
      <div class="two-fa-modal-body-verification">
        <span class="two-fa-modal-body-verification-title">Verification Code</span>
        <div class="two-fa-modal-body-verification-code">
          <span>{{ firstPartCode }}</span>
          <span>-</span>
          <XInputText
            class="two-fa-modal-body-verification-input-field"
            size="md"
            placeholder="Enter code"
            type="number"
            v-model="secondPartCode"
            @keydown.enter="continueVerifyTwoFactorProcess"
            autofocus
          />
        </div>
        <XButton size="sm" :disabled="isResendCodeDisabled" display="text" @click="resendCode">{{
          resendCodeText
        }}</XButton>
        <XErrorMessage v-if="codeError" :message="codeError" />
      </div>
    </div>
    <div class="two-fa-modal-footer">
      <XButton size="lg" :disabled="secondPartCode.length !== 6" @click="continueVerifyTwoFactorProcess"
        >Continue</XButton
      >
      <div class="two-fa-modal-footer-button-group">
        <XButton size="sm" display="text" @click="$emit('back-to-home')">Log in with a different account</XButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from 'vue';
import { XInputText, XButton, XNotificationType, XToastTheme } from '@asus-aics/xui';
import XErrorMessage from '@/components/XErrorMessage.vue';
import { postSignUp, resendTwoFACode, verifyTwoFALogin } from '@/api/authApi';
import { useLoginUserDataStore } from '@/store/views/LoginPage/loginUserData';
import { storeToRefs } from 'pinia';
import { useNotification } from '@/composables/useNotification';
import { useLoadingStore } from '@/store/shared/useLoadingStore';
import { useTwoFaData } from '@/store/views/LoginPage/twoFaData';
import { useToast } from '@/composables/shared/useToast';
import { useTooManyAttemptsModal } from '@/composables/views/LoginPage/useTooManyAttemptsModal';
import { TooManyRequestsError } from '@/api/authApiError';
import { RESEND_CODE_COUNTDOWN, VERIFICATION_CODE_SENT_MESSAGE } from '@/utils/shared/constants';

export default defineComponent({
  name: 'TwoFAModal',
  components: { XInputText, XButton, XErrorMessage },
  props: {
    fromAction: {
      type: String,
      default: 'login',
    },
  },
  emits: ['back-to-home', 'show-success-registration-modal'],
  setup(props, { emit }) {
    const { openModal } = useTooManyAttemptsModal();

    const resendCodeCountdown = ref(RESEND_CODE_COUNTDOWN);
    const isResendCodeDisabled = computed(() => resendCodeCountdown.value > 0);

    const resendCodeText = computed(() => {
      return resendCodeCountdown.value > 0 ? `Resend code in ${resendCodeCountdown.value} seconds` : 'Resend code';
    });

    const loginUserData = useLoginUserDataStore();
    const { email, flowToken } = storeToRefs(loginUserData);

    const { showNotification } = useNotification();
    const { showToast } = useToast();
    const { showLoading, hideLoading } = useLoadingStore();

    const twoFaDataStore = useTwoFaData();
    const { firstPartCode, secondPartCode } = storeToRefs(twoFaDataStore);
    const { setFirstPartCode } = twoFaDataStore;

    const startResendCodeCountdown = () => {
      const interval = setInterval(() => {
        resendCodeCountdown.value -= 1;
        if (resendCodeCountdown.value <= 0) {
          clearInterval(interval);
        }
      }, 1000);
    };

    const resendCode = async () => {
      resendCodeCountdown.value = RESEND_CODE_COUNTDOWN;
      startResendCodeCountdown();
      showLoading(false);
      try {
        const response = await resendTwoFACode(email.value, flowToken.value);
        setFirstPartCode(response.firstPartCode);
        showToast(VERIFICATION_CODE_SENT_MESSAGE, XToastTheme.Neutral);
      } catch (error) {
        if (error instanceof TooManyRequestsError) {
          openModal();
        } else {
          showNotification(XNotificationType.Error, error.message);
        }
      }
      hideLoading();
    };

    const codeError = ref('');

    const continueVerifyTwoFactorProcess = async () => {
      if (secondPartCode.value.length !== 6) return;
      showLoading(false);
      codeError.value = '';

      if (props.fromAction === 'login') {
        const response = await verifyTwoFALogin(
          email.value,
          firstPartCode.value,
          secondPartCode.value,
          flowToken.value
        );

        if (response.success) {
          // Go to different page
        } else if (response.tooManyAttempts) {
          openModal();
        } else {
          codeError.value = response.message;
        }
      } else if (props.fromAction === 'signUp') {
        const response = await postSignUp(email.value, firstPartCode.value, secondPartCode.value, flowToken.value);

        if (response.success) {
          // Go to different page
          emit('show-success-registration-modal');
        } else if (response.tooManyAttempts) {
          openModal();
        } else {
          codeError.value = response.message;
        }
      }
      hideLoading();
    };

    onMounted(() => {
      startResendCodeCountdown();
    });
    return {
      firstPartCode,
      resendCodeCountdown,
      resendCodeText,
      isResendCodeDisabled,
      resendCode,
      email,
      secondPartCode,
      continueVerifyTwoFactorProcess,
      codeError,
    };
  },
});
</script>

<style scoped lang="scss">
.two-fa-modal {
  display: flex;
  width: 400px;
  flex-direction: column;
  border-radius: 16px;
  background: var(--container-surface, #fff);

  box-shadow: 0px 4px 30px 0px rgba(157, 205, 245, 0.2), 0px 4px 16px 0px rgba(66, 66, 66, 0.2);

  &-header {
    padding: 24px 24px 24px 32px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
  }

  &-body {
    padding: 0 32px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    &-description {
      display: flex;
      flex-direction: column;
      gap: 4px;
      &-warning {
        color: var(--xv-status--warning);
      }
    }

    &-verification {
      display: flex;
      flex-direction: column;
      gap: 4px;
      align-items: start;
      &-title {
        font-size: var(--xv-text--body-md--font-size);
        font-weight: var(--xv-text--body-md--font-weight);
        line-height: var(--xv-text--body-md--line-height);
        color: var(--xv-text--medium-emphasis-text);
      }
      &-code {
        display: flex;
        align-items: center;
        width: 100%;
        gap: 4px;
      }
      &-input-field {
        width: 100%;
      }
    }
  }

  &-footer {
    padding: 16px 32px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    &-button-group {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
}
</style>
