<template>
  <div class="sidebar-upper-container">
    <div class="filter-bar">
      <div class="filter-icon-text-container">
        <XIcon icon="list" color="var(--xv-status--primary)" />
        <span class="xv-label--label-md filter-text">Filter</span>
      </div>
      <XButton size="sm" display="text" disabled>Reset</XButton>
    </div>
    <div class="filter-selection">
      <div class="filter-text-and-chips">
        <span class="medium-emphasis-text xv-body--body-md">By data type</span>
        <XChipGroup
          v-model:selected="dataTypeSelectedChipItems1"
          :items="dataTypeChipItems1"
          size="sm"
          select-mode="multi"
        />
        <XChipGroup
          v-model:selected="dataTypeSelectedChipItems2"
          :items="dataTypeChipItems2"
          size="sm"
          select-mode="multi"
        />
      </div>
      <div class="filter-text-and-chips">
        <span class="medium-emphasis-text xv-body--body-md">Other</span>
        <XChipGroup v-model:selected="otherSelectedChipItems" :items="otherChipItems" size="sm" select-mode="multi" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { XChipGroupItem, XChipGroup } from '@asus-aics/xui';
import { ref, defineComponent } from 'vue';

export default defineComponent({
  name: 'FilterSection',
  components: {
    XChipGroup,
  },
  setup() {
    //#region chips
    const dataTypeChipItems1 = ref<XChipGroupItem[]>([
      { key: 'string', label: 'String' },
      { key: 'boolean', label: 'Boolean' },
    ]);
    const dataTypeChipItems2 = ref<XChipGroupItem[]>([
      { key: 'object', label: 'Object' },
      { key: 'array[Object]', label: 'Array[Object]' },
    ]);
    const otherChipItems = ref<XChipGroupItem[]>([
      { key: 'required', label: 'Required' },
      { key: 'global', label: 'Global' },
    ]);

    const dataTypeSelectedChipItems1 = ref([]);
    const dataTypeSelectedChipItems2 = ref([]);
    const otherSelectedChipItems = ref([]);
    //#endregion chips
    return {
      dataTypeChipItems1,
      dataTypeChipItems2,
      otherChipItems,
      dataTypeSelectedChipItems1,
      dataTypeSelectedChipItems2,
      otherSelectedChipItems,
    };
  },
});
</script>

<style scoped lang="scss">
.sidebar-upper-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 12px;
}
.filter-bar {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.filter-icon-text-container {
  display: flex;
  gap: 4px;
}
.filter-text {
  color: var(--xv-status--primary);
}

.filter-selection {
  width: 100%;
  height: 212px;
  border: 1px solid var(--xv-text--dividing-line);
  border-radius: 8px;
  padding: 12px 11px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.filter-text-and-chips {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
</style>
