import { DalApiEdge, DalApiNode } from '@/store/views/DalPage/apiListData';

type NodeType = 'collection' | 'process_function' | 'process_mongo' | 'data' | 'decision' | 'return';
const getTypeBrackets = (type: NodeType) => {
  switch (type) {
    case 'collection':
      return ['[(', ')]'];
    case 'process_function':
      return ['[', ']'];
    case 'process_mongo':
      return ['[', ']'];
    case 'data':
      return ['([', '])'];
    case 'decision':
      return ['{', '}'];
    case 'return':
      return ['(', ')'];
    default:
      return ['[', ']'];
  }
};

const getTypeSymbol = (type: NodeType) => {
  switch (type) {
    case 'collection':
      return 'cylinder';
    case 'process_function':
      return 'rect_yellow';
    case 'process_mongo':
      return 'rect_red';
    case 'data':
      return 'data';
    case 'decision':
      return 'condition';
    case 'return':
      return 'terminal';
    default:
      return 'rect';
  }
};

const getGraphDefinitionText = (graphNode: DalApiNode[], graphEdges: DalApiEdge[]) => {
  let nodeDefinition = '';
  let edgeDefinition = '';
  let typeDefinition = '';
  let clickDefinition = '';

  graphNode.forEach((node) => {
    const bracket = getTypeBrackets(node.type as NodeType);
    nodeDefinition += `\t${node.id}${bracket[0]}"${node.name}"${bracket[1]}\n`;
    typeDefinition += `\t${node.id}:::${getTypeSymbol(node.type as NodeType)}\n`;
    clickDefinition += `\tclick ${node.id}\n`;
  });

  graphEdges.forEach((edge) => {
    const description = edge.content ? `|${edge.content}|` : '';
    edgeDefinition += `\t${edge.from} -->${description} ${edge.to}\n`;
  });

  const classDefinition = `\tclassDef cylinder stroke:#FFFFFF
    classDef square stroke:#FFFFFF
    classDef condition stroke:#FFFFFF
    classDef terminal stroke:#FFFFFF
    classDef rect_yellow stroke:#FFFFFF
    classDef rect_red stroke:#FFFFFF
    classDef data stroke:#FFFFFF
`;

  const graphDefinition =
    'flowchart TD\n' + nodeDefinition + clickDefinition + edgeDefinition + typeDefinition + classDefinition;
  return graphDefinition;
};

export { getGraphDefinitionText };
