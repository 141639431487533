<template>
  <div class="login-modal">
    <div class="modal-header">
      <div class="title-group">
        <h1 class="modal-title-text xv-headline--headline-sm">CDP Portal</h1>
      </div>
    </div>
    <div class="modal-body">
      <div class="input-container" :style="{ paddingBottom: '4px' }">
        <span class="body-text xv-body--body-md">Email</span>
        <XInputText
          size="md"
          placeholder="your@email.com"
          type="email"
          class="input-field"
          :error="isError"
          :model-value="account"
          @update:model-value="(value) => (account = value)"
          @keydown.enter="() => login()"
          autofocus
        />
      </div>
      <div class="input-container">
        <span class="body-text xv-body--body-md">Password</span>
        <XInputText
          size="md"
          placeholder="Enter password"
          type="password"
          class="input-field"
          :error="isError"
          :model-value="password"
          @update:model-value="(value) => (password = value)"
          @keydown.enter="() => login()"
        />
      </div>
      <XButton display="text" size="sm" @click="$emit('forgot-password-clicked')">Forgot Password?</XButton>
      <XErrorMessage v-if="isError" :message="loginErrorMessage" />
    </div>
    <div class="modal-footer">
      <XButton
        class="login-button"
        size="lg"
        :disabled="!isLoginAllowed"
        :loading="isButtonLoading"
        @click="() => login()"
        >Log In</XButton
      >
      <div class="divider-container">
        <XDivider variant="fullWidth" :style="{ flex: 1 }" />
        <span class="divider-text">OR</span>
        <XDivider variant="fullWidth" :style="{ flex: 1 }" />
      </div>
      <XButton class="login-button" size="lg" @click="() => onClickLoginByAzure()">Log In with Azure</XButton>
      <div class="sign-up-container">
        <span class="sign-up-text">New user?</span>
        <XButton display="text" size="sm" @click="$emit('sign-up-clicked')">Sign Up</XButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, defineComponent, computed } from 'vue';
import { loginByAccount, loginByAzure } from '@/api/authApi';
import { XButton, XDivider, XInputText } from '@asus-aics/xui';
import { useLoginUserDataStore } from '@/store/views/LoginPage/loginUserData';
import XErrorMessage from '@/components/XErrorMessage.vue';

export default defineComponent({
  name: 'LoginModal',
  components: { XInputText, XButton, XDivider, XErrorMessage },
  emits: ['forgot-password-clicked', 'sign-up-clicked', 'show-two-fa-modal'],
  setup(_, { emit }) {
    const account = ref('');
    const password = ref('');
    const isError = ref(false);
    const loginErrorMessage = ref('');
    const isButtonLoading = ref(false);
    const isLoginAllowed = computed(() => {
      return !(account.value.length === 0 || password.value.length === 0);
    });

    const { setEmail, setFlowToken } = useLoginUserDataStore();

    const login = async () => {
      if (!isLoginAllowed.value) return;
      isButtonLoading.value = true;
      isError.value = false;
      loginErrorMessage.value = '';
      try {
        const { firstPartCode, flowToken, action } = await loginByAccount(account.value, password.value);
        setEmail(account.value);
        setFlowToken(flowToken);
        emit('show-two-fa-modal', firstPartCode, action);
      } catch (e) {
        isError.value = true;
        loginErrorMessage.value = e.message;
      }
      isButtonLoading.value = false;
    };

    const onClickLoginByAzure = async () => {
      await loginByAzure();
    };

    return {
      account,
      password,
      isError,
      isLoginAllowed,
      isButtonLoading,
      login,
      onClickLoginByAzure,
      loginErrorMessage,
    };
  },
});
</script>

<style scoped lang="scss">
.login-modal {
  display: flex;
  width: 400px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 16px;
  background: var(--container-surface, #fff);

  /* Shadow M */
  box-shadow: 0px 4px 30px 0px rgba(157, 205, 245, 0.2), 0px 4px 16px 0px rgba(66, 66, 66, 0.2);
}

.modal-header {
  display: flex;
  padding: 24px 24px 24px 32px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.title-group {
  display: flex;
  align-items: center;
  gap: 12px;
}

.modal-title-text {
  color: var(--text-high-emphasis-text, #262626);
}

.modal-body {
  display: flex;
  width: 400px;
  padding: 0 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.input-container {
  display: flex;
  width: 336px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.input-field {
  width: 336px;
}

.body-text {
  color: var(--text-medium-emphasis-text, rgba(38, 38, 38, 0.6));
}

.modal-footer {
  display: flex;
  flex-direction: column;
  padding: 16px 32px;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.login-button {
  width: 336px;
  padding: 10px 28px;
}

.divider {
  &-text {
    font-size: var(--xv-text--body-lg--font-size);
    font-weight: var(--xv-text--body-lg--font-weight);
    line-height: var(--xv-text--body-lg--line-height);
    color: var(--xv-text--medium-emphasis-text);
    padding: 0 8px;
  }
  &-container {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 8px 0;
  }
}

.sign-up {
  &-text {
    font-size: var(--xv-text--body-md--font-size);
    font-weight: var(--xv-text--body-md--font-weight);
    line-height: var(--xv-text--body-md--line-height);
    color: var(--xv-text--medium-emphasis-text);
  }
  &-container {
    display: flex;
    align-items: center;
    gap: 4px;
    padding-top: 8px;
    width: 100%;
    justify-content: center;
  }
}
</style>
