<template>
  <XDialogue id="single" :show="show" :width="576" :height="344">
    <div class="dialogue-container">
      <div class="dialogue-header">
        <span class="xv-text--title-lg">Tags</span>
      </div>
      <div class="dialogue-body">
        <XTextArea
          id="tags-text-area"
          :rows="7"
          class="text-area"
          :model-value="content"
          @update:model-value="(value) => $emit('update:content', value)"
        />
      </div>
      <div class="dialogue-footer">
        <XButton class="action-button" size="lg" theme="tertiary" outline @click="closeDialogue">Cancel</XButton>
        <XButton class="action-button" size="lg" @click="save">Done</XButton>
      </div>
    </div>
  </XDialogue>
</template>
<script lang="ts">
import { XButton, XDialogue, XTextArea } from '@asus-aics/xui';
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'TableInputEditModel',
  components: { XButton, XDialogue, XTextArea },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    content: {
      type: String,
      default: '',
    },
  },
  emits: ['onModalClose', 'update:content', 'onSave'],
  setup(_, { emit }) {
    const closeDialogue = () => {
      emit('onModalClose');
    };

    const save = () => {
      emit('onSave');
      closeDialogue();
    };

    return {
      closeDialogue,
      save,
    };
  },
});
</script>
<style scoped lang="scss">
.dialogue-container {
  display: flex;
  flex-direction: column;
  gap: 0px;
}

.dialogue-header {
  margin: 32px 32px 24px 32px;
}

.dialogue-body {
  padding: 0 32px;
}

.dialogue-footer {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  padding: 24px 32px 32px 128px;
}

.text-area {
  width: 100%;
}
</style>
