<template>
  <div class="sample-data">
    <div class="collapsable-title-wrapper">
      <div class="collapsable-title">
        <div class="collapsable-title-left-part">
          <XIconButton
            :icon="isCollapsed ? 'chevron-right' : 'chevron-down'"
            color="var(--xv-status--primary)"
            @click="toggleCollapse"
          />
          <span>Sample Data {{ index }}</span>
        </div>
        <div class="collapsable-title-right-part">
          <XIconButton
            icon="copy"
            text="Copy"
            size="sm"
            long
            :style="{ color: 'var(--xv-status--primary)', marginRight: '8px' }"
            :rounded="false"
            @click="copyToClipboard"
          >
          </XIconButton>
        </div>
      </div>
    </div>
    <div ref="codeBlockRef" class="code-block" :style="{ maxHeight: isCollapsed ? null : `${codeBlockHeight}px` }">
      <highlightjs language="typescript" :code="code" />
    </div>
  </div>
</template>

<script lang="ts">
import '@/assets/scss/code_highlight_sample_data.css';
import { computed, defineComponent, nextTick, onMounted, ref } from 'vue';
import { XIconButton } from '@asus-aics/xui';
import { useToast } from '@/composables/shared/useToast';

export default defineComponent({
  name: 'SampleDataItem',
  components: { XIconButton },
  props: {
    code: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const codeBlockRef = ref(null);
    const { showToast } = useToast();
    const isCollapsed = ref(true);

    const codeBlockHeight = computed(() => {
      return isCollapsed.value ? 0 : codeBlockRef.value.scrollHeight;
    });

    const toggleCollapse = () => {
      isCollapsed.value = !isCollapsed.value;
    };

    const copyToClipboard = () => {
      navigator.clipboard.writeText(props.code);
      showToast('Copied to clipboard', 'neutral');
    };

    onMounted(() => {
      nextTick(() => toggleCollapse());
    });

    return {
      codeBlockRef,
      isCollapsed,
      codeBlockHeight,
      toggleCollapse,
      copyToClipboard,
    };
  },
});
</script>

<style scoped lang="scss">
.sample-data {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.collapsable-title {
  &-wrapper {
    position: sticky;
    top: 0;
    background-color: white;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--xv-container--area);
  border-radius: 4px;

  &-left-part {
    display: flex;
    align-items: center;
    padding: 0 4px;
    font-size: var(--xv-text--mono-num-md--font-size);
    font-weight: var(--xv-text--mono-num-md--font-weight);
    line-height: var(--xv-text--mono-num-md--line-height);
    color: var(--xv-text--high-emphasis-text);
  }
}

.code-block {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  pre {
    background-color: transparent;
  }
}
</style>
