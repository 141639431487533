import { useSourceImageDataStore } from '@/store/sourceImageData';
import { nextTick, ref } from 'vue';
import { useLoadingStore } from '@/store/shared/useLoadingStore';
import { deleteImageAndUpdateStore, deleteWipSchemaAndUpdateStore } from '@/utils/dataService';
import { useNotification } from './useNotification';
import { XNotificationType } from '@asus-aics/xui';
import { ResourceContent, useResourceContentPageDataStore } from '@/store/resourceContentPageData';
import { storeToRefs } from 'pinia';
import { cloneDeep } from 'lodash';

interface ModalOptions {
  title: string;
  description: string;
  primaryButtonText: string;
  secondaryButtonText: string;
  primaryAction: () => void;
}

const useCannotEditWarningModal = () => {
  const showCannotEditWarningModal = ref(false);
  const onCloseCannotEditWarningModal = () => {
    showCannotEditWarningModal.value = false;
  };
  return { showCannotEditWarningModal, onCloseCannotEditWarningModal };
};

const executePendingFunctions = (pendingFunctionsValue: Function[], clearPendingFunctions: Function) => {
  for (const func of pendingFunctionsValue) {
    func();
  }
  clearPendingFunctions();
};

const useUnsavedChangesModal = (setIsEditing: (value: boolean) => void, saveContent: () => Promise<void>) => {
  const showUnsavedChangesModal = ref(false);
  const pendingFunctions = ref([] as (() => void)[]);

  const onCloseUnsavedChangesModal = () => {
    showUnsavedChangesModal.value = false;
    clearPendingFunctions();
  };

  const onDontSaveChangesModal = () => {
    setIsEditing(false);
    showUnsavedChangesModal.value = false;
    executePendingFunctions(pendingFunctions.value, clearPendingFunctions);
  };

  const onSaveChangesModal = async () => {
    await saveContent();
    showUnsavedChangesModal.value = false;
    executePendingFunctions(pendingFunctions.value, clearPendingFunctions);
  };

  const addPendingFunction = (func: () => void) => {
    pendingFunctions.value.push(func);
  };

  const clearPendingFunctions = () => {
    pendingFunctions.value = [];
  };

  return {
    showUnsavedChangesModal,
    onCloseUnsavedChangesModal,
    onDontSaveChangesModal,
    onSaveChangesModal,
    addPendingFunction,
  };
};

const useConfirmDeleteImageModal = () => {
  const showConfirmDeleteImageModal = ref(false);
  const deleteUuid = ref('');
  const onCloseConfirmDeleteImageModal = () => {
    showConfirmDeleteImageModal.value = false;
  };

  const onDeleteConfirmDeleteImageModal = async (collectionName: string) => {
    const sourceImageDataStore = useSourceImageDataStore();
    const { addPendingDeletePictureUuid, deleteEditingPictureByUuid, clearPendingDeletePictureUuid } =
      sourceImageDataStore;
    const { pendingDeletePictureUuid } = storeToRefs(sourceImageDataStore);
    addPendingDeletePictureUuid(deleteUuid.value);
    deleteEditingPictureByUuid(deleteUuid.value);
    deleteUuid.value = '';
    onCloseConfirmDeleteImageModal();
    const deleteResponses = [];
    for (const uuid of pendingDeletePictureUuid.value) {
      const deleteRes = await deleteImageAndUpdateStore(collectionName, uuid);
      deleteResponses.push(deleteRes);
    }
    clearPendingDeletePictureUuid();
    return deleteResponses;
  };

  const onDeleteImageClick = (_, uuid: string) => {
    deleteUuid.value = uuid;
    showConfirmDeleteImageModal.value = true;
  };

  return {
    showConfirmDeleteImageModal,
    deleteUuid,
    onCloseConfirmDeleteImageModal,
    onDeleteConfirmDeleteImageModal,
    onDeleteImageClick,
  };
};

const useSchemaCodeModal = () => {
  const showSchemaCodeModal = ref(false);
  const onCloseShowSchemaCodeModal = () => {
    showSchemaCodeModal.value = false;
  };

  return { showSchemaCodeModal, onCloseShowSchemaCodeModal };
};

const useCodeGenerateTabUnsavedChangesModal = (
  setIsCodeGenerateTabEditing: (value: boolean) => void,
  onCodeGenerateSaveClick: (wipId: any, wipName: any, newName: any, newNameCh: any) => Promise<void>
) => {
  const showCodeGenerateTabUnsavedChangeModal = ref(false);
  const pendingFunctions = ref([] as (() => void)[]);

  const onCloseCodeGenerateTabUnsavedChangeModal = () => {
    showCodeGenerateTabUnsavedChangeModal.value = false;
    clearPendingFunctions();
  };

  const onDontSaveCodeGenerateTabUnsavedChangeModal = () => {
    showCodeGenerateTabUnsavedChangeModal.value = false;
    setIsCodeGenerateTabEditing(false);
    executePendingFunctions(pendingFunctions.value, clearPendingFunctions);
  };

  const onSaveCodeGenerateTabUnsavedChangeModal = async (
    wipId: string,
    wipName: string,
    editingCollectionName: string,
    editingCollectionNameCn: string
  ) => {
    showCodeGenerateTabUnsavedChangeModal.value = false;
    await onCodeGenerateSaveClick(wipId, wipName, editingCollectionName, editingCollectionNameCn);
    executePendingFunctions(pendingFunctions.value, clearPendingFunctions);
  };

  const addPendingFunction = (func: () => void) => {
    pendingFunctions.value.push(func);
  };

  const clearPendingFunctions = () => {
    pendingFunctions.value = [];
  };

  return {
    showCodeGenerateTabUnsavedChangeModal,
    onCloseCodeGenerateTabUnsavedChangeModal,
    onDontSaveCodeGenerateTabUnsavedChangeModal,
    onSaveCodeGenerateTabUnsavedChangeModal,
    addPendingFunction,
  };
};

const useConfirmDeleteCollectionModal = () => {
  const showConfirmDeleteCollectionModal = ref(false);
  const deleteCollectionId = ref('');
  const onShowConfirmDeleteCollectionModalClick = () => {
    showConfirmDeleteCollectionModal.value = true;
  };
  const onDeleteCollectionModalClick = async (wipId: string, openPage: (page: string) => void) => {
    const { showLoading, hideLoading } = useLoadingStore();

    const { showNotification } = useNotification();
    showConfirmDeleteCollectionModal.value = false;
    showLoading(false);
    await deleteWipSchemaAndUpdateStore(wipId);
    hideLoading();
    showNotification(XNotificationType.Success, 'Deleted');
    openPage('collectionList');
  };
  const onCloseCollectionModal = () => {
    showConfirmDeleteCollectionModal.value = false;
  };

  return {
    showConfirmDeleteCollectionModal,
    deleteCollectionId,
    onShowConfirmDeleteCollectionModalClick,
    onDeleteCollectionModalClick,
    onCloseCollectionModal,
  };
};

const useTwoButtonWarningModal = () => {
  const showTwoButtonWarningModal = ref(false);
  const twoButtonWarningModalOptions = ref({
    title: '',
    description: '',
    primaryButtonText: '',
    secondaryButtonText: '',
    primaryAction: () => {},
  } as ModalOptions);

  const onCloseTwoButtonWarningModal = () => {
    showTwoButtonWarningModal.value = false;
  };
  const onShowTwoButtonWarningModalClick = (_options: ModalOptions) => {
    showTwoButtonWarningModal.value = true;
    twoButtonWarningModalOptions.value.title = _options.title;
    twoButtonWarningModalOptions.value.description = _options.description;
    twoButtonWarningModalOptions.value.primaryButtonText = _options.primaryButtonText;
    twoButtonWarningModalOptions.value.secondaryButtonText = _options.secondaryButtonText;
    twoButtonWarningModalOptions.value.primaryAction = _options.primaryAction;
  };

  return {
    showTwoButtonWarningModal,
    onCloseTwoButtonWarningModal,
    onShowTwoButtonWarningModalClick,
    twoButtonWarningModalOptions,
  };
};

const useResourceContentEditModal = () => {
  const showResourceContentEditModal = ref(false);
  const onOpenResourceContentEditModal = (item: ResourceContent) => {
    const store = useResourceContentPageDataStore();
    const { currentEditResourceContentItem } = storeToRefs(store);
    currentEditResourceContentItem.value = cloneDeep(item);
    showResourceContentEditModal.value = true;
  };
  const onCopyResourceContentEditModal = (item: ResourceContent) => {
    const store = useResourceContentPageDataStore();
    const { currentEditResourceContentItem } = storeToRefs(store);
    currentEditResourceContentItem.value = cloneDeep(item);
    currentEditResourceContentItem.value.id = null;
    currentEditResourceContentItem.value.propertyName = currentEditResourceContentItem.value.propertyName + 'Copy';
    currentEditResourceContentItem.value.propertyNameCn =
      currentEditResourceContentItem.value.propertyNameCn + ' (Copy)';
    showResourceContentEditModal.value = true;
  };
  const onNewResourceContentEditModal = () => {
    const store = useResourceContentPageDataStore();
    const { currentEditResourceContentItem } = storeToRefs(store);
    currentEditResourceContentItem.value = {
      id: null,
      propertyName: '',
      propertyNameCn: '',
      fieldPath: '',
      fieldDataType: '',
      elementDataType: null,
      required: false,
      source: '',
      sourceNote: '',
      note: '',
      tags: '',
      layer: 0,
      parentName: 'root',
      enum: [],
      enumString: '',
      defaultValue: '',
      children: [],
    };
    showResourceContentEditModal.value = true;
    nextTick(() => {
      const textAreaHtmlElement = document.querySelector('#resource-content-edit-modal-property-name');
      (textAreaHtmlElement as HTMLTextAreaElement).focus();
    });
  };
  const onCloseResourceContentEditModal = () => {
    showResourceContentEditModal.value = false;
  };
  return {
    showResourceContentEditModal,
    onOpenResourceContentEditModal,
    onCopyResourceContentEditModal,
    onNewResourceContentEditModal,
    onCloseResourceContentEditModal,
  };
};

export {
  useCannotEditWarningModal,
  useUnsavedChangesModal,
  useConfirmDeleteImageModal,
  useSchemaCodeModal,
  useCodeGenerateTabUnsavedChangesModal,
  useConfirmDeleteCollectionModal,
  useTwoButtonWarningModal,
  useResourceContentEditModal,
};
