<template>
  <div
    class="list-item"
    @click="$emit('click', category, collectionName, collectionNameCn, contentTypeTranslation(contentType), wipId)"
  >
    <span class="xv-body--body-lg">
      <template v-for="result in processedSearchText" :key="result.key">
        <template v-if="result.match"
          ><span class="xv-body--body-lg strong-text">{{ result.text }}</span></template
        >
        <template v-else>{{ result.text }}</template>
      </template>
    </span>
    <div class="file-path">
      <span class="xv-body--body-sm file-path-text">{{ category }}</span>
      <XIcon icon="chevron-right" style="height: 20px; width: 20px" color="rgba(38, 38, 38, 0.68)" />
      <span class="xv-body--body-sm file-path-text"
        >{{ collectionName }}{{ collectionNameCn !== '' ? ' (' + collectionNameCn + ')' : '' }}</span
      >
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { XIcon } from '@asus-aics/xui';
import { processSearchText } from '@/utils/shared/searchUtils';

export default defineComponent({
  name: 'SearchResultListItem',
  components: {
    XIcon,
  },
  props: {
    searchText: {
      type: String,
      required: true,
    },
    category: {
      type: String,
      required: true,
    },
    collectionName: {
      type: String,
      required: true,
    },
    collectionNameCn: {
      type: String,
      required: true,
    },
    contentType: {
      type: String,
      required: true,
    },
    wipId: {
      type: String,
      default: '',
      required: false,
    },
  },
  emits: ['click'],
  setup(props) {
    const contentTypeTranslation = (contentType) => {
      switch (contentType) {
        case 'content':
          return 'content';
        case 'resource_content':
          return 'resourceContent';
        default:
          return '';
      }
    };

    const processedSearchText = computed(() => {
      return processSearchText(props.searchText);
    });
    return {
      processedSearchText,
      contentTypeTranslation,
    };
  },
});
</script>

<style scoped lang="scss">
.list-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px 4px 9px 4px;
  border-bottom: 1px solid var(--text-dividing-line, rgba(38, 38, 38, 0.23));
  cursor: pointer;

  &:hover {
    background-color: var(--xv-container--surface-hovered);
  }
}

.file-path {
  display: flex;
  align-items: center;
  gap: 4px;
}
.file-path-text {
  color: var(--xv-text--medium-emphasis-text);
}

.strong-text {
  font-weight: 700;
}
</style>
