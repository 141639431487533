<template>
  <component
    :is="rootComponent"
    ref="target"
    data-qa-xui="XTooltip"
    class="x-tooltip"
    :class="{
      'x-ellipsis--with-line-limits': ellipsis,
      'over-two-line-text': isOverTwoLineText,
    }"
    :style="{
      '-webkit-line-clamp': ellipsisLineLimits,
    }"
    @mouseover="() => onMouseOver()"
  >
    <slot></slot>
  </component>
</template>

<script lang="ts">
import { Props, roundArrow } from 'tippy.js';
import { defineComponent, nextTick, PropType, ref, toRefs, watch } from 'vue';
import { useTippy, MaybeTippyElementRef, IsComponentType } from '@asus-aics/xui';

export default defineComponent({
  name: 'XTooltipWithLineLimits',
  props: {
    content: {
      default: '',
      type: [String, Object] as PropType<MaybeTippyElementRef>,
    },
    rootComponent: {
      default: 'div',
      type: [String, Object] as PropType<IsComponentType>,
    },
    options: {
      default: () => ({}),
      type: Object as PropType<Partial<Props>>,
    },
    ellipsis: {
      type: Boolean,
      default: false,
    },
    ellipsisOnEventTarget: {
      type: Boolean,
      default: false,
    },
    ellipsisLineLimits: {
      type: Number,
      default: 5,
    },
  },
  setup(props) {
    const ONE_LINE_OFFSET_HEIGHT = 24;
    const isOverTwoLineText = ref<boolean>(false);
    const { content } = toRefs(props);
    const { target, tippyInstance } = useTippy(undefined, content as MaybeTippyElementRef, {
      arrow: roundArrow,
      ...props.options,
    });

    // handle ellipsis
    const onMouseOver = async () => {
      if (!props.ellipsis) {
        return;
      }
      const isTextClamped = (element) => element.scrollHeight > element.clientHeight;

      if (isTextClamped(target.value as HTMLElement)) {
        tippyInstance.value?.enable();
        tippyInstance.value?.show();
      } else {
        tippyInstance.value?.disable();
        tippyInstance.value?.hide();
      }
    };

    watch(
      () => props.content,
      () => {
        nextTick(() => {
          isOverTwoLineText.value = (target.value as HTMLElement)?.clientHeight > ONE_LINE_OFFSET_HEIGHT;
        });
      },
      { immediate: true }
    );
    return {
      target,
      onMouseOver,
      isOverTwoLineText,
    };
  },
});
</script>

// eslint-disable-next-line vue-scoped-css/enforce-style-type
<style lang="scss">
.x-tooltip {
  font-size: var(--xv-body--body-md--font-size);
  font-weight: var(--xv-body--body-md--font-weight);
  line-height: var(--xv-body--body-md--line-height);
}

.x-ellipsis--with-line-limits {
  overflow: hidden;
  overflow-wrap: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  text-overflow: ellipsis;
  white-space: pre-wrap;
}

.over-two-line-text {
  margin-top: 8px;
  margin-bottom: 8px;
}
</style>
