import { defineStore } from 'pinia';

interface ConstantData {
  dataTypes: string[];
  elementDataTypes: string[];
  directions: string[];
}
export const useConstantDataStore = defineStore('constantData', {
  state: () => ({
    constantData: {} as ConstantData,
  }),
  getters: {},
  actions: {
    setDataTypes(dataTypes: string[]) {
      this.constantData.dataTypes = dataTypes;
    },
    setElementDataTypes(elementDataTypes: string[]) {
      this.constantData.elementDataTypes = elementDataTypes;
    },
    setDirections(directions: string[]) {
      this.constantData.directions = directions;
    },
  },
});
