import router from '@/router';
import { useInfoDataStore } from '@/store/infoData';
import axios from 'axios';
import { setApiHospital } from './dataApi';
import { GENERIC_ERROR_MESSAGE, TOO_MANY_ATTEMPTS_ERROR, UNAUTHORIZED_ERROR } from '@/utils/shared/constants';
import { SignUpError, TooManyRequestsError } from './authApiError';

const BASE_URL = import.meta.env.VITE_XHIS_CDP_PORTAL_API_URL;

const apiInstance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});
const loginByAzure = async () => {
  fetch(BASE_URL + '/api/oauth2/login', {
    redirect: 'manual',
  })
    .then((res) => {
      if (res.type === 'opaqueredirect') {
        // redirect to login page
        window.location.href = res.url;
      } else {
        // handle normally / pass on to next handler
      }
    })
    .catch((error) => {
      // handle fetch error here
      console.error(error);
    });
};

const logoutByAzure = async () => {
  fetch(BASE_URL + '/api/user/logout', {
    redirect: 'manual',
  })
    .then((res) => {
      if (res.type === 'opaqueredirect') {
        // redirect to logout page
        window.location.href = res.url;
      } else {
        // handle normally / pass on to next handler
      }
    })
    .catch((error) => {
      // handle fetch error here
      console.error(error);
    });
};

const loginByAccount = async (email: string, password: string) => {
  try {
    const response = await apiInstance.post(
      '/api/user/login/',
      {
        username: email,
        password,
      },
      { maxRedirects: 0 }
    );
    if (response.status === 200) {
      if (response.request.responseURL.includes('main')) {
        router.push('/main');
      } else {
        const { message, first_part_code, flow_token, action } = response.data;
        return { message, firstPartCode: first_part_code, flowToken: flow_token, action };
      }
    } else {
      // Handle non-200 status codes

      throw new Error(GENERIC_ERROR_MESSAGE);
    }
  } catch (error) {
    throw new Error(error.response?.data?.message || GENERIC_ERROR_MESSAGE);
  }
};

const verifyTwoFALogin = async (
  email: string,
  first_part_code: string,
  second_part_code: string,
  flowToken: string
) => {
  try {
    const response = await apiInstance.post('/api/two_fa/post_login/', {
      username: email,
      code: `${first_part_code}-${second_part_code}`,
      flow_token: flowToken,
    });
    if (response.status === 200) {
      if (response.request.responseURL.includes('main')) {
        router.push('/main');
      } else {
        const { message } = response.data;
        return { success: true, message, tooManyAttempts: false };
      }
    } else {
      return { success: false, message: GENERIC_ERROR_MESSAGE, tooManyAttempts: false };
    }
  } catch (error) {
    let errorMessage = GENERIC_ERROR_MESSAGE;
    let tooManyAttempts = false;

    if (error.response?.status === 429) {
      errorMessage = TOO_MANY_ATTEMPTS_ERROR;
      tooManyAttempts = true;
    } else {
      errorMessage = error.response?.data?.message || errorMessage;
    }

    return { success: false, message: errorMessage, tooManyAttempts };
  }
};

const resendTwoFACode = async (email: string, flowToken: string) => {
  try {
    const response = await apiInstance.post('/api/two_fa/resend_code/', {
      username: email,
      flow_token: flowToken,
    });

    if (response.status === 200) {
      // Handle success
      const { message, first_part_code } = response.data;
      return { message, firstPartCode: first_part_code };
    } else {
      // Handle non-200 status codes
      throw new Error(GENERIC_ERROR_MESSAGE);
    }
  } catch (error) {
    let errorMessage = GENERIC_ERROR_MESSAGE;

    if (error?.response?.data?.message) {
      errorMessage = error.response.data.message;
    }

    if (error?.response?.status === 429) {
      throw new TooManyRequestsError(errorMessage);
    }
    throw new Error(errorMessage);
  }
};

interface ResetPasswordResponse {
  message: string;
  firstPartCode: string;
  flowToken: string;
}

const resetPassword = async (email: string): Promise<ResetPasswordResponse | null> => {
  try {
    const response = await apiInstance.post('/api/user/reset_password/', {
      username: email,
    });

    if (response.status === 200) {
      const { message, first_part_code, flow_token } = response.data;
      return { message, firstPartCode: first_part_code, flowToken: flow_token };
    } else {
      throw new Error(GENERIC_ERROR_MESSAGE);
    }
  } catch (error) {
    throw new Error(error.response?.data?.message || GENERIC_ERROR_MESSAGE);
  }
};

const postResetPassword = async (
  email: string,
  password: string,
  first_part_code: string,
  second_part_code: string,
  flowToken: string
) => {
  try {
    const response = await apiInstance.post('/api/two_fa/post_reset_password/', {
      username: email,
      password,
      code: `${first_part_code}-${second_part_code}`,
      flow_token: flowToken,
    });

    if (response.status === 200) {
      const { message } = response.data;
      return { success: true, message, invalidCode: false };
    } else {
      return { success: false, message: GENERIC_ERROR_MESSAGE, invalidCode: false };
    }
  } catch (error) {
    let errorMessage: string;
    let invalidCode = false;
    errorMessage = error.response?.data?.message || GENERIC_ERROR_MESSAGE;

    if (errorMessage === 'The code is expired or incorrect') {
      invalidCode = true;
    }
    return { success: false, message: errorMessage, invalidCode };
  }
};

interface SignUpInfo {
  projects: string[];
  positions: string[];
}

const getSignUpInfo = async (): Promise<SignUpInfo> => {
  try {
    const response = await apiInstance.get('/api/user/sign_up_info/', { withCredentials: false });
    const { projects, positions } = response.data;
    return { projects, positions };
  } catch (error) {
    throw new Error(error.response?.data?.message || GENERIC_ERROR_MESSAGE);
  }
};

interface SignUpParams {
  username: string;
  password: string;
  first_name: string;
  last_name: string;
  project: string;
  position: string;
}

const signUp = async (params: SignUpParams) => {
  try {
    const { username, password, first_name, last_name, project, position } = params;
    const response = await apiInstance.post('/api/user/sign_up/', {
      username,
      password,
      first_name,
      last_name,
      project,
      position,
    });

    if (response.status === 200) {
      const { message, first_part_code, flow_token, action } = response.data;
      return { message, firstPartCode: first_part_code, flowToken: flow_token, action };
    } else {
      throw new Error(GENERIC_ERROR_MESSAGE);
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message || GENERIC_ERROR_MESSAGE;

    throw new SignUpError(errorMessage, {
      username: error.response?.data?.username,
      password: error.response?.data?.password,
      firstName: error.response?.data?.first_name,
      lastName: error.response?.data?.last_name,
    });
  }
};

const postSignUp = async (email: string, first_part_code: string, second_part_code: string, flowToken: string) => {
  try {
    const response = await apiInstance.post('/api/two_fa/post_sign_up/', {
      username: email,
      code: `${first_part_code}-${second_part_code}`,
      flow_token: flowToken,
    });

    if (response.status === 200) {
      const { message } = response.data;
      return { success: true, message, tooManyAttempts: false };
    } else {
      return { success: false, message: GENERIC_ERROR_MESSAGE, tooManyAttempts: false };
    }
  } catch (error) {
    let errorMessage = GENERIC_ERROR_MESSAGE;
    let tooManyAttempts = false;

    if (error.response?.status === 429) {
      errorMessage = TOO_MANY_ATTEMPTS_ERROR;
      tooManyAttempts = true;
    } else {
      errorMessage = error.response?.data?.message || errorMessage;
    }

    return { success: false, message: errorMessage, tooManyAttempts };
  }
};

interface UserInfo {
  email: string;
  username: string;
  first_name: string;
  last_name: string;
  groups: string[];
  project: string;
}

interface LoginApiError {
  message: string;
  status: number;
}

const getUserInfo = async (): Promise<UserInfo | LoginApiError> => {
  try {
    const response = await apiInstance.get('/api/user/info/');
    const data = response.data as UserInfo;
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const unauthorizedError = {
        message: UNAUTHORIZED_ERROR,
        status: 401,
      };
      return unauthorizedError;
    } else {
      const genericError = {
        message: GENERIC_ERROR_MESSAGE,
        status: error.response ? error.response.status : null,
      };
      return genericError;
    }
  }
};

const getUserInfoAndUpdateStore = async () => {
  const infoResult = await getUserInfo();
  const { setUser, setCurrentHospital } = useInfoDataStore();
  if ('status' in infoResult && infoResult.status === 401) {
    if (import.meta.env.VITE_XHIS_CDP_PORTAL_BYPASS_AUTH === 'false') {
      router.push('/');
    }
  } else if ('status' in infoResult) {
    console.error(infoResult.message);
  } else {
    setUser({
      first_name: infoResult.first_name,
      last_name: infoResult.last_name,
      username: infoResult.username,
      groups: infoResult.groups,
    });

    setCurrentHospital(infoResult.project);
    setApiHospital(infoResult.project);
  }
};

export {
  apiInstance,
  loginByAzure,
  logoutByAzure,
  getUserInfo,
  getUserInfoAndUpdateStore,
  loginByAccount,
  verifyTwoFALogin,
  resendTwoFACode,
  resetPassword,
  postResetPassword,
  getSignUpInfo,
  signUp,
  postSignUp,
};
