import { unref, ref } from 'vue';
import { MaybeRef } from '@vueuse/core';
import { useTippy } from '@asus-aics/xui';
import { Props } from 'tippy.js';

export const useContextmenu = (isEnabled: MaybeRef<boolean> = true, tippyOptions: Partial<Props> = {}) => {
  // since we change reference client rect on contextmenu event, target is not important here
  const dummyTarget = ref<HTMLDivElement>(document.createElement('div'));
  const { content: contextmenuRef, tippyInstance } = useTippy(dummyTarget, undefined, {
    trigger: 'manual',
    theme: 'dropdown',
    arrow: false,
    placement: 'right-start',
    interactive: true,
    offset: [0, 8],
    animation: false,
    appendTo: (document.getRootNode() as Document)?.body,
    ...tippyOptions,
  });

  const handleContextmenu = (event: MouseEvent): void => {
    if (!unref(isEnabled)) {
      return;
    }
    event.preventDefault();

    // reference: https://atomiks.github.io/tippyjs/v6/misc/#context-menu
    unref(tippyInstance)?.setProps({
      getReferenceClientRect: () =>
        ({
          width: 0,
          height: 0,
          top: event.clientY,
          bottom: event.clientY,
          left: event.clientX,
          right: event.clientX,
        } as DOMRect),
    });
    unref(tippyInstance)?.show();
  };

  const hideContextmenu = (): void => {
    unref(tippyInstance)?.hide();
  };

  return {
    contextmenuRef,
    handleContextmenu,
    hideContextmenu,
  };
};
