<template>
  <XDialogue id="single" :show="show" :width="960" :height="810">
    <div class="dialogue-container">
      <div class="description-and-action">
        <div class="modal-header">
          <span class="xv-text--title-lg">Schema Code</span>
          <XIconButton icon="dismiss" @click="closeDialogue" />
        </div>
        <div class="modal-body">
          <div class="x-textarea x-scroll-bar code-text-area" :style="{ overflowY: 'auto' }">
            <highlightjs language="typescript" :code="codeGenerateText" />
          </div>
        </div>
        <div class="action-footer">
          <XButton class="action-button" size="lg" icon="copy" style="width: 125px" @click="onCopyClick">Copy</XButton>
        </div>
      </div>
    </div>
  </XDialogue>
  <XToast v-model:visible="isToastVisible" content="Copied to clipboard" theme="neutral" placement="middle"></XToast>
</template>
<script lang="ts">
import { useCodeGenerateTabDataStore } from '@/store/codeGenerateTabData';
import { XButton, XDialogue, XIconButton, XTextArea, XToast } from '@asus-aics/xui';
import { storeToRefs } from 'pinia';
import { defineComponent, ref } from 'vue';
export default defineComponent({
  name: 'SchemaCodeModal',
  components: { XButton, XDialogue, XIconButton, XTextArea, XToast },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['on-modal-close', 'on-dont-save', 'on-save'],
  setup(props, { emit }) {
    const store = useCodeGenerateTabDataStore();
    const { codeGenerateText } = storeToRefs(store);
    const closeDialogue = () => {
      emit('on-modal-close');
    };

    const dontSave = () => {
      emit('on-dont-save');
    };

    const save = () => {
      emit('on-save');
    };

    const onCopyClick = () => {
      navigator.clipboard.writeText(codeGenerateText.value);
      isToastVisible.value = true;
    };

    const isToastVisible = ref<boolean>(false);
    return {
      closeDialogue,
      dontSave,
      save,
      onCopyClick,
      isToastVisible,
      codeGenerateText,
    };
  },
});
</script>
<style scoped lang="scss">
.dialogue-container {
  display: flex;
  height: 100%;
}
.description-and-action {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  flex: 1 0 0;
}

.modal-header {
  padding: 16px 24px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  box-shadow: 0px -1px 0px 0px rgba(38, 38, 38, 0.18) inset;
}

.modal-body {
  display: flex;
  align-items: center;
  padding: 20px 20px 0 20px;
  width: 100%;
}
.action-footer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 16px;
  padding: 24px 32px;
}

.code-text-area {
  width: 100%;
  height: 606px;
}
.cancel-and-save-area {
  display: flex;
  gap: 16px;
}
.medium-emphasis-text {
  color: var(--xv-text--medium-emphasis-text);
}
</style>
