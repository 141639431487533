import axios from 'axios';
import { Category } from '@/store/collectionDrawerData';
import { useInfoDataStore } from '@/store/infoData';
import { storeToRefs } from 'pinia';
import { GENERIC_ERROR_MESSAGE } from '@/utils/shared/constants';
// #region API Get
interface ApiCollectionChildren {
  collection_name: string;
  collection_name_ch: string;
  updated_at: string;
  wip_id?: string;
}
export interface ApiCollection {
  category_name: string;
  children: ApiCollectionChildren[];
}

export interface ApiKeyObj {
  key: string;
  direction: string;
  does_field_exist: boolean;
}
export interface ApiContentIndex {
  index_name: string;
  wip_id?: string;
  key_objs: ApiKeyObj[];
  use_default_name: boolean;
  unique: boolean;
  expire_after_seconds: number | null;
  isValid?: boolean;
}
export interface ApiContent {
  collection_name: string;
  collection_name_ch: string;
  category_name?: string;
  scope_and_usage: string;
  background_and_context: string;
  service_to_this_resource: string;
  updated_at: string;
  index: ApiContentIndex[];
  wip_id?: string;
  wip_name?: string;
  owner?: string;
}

export interface ApiResourceContent {
  fields: ApiResourceContentItem[];
  total_count: number;
}
export interface ApiResourceContentItem {
  id: number;
  field_name: string;
  field_name_ch: string;
  field_path: string;
  field_data_type: string;
  element_data_type: string | null;
  required: boolean;
  source: string;
  source_note: string;
  note: string;
  tags: string;
  layer: number;
  enum_string?: string;
  field_options?: {
    enum?: string[];
    default?: string;
  };
  children?: ApiResourceContentItem[];
}
export interface ApiSearchResult {
  headline: string;
  category_name: string;
  collection_name: string;
  content_type: string;
  collection_name_ch: string;
  wip_id: string;
  rank: number;
}

export interface ApiImage {
  uuid: string;
  collection_name: string;
  image_name: string;
  image_url: string;
  thumbnail_url: string;
  updated_at: string;
}
// #endregion API Get

// #region API Post
export interface ApiPostContent {
  category_name: string;
  collection_name: string;
  collection_name_ch: string;
  scope_and_usage: string;
  background_and_context: string;
  service_to_this_resource: string;
}

export interface ApiPostFieldData {
  field_path: string;
  field_name_ch: string;
  note: string;
  tags: string;
  source: string;
  source_note: string;
}
export interface ApiEditWipContent {
  selectable_fields: string[];
}

export interface ApiEditWipResourceContent {
  parent: string;
  selectable_parents: string[];
}

export interface ApiSaveWipSchema {
  wip_name: string;
  collection_name: string;
  collection_name_ch: string;
  scope_and_usage: string;
  background_and_context: string;
  service_to_this_resource: string;
  index: ApiContentIndex[];
}

export interface ApiSaveWipResourceContentItem {
  id: number;
  field_name: string;
  field_name_ch: string;
  parent: string;
  field_data_type: string;
  element_data_type: string | null;
  required: boolean;
  default: string | null;
  enum_string: string | null;
  source: string;
  source_note: string;
  note: string;
  tags: string;
}
export interface ApiConstantData {
  data_types: string[];
  element_data_types: string[];
  directions: string[];
}

export interface ApiDalApiListItem {
  id: number;
  api_name: string;
  related_collections: string[];
}
export interface ApiDalApiList {
  api_type: string;
  children: ApiDalApiListItem[];
}

export interface ApiDalApiInput {
  id: number;
  object_name: string;
  object_type: string;
  object_string: string;
}

export interface ApiDalApiOutput {
  id: number;
  object_name: string;
  object_type: string;
  object_string: string;
}

export interface ApiDalApiNode {
  id: number;
  node_name: string;
  node_type: string;
  node_content: string;
}

export interface ApiDalApiEdge {
  id: number;
  from_node: number;
  to_node: number;
  edge_content: string;
}
export interface ApiDalApi {
  id: number;
  api_name: string;
  api_type: string;
  api_string: string;
  related_collections: string[];
  input: ApiDalApiInput[];
  output: ApiDalApiOutput[];
  nodes: ApiDalApiNode[];
  edges: ApiDalApiEdge[];
}

export interface ApiCommitVersion {
  commit: string;
}

export interface ApiCopyDataFromWipToCollectionSuccessResponse {
  status: 'success';
  collection_name: string;
}
export interface ApiCopyDataFromWipToCollectionErrorResponse {
  detail: string;
}
export type ApiCopyDataFromWipToCollectionResponse =
  | ApiCopyDataFromWipToCollectionSuccessResponse
  | ApiCopyDataFromWipToCollectionErrorResponse;
// #endregion API Post

export interface ApiHospitalList {
  projects: string[];
}
const BASE_URL = import.meta.env.VITE_XHIS_CDP_PORTAL_API_URL;

export const apiInstance = axios.create({
  baseURL: BASE_URL,
});

const getVersion = async () => {
  try {
    const response = await apiInstance.get('/api/mongoose/latest_version/');
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const setApiVersion = async () => {
  try {
    const response = await getVersion();
    apiInstance.defaults.params = {
      ...apiInstance.defaults.params,
      version: response.version,
    };
    const store = useInfoDataStore();
    store.setVersions(response.version);
  } catch (error) {
    console.error('An error occurred while setting API version:', error);
  }
};

export const setApiHospital = async (currentHospital: string) => {
  apiInstance.defaults.params = {
    ...apiInstance.defaults.params,
    project_name: currentHospital,
  };
};

export const getApiHospitalList = async () => {
  try {
    const response = await apiInstance.get('/api/portal/projects/');
    const data = response.data as ApiHospitalList;
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export async function getCollections() {
  try {
    const response = await apiInstance.get('/api/portal/main_page/');
    const data = response.data as ApiCollection[];
    return data;
  } catch (error) {
    console.error(error);
  }
}

export async function getCategories() {
  try {
    const response = await apiInstance.get('/api/portal/categories/');
    const data = response.data as Category[];
    return data;
  } catch (error) {
    console.error(error);
  }
}

export async function getContentByCollectionName(
  collectionName: string,
  rank: number | null = null,
  keyword: string | null = null
) {
  try {
    const fromSearchParameter = rank ? { from_search: true, rank, keyword } : {};
    const response = await apiInstance.get('/api/portal/collection_content/', {
      params: {
        collection_name: collectionName,
        ...fromSearchParameter,
      },
    });
    const data = response.data as ApiContent;
    return data;
  } catch (error) {
    console.error(error);
  }
}

export async function getContentByWipId(wipId: string, rank: number | null = null, keyword: string | null = null) {
  try {
    const fromSearchParameter = rank ? { from_search: true, rank, keyword } : {};
    const response = await apiInstance.get('/api/wip/contents/', {
      params: {
        wip_id: wipId,
        ...fromSearchParameter,
      },
    });
    const data = response.data as ApiContent;
    return data;
  } catch (error) {
    console.error(error);
  }
}

export async function getResourceContentByCollectionName(
  collectionName: string,
  rank: number | null = null,
  keyword: string | null = null
) {
  try {
    const fromSearchParameter = rank ? { from_search: true, rank, keyword } : {};
    const response = await apiInstance.get('/api/portal/collection_resource_content/', {
      params: {
        collection_name: collectionName,
        ...fromSearchParameter,
      },
    });
    const data = response.data as ApiResourceContent;
    return data;
  } catch (error) {
    console.error(error);
  }
}

export async function getResourceContentByWipId(
  wipId: string,
  rank: number | null = null,
  keyword: string | null = null
) {
  try {
    const fromSearchParameter = rank ? { from_search: true, rank, keyword } : {};
    const response = await apiInstance.get('/api/wip/resource_contents/', {
      params: {
        wip_id: wipId,
        ...fromSearchParameter,
      },
    });
    const data = response.data as ApiResourceContent;
    return data;
  } catch (error) {
    console.error(error);
  }
}

export async function saveContentByCollectionName(apiPostContent: ApiPostContent) {
  try {
    const response = await apiInstance.post('/api/portal/collection_content/', apiPostContent);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
  }
}

export async function saveResourceContentByFieldData(apiPostFieldData: ApiPostFieldData) {
  try {
    const response = await apiInstance.post('/api/portal/collection_resource_content/', apiPostFieldData);
    const data = response.data;

    return data;
  } catch (error) {
    console.error(error);
  }
}

export async function searchContentByKeyword(keyword: string) {
  try {
    const response = await apiInstance.get('/api/portal/search/', {
      params: {
        q: keyword,
      },
    });
    const data = response.data as ApiSearchResult[];
    return data;
  } catch (error) {
    console.error(error);
  }
}

export async function getImagesByCollectionName(collectionName: string) {
  try {
    const response = await apiInstance.get('/api/portal/images/', {
      params: {
        collection_name: collectionName,
      },
    });
    const data = response.data as ApiImage[];
    return data;
  } catch (error) {
    console.error(error);
  }
}

export async function uploadImage(collectionName: string, image: File) {
  try {
    const formData = new FormData();
    formData.append('collection_name', collectionName);
    formData.append('image_name', image.name);
    formData.append('image', image);
    const response = await apiInstance.post('/api/portal/images/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    return error;
  }
}

export async function deleteImage(uuid: string) {
  try {
    const response = await apiInstance.delete(`/api/portal/images/${uuid}/`);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
  }
}

export async function patchImage(uuid: string, newName: string) {
  try {
    const response = await apiInstance.patch(`/api/portal/images/${uuid}/`, {
      image_name: newName,
    });
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
  }
}

export async function createWipCollection(collectionName: string | null) {
  try {
    const store = useInfoDataStore();
    const { version } = storeToRefs(store);
    const paramVersion = collectionName ? version.value : null;
    const response = await apiInstance.post('/api/portal/wip/', null, {
      params: {
        collection_name: collectionName,
        version: paramVersion,
      },
    });
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
  }
}

export async function patchWipSchema(wipId: string, saveWipSchema: ApiSaveWipSchema) {
  try {
    const response = await apiInstance.patch(`/api/wip/contents/`, saveWipSchema, {
      params: {
        wip_id: wipId,
      },
    });
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function deleteWipSchema(wipId: string) {
  try {
    const response = await apiInstance.delete('/api/portal/wip/', {
      params: {
        wip_id: wipId,
      },
    });
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
  }
}

export async function getGenerateCode(wipId: string) {
  try {
    const response = await apiInstance.get('/api/portal/code/', {
      params: {
        wip_id: wipId,
      },
    });
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
  }
}

export async function editWipContent(wipId: string) {
  try {
    const response = await apiInstance.get('/api/wip/contents/edit/', {
      params: {
        wip_id: wipId,
      },
    });
    const data = response.data as ApiEditWipContent;
    return data;
  } catch (error) {
    console.error(error);
  }
}

export async function editWipResourceContent(wipId: string, id: number = null) {
  try {
    const response = await apiInstance.get('/api/wip/resource_contents/edit/', {
      params: {
        wip_id: wipId,
        id,
      },
    });
    const data = response.data as ApiEditWipResourceContent;
    return data;
  } catch (error) {
    console.error(error);
  }
}

export async function saveWipResourceContentItem(wipId: string, resourceContent: ApiSaveWipResourceContentItem) {
  try {
    const response = await apiInstance.post('/api/wip/resource_contents/', resourceContent, {
      params: {
        wip_id: wipId,
      },
    });
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    return error.response;
  }
}

export async function deleteResourceContent(itemId: number) {
  try {
    const response = await apiInstance.delete(`/api/wip/resource_contents/${itemId}/`);

    if (response.data.status === 'success') {
      return response.data;
    } else {
      throw new Error('API request failed');
    }
  } catch (error) {
    console.error('Error deleting resource content:', error);
    throw error;
  }
}

export async function getConstantData() {
  try {
    const response = await apiInstance.get('/api/constants/');
    const data = response.data as ApiConstantData;
    return data;
  } catch (error) {
    console.error(error);
  }
}

export async function getDalApiList(): Promise<ApiDalApiList[]> {
  try {
    const response = await apiInstance.get('/api/dal/apis/');
    const data = response.data as ApiDalApiList[];
    return data;
  } catch (error) {
    console.error(error);
  }
}

export async function getDalApiById(id: number): Promise<ApiDalApi> {
  try {
    const response = await apiInstance.get(`/api/dal/apis/${id}/`);
    const data = response.data as ApiDalApi;
    return data;
  } catch (error) {
    console.error(error);
  }
}

export async function getCommitVersion(): Promise<ApiCommitVersion> {
  try {
    const response = await apiInstance.get('/api/dal/commits/latest/');
    const data = response.data as ApiCommitVersion;
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function copyDataFromWipToCollection(wipId: string): Promise<ApiCopyDataFromWipToCollectionResponse> {
  try {
    const response = await apiInstance.post('/api/portal/wip/copy/', null, {
      params: {
        wip_id: wipId,
      },
    });
    const data: ApiCopyDataFromWipToCollectionSuccessResponse = response.data;
    if ('status' in data && data.status === 'success') {
      return data;
    }
  } catch (error) {
    console.error('An error occurred:', error);
    return error.response.data as ApiCopyDataFromWipToCollectionErrorResponse;
  }
}

export async function getSampleData(collectionName: string) {
  try {
    const response = await apiInstance.get('/api/portal/sample_data/', {
      params: {
        collection_name: collectionName,
      },
    });
    const data = response.data as string[];
    return data;
  } catch (error) {
    throw new Error(GENERIC_ERROR_MESSAGE);
  }
}
