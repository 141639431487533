import { ref } from 'vue';
import { XToastThemeType } from '@asus-aics/xui';

const toastText = ref('');
const toastTheme = ref<XToastThemeType>('success');
const isToastShow = ref(false);

const useToast = () => {
  function showToast(text: string, theme: XToastThemeType) {
    toastText.value = text;
    toastTheme.value = theme;
    isToastShow.value = true;
    // Logic to display the toast notification
  }

  return {
    showToast,
    toastText,
    toastTheme,
    isToastShow,
  };
};

export { useToast };
