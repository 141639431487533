import { ref, computed } from 'vue';

const useSignUpData = () => {
  const firstName = ref('');
  const firstNameError = ref(false);
  const lastName = ref('');
  const lastNameError = ref(false);

  const selectedProject = ref('');
  const projectFontColor = computed(() => {
    return selectedProject.value ? 'var(--xv-text--high-emphasis-text)' : 'var(--xv-text--dividing-line)';
  });
  const selectedPosition = ref('');
  const positionFontColor = computed(() => {
    return selectedPosition.value ? 'var(--xv-text--high-emphasis-text)' : 'var(--xv-text--dividing-line)';
  });

  const email = ref('');
  const emailError = ref(false);
  const newPassword = ref('');
  const confirmNewPassword = ref('');
  const passwordError = ref(false);

  const isTermsOfUseChecked = ref(false);

  const errorMessages = ref<string[]>([]);

  const resetErrorMessages = () => {
    errorMessages.value = [];
    firstNameError.value = false;
    lastNameError.value = false;
    emailError.value = false;
    passwordError.value = false;
  };


  return {
    firstName,
    firstNameError,
    lastName,
    lastNameError,
    selectedProject,
    projectFontColor,
    selectedPosition,
    positionFontColor,
    email,
    emailError,
    newPassword,
    confirmNewPassword,
    passwordError,
    isTermsOfUseChecked,
    errorMessages,
    resetErrorMessages,
  };
};
export { useSignUpData };
