<template>
  <div class="success-registration-modal">
    <div class="success-registration-modal-header">
      <span class="xv-headline--headline-sm">CDP Portal</span>
      <span class="xv-body--body-md">Sign Up</span>
    </div>
    <div class="success-registration-modal-body">
      <div class="success-registration-modal-body-icon-text">
        <XIcon icon="success-outline" />
        <span class="success-registration-modal-body-title">Email verified</span>
      </div>
      <span class="success-registration-modal-body-description"
        >You will be notified via email once your account has been successfully activated</span
      >
    </div>
    <div class="success-registration-modal-footer">
      <div class="success-registration-modal-footer-button-group">
        <XButton size="sm" display="text" @click="$emit('back-to-home')">Back to login page</XButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { XIcon, XButton } from '@asus-aics/xui';
export default defineComponent({
  name: 'SuccessRegistrationModal',
  components: { XIcon, XButton },
  setup() {
    return {};
  },
});
</script>

<style scoped lang="scss">
.success-registration-modal {
  display: flex;
  width: 400px;
  flex-direction: column;
  border-radius: 16px;
  background: var(--container-surface, #fff);

  box-shadow: 0px 4px 30px 0px rgba(157, 205, 245, 0.2), 0px 4px 16px 0px rgba(66, 66, 66, 0.2);

  &-header {
    padding: 24px 24px 24px 32px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
  }

  &-body {
    padding: 0 32px;
    display: flex;
    flex-direction: column;
    gap: 4px;

    &-description {
      font-size: var(--xv-text--body-md--font-size);
      font-weight: var(--xv-text--body-md--font-weight);
      line-height: var(--xv-text--body-md--line-height);
      color: var(--xv-text--high-emphasis-text);
      text-align: center;
    }

    &-icon-text {
      display: flex;
      gap: 4px;
      align-items: center;
      justify-content: center;
      color: var(--xv-status--success);
    }
    &-title {
      font-size: var(--xv-text--body-md--font-size);
      font-weight: var(--xv-text--body-md--font-weight);
      line-height: var(--xv-text--body-md--line-height);
    }
  }

  &-footer {
    padding: 16px 32px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    &-button-group {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
}
</style>
