<template>
  <div class="image-container">
    <div class="clickable-image-wrapper" @click="$emit('click', index)">
      <img class="clickable-image" :src="data" :alt="title" />
    </div>
    <div v-if="!isEditing || currentEditIndex !== index" class="caption-and-option">
      <XTooltip
        class="image-text"
        :content="title"
        ellipsis
        :options="{
          theme: 'blue-card',
          arrow: false,
        }"
        >{{ title }}</XTooltip
      >
      <XDropdown v-if="user.isAdmin && !isEditing">
        <template #default="{ isMenuOpened }"
          ><XIconButton class="action-button" icon="more-hori" size="sm"></XIconButton
        ></template>
        <template #dropdownList="{ hideMenu }">
          <XList>
            <XListItem
              @click.stop="
                () => {
                  editPicture();
                  hideMenu();
                }
              "
              >Edit name</XListItem
            >
            <XListItem
              @click.stop="
                () => {
                  deletePicture();
                  hideMenu();
                }
              "
              >Delete</XListItem
            >
          </XList>
        </template>
      </XDropdown>
    </div>
    <template v-else>
      <div class="edit-action-area">
        <XInputText
          type="search"
          size="sm"
          style="width: 100%"
          :model-value="currentEditingPicture.name"
          @update:model-value="(value) => (currentEditingPicture.name = value)"
          @input-blur="restoreTextIfEmpty"
        >
          <template #prefix><span></span></template>
        </XInputText>
        <XButton size="sm" theme="tertiary" outline @click="$emit('cancel-edit-picture')">Cancel</XButton>
        <XButton size="sm" @click="saveEditPicture">Save</XButton>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { XInputText, XIconButton, XDropdown, XList, XListItem, XTooltip, XButton } from '@asus-aics/xui';
import { useSourceImageDataStore } from '@/store/sourceImageData';
import { useInfoDataStore } from '@/store/infoData';
import { storeToRefs } from 'pinia';
export default defineComponent({
  name: 'ClickableImage',
  components: {
    XInputText,
    XIconButton,
    XDropdown,
    XList,
    XListItem,
    XTooltip,
    XButton,
  },
  props: {
    index: {
      default: 0,
      type: Number,
    },
    data: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    currentEditIndex: {
      type: Number,
      default: null,
    },
  },
  emits: ['click', 'delete-picture', 'edit-picture', 'cancel-edit-picture', 'save-edit-picture'],
  setup(props, { emit }) {
    const sourceImageDataStore = useSourceImageDataStore();
    const infoStore = useInfoDataStore();
    const { user } = storeToRefs(infoStore);
    const { currentEditingPicture } = storeToRefs(sourceImageDataStore);
    const deletePicture = () => {
      emit('delete-picture', props.index);
    };
    const editPicture = () => {
      emit('edit-picture', props.index);
    };

    const saveEditPicture = () => {
      emit('save-edit-picture', props.index);
    };

    const restoreTextIfEmpty = () => {
      if (currentEditingPicture.value.name === '') {
        currentEditingPicture.value.name = props.title;
      }
    };

    return {
      deletePicture,
      editPicture,
      restoreTextIfEmpty,
      user,
      saveEditPicture,
      currentEditingPicture,
    };
  },
});
</script>

<style scoped lang="scss">
.image-container {
  width: 750px;
}
.clickable-image-wrapper {
  cursor: pointer;
}

.clickable-image {
  width: 750px;
  height: 428px;
  object-fit: cover;
}

.image-text {
  display: flex;
  align-items: center;
}
.edit-action-area {
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 4px 0px 8px 0px;
  height: 44px;
}

.delete-button {
  color: var(--xv-status--error);
}

.caption-and-option {
  display: flex;
  height: 44px;
  align-items: center;
}
</style>
