import { ref } from 'vue';

const useMainPageState = () => {
  const isEditing = ref(false);
  const isCodeGenerateTabEditing = ref(false);

  const setIsEditing = (value: boolean) => {
    isEditing.value = value;
  };

  const setIsCodeGenerateTabEditing = (value: boolean) => {
    isCodeGenerateTabEditing.value = value;
  };

  return {
    isEditing,
    isCodeGenerateTabEditing,
    setIsEditing,
    setIsCodeGenerateTabEditing,
  };
};

export { useMainPageState };
