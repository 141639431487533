<template>
  <div class="overflow-wrapper">
    <div v-if="currentPictureList?.pictures?.length > 0" class="source-image-tab">
      <template v-for="(picture, index) in currentPictureList.pictures" :key="picture.pictureId">
        <ClickableImage
          :index="index"
          :data="picture.imageUrl"
          :title="picture.name"
          :isEditing="isEditing"
          :currentEditIndex="currentEditingImageIndex"
          @click="(index) => (imgIndex = index)"
          @delete-picture="(_index) => $emit('delete-picture', _index, picture.uuid)"
          @edit-picture="(index) => editPicture(index)"
          @cancel-edit-picture="$emit('cancel-edit-picture')"
          @save-edit-picture="(index) => $emit('save-edit-picture', index)"
        />
      </template>
    </div>
    <div v-else class="empty-state-block"><span class="xv-body--body-lg low-emphasis-text">No items</span></div>
  </div>
  <Gallery
    v-show="imgIndex !== null"
    :thumbnailImages="thumbnailImages"
    :originImages="opOriginPictures"
    :index="imgIndex"
    @closeGallery="imgIndex = null"
    @update:imgIndex="(idx: number) => imgIndex = idx"
  ></Gallery>
</template>

<script lang="ts">
import '@/assets/scss/scroll_bar.scss';
import { ref, defineComponent, watch, computed } from 'vue';
import ClickableImage from './ClickableImage.vue';
import Gallery from './Gallery.vue';

import { PictureList, useSourceImageDataStore } from '@/store/sourceImageData';
import { OpOriginPictureItem, OpPictureItem } from '@/utils/useTableData';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'SourceImageTab',
  components: { ClickableImage, Gallery },
  props: {
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['delete-picture', 'edit-picture', 'cancel-edit-picture', 'save-edit-picture'],
  setup(props, { emit }) {
    const sourceImageDataStore = useSourceImageDataStore();
    const { pictureList, editingPictureList, currentEditingPicture } = storeToRefs(sourceImageDataStore);
    const currentPictureList = computed<PictureList>(() => {
      return props.isEditing ? editingPictureList.value : pictureList.value;
    });
    const imgIndex = ref<null | number>(null);
    const opOriginPictures = ref<Array<OpOriginPictureItem>>();
    const thumbnailImages = ref<Array<OpPictureItem>>();
    const currentEditingImageIndex = ref<null | number>(null);

    const editPicture = (index: number) => {
      currentEditingImageIndex.value = index;
      currentEditingPicture.value = {
        ...currentPictureList.value.pictures[index],
      };
      emit('edit-picture');
    };

    watch(imgIndex, async (newImgIndex) => {
      imgIndex.value = newImgIndex;
      opOriginPictures.value = sourceImageDataStore.getOriginPictureList.opOriginPictures ?? [];
      thumbnailImages.value = sourceImageDataStore.getOriginPictureList.opPictures ?? [];
    });

    return {
      imgIndex,
      opOriginPictures,
      thumbnailImages,
      pictureList,
      currentPictureList,
      currentEditingImageIndex,
      editPicture,
    };
  },
});
</script>

<style scoped lang="scss">
.overflow-wrapper {
  height: 100%;
  width: 100%;
  overflow-y: auto;
}
.source-image-tab {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  row-gap: 36px;
  gap: 16px;
}

.low-emphasis-text {
  color: var(--xv-text--low-emphasis-text);
}

.empty-state-block {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
</style>
