<template>
  <div class="sample-data-tab">
    <div class="info-text">
      <XIcon
        icon="info-filled"
        color="var(--xv-status--primary)"
        :style="{ width: '16px', height: 'var(--xv-text--body-md--line-height)' }"
      />
      <span
        >All sample data are sourced from the current xHIS database. Please refer to the CDP portal for accurate
        information, if the sample data do not align with the schemas in CDP portal.</span
      >
    </div>
    <div v-if="sampleData.length === 0" class="empty-sample-data">
      <XStatus type="empty">No items</XStatus>
    </div>
    <template v-for="(data, index) in sampleData">
      <SampleDataItem :code="data" :index="index + 1" />
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { XIcon, XIconButton, XStatus } from '@asus-aics/xui';
import SampleDataItem from './SampleDataItem.vue';
import { useSampleDataStore } from '@/store/views/MainPage/sampleData';
import { storeToRefs } from 'pinia';
export default defineComponent({
  name: 'SampleDataTab',
  components: { XIcon, XIconButton, SampleDataItem, XStatus },
  setup() {
    const sampleDataStore = useSampleDataStore();
    const { sampleData } = storeToRefs(sampleDataStore);
    return { sampleData };
  },
});
</script>

<style scoped lang="scss">
.sample-data-tab {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  overflow-y: auto;
  .info-text {
    display: flex;
    gap: 4px;

    span {
      font-size: var(--xv-text--body-md--font-size);
      font-weight: var(--xv-text--body-md--font-weight);
      line-height: var(--xv-text--body-md--line-height);
      color: var(--xv-text--medium-emphasis-text);
    }
  }
}

.empty-sample-data {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
