<template>
  <XDialogue id="single" :show="show" :width="1080" :height="756">
    <div class="dialogue-container">
      <div class="input-container">
        <XInputText
          id="resource-content-edit-modal-property-name"
          :required="isWip"
          :disabled="!isWip"
          label="Property Name"
          placeholder="Enter Property Name"
          :model-value="currentEditResourceContentItem.propertyName"
          @update:model-value="(value: string) => {(currentEditResourceContentItem.propertyName = value); validatePropertyName()}"
          :error="!!currentEditResourceContentItem.errorMessagePropertyName"
          :message="currentEditResourceContentItem.errorMessagePropertyName"
        ></XInputText>
        <XInputText
          label="Property Name(CN)"
          placeholder="Enter Property Name(CN)"
          :model-value="currentEditResourceContentItem.propertyNameCn"
          @update:model-value="(value: string) => {(currentEditResourceContentItem.propertyNameCn = value); validatePropertyNameCn()}"
          :error="!!currentEditResourceContentItem.errorMessagePropertyNameCn"
          :message="currentEditResourceContentItem.errorMessagePropertyNameCn"
        ></XInputText>
        <div v-if="isWip" class="input-with-top-label">
          <span class="x-input x-input-label">Parent</span>
          <XSelect
            class="x-input"
            max-width="2000px"
            menu-min-width="1000px"
            :data-options="parentOptions"
            v-model:model-value="currentEditResourceContentItem.parentName"
            :style="{
              borderRadius: 'var(--x-input-bd-radius)',
              border: 'var(--x-input-bd)',
              background: 'var(--x-input-bg)',
            }"
          ></XSelect>
        </div>
        <div class="input-row">
          <div class="input-with-top-label">
            <div class="label-with-required-mark">
              <span class="x-input x-input-label"
                >Data Type<span v-if="isWip" class="x-input-label-required-mark">*</span>
              </span>
            </div>
            <XSelect
              class="x-input"
              min-width="260px"
              max-width="260px"
              menu-min-width="260px"
              :disabled="!isWip"
              :data-options="dataTypeOptions"
              :error="!!currentEditResourceContentItem.errorMessageFieldDataType"
              :model-value="isArray ? 'Array' : currentEditResourceContentItem.fieldDataType"
              @update:model-value="(value: string) => {(currentEditResourceContentItem.fieldDataType = value); validateFieldDataType()}"
              :style="{
                borderRadius: 'var(--x-input-bd-radius)',
                border: !!currentEditResourceContentItem.errorMessageFieldDataType ? '' : 'var(--x-input-bd)',
                background: 'var(--x-input-bg)',
              }"
            ></XSelect>
          </div>
          <div v-if="isWip" class="input-with-top-label">
            <div class="label-with-required-mark">
              <span class="x-input x-input-label"
                >Element Data Type<span v-if="isElementDataTypeEnabled" class="x-input-label-required-mark">*</span>
              </span>
            </div>
            <XSelect
              class="x-input"
              min-width="260px"
              max-width="260px"
              menu-min-width="260px"
              :data-options="elementDataTypeOptions"
              fallback-label="Select Element Type"
              v-model:model-value="currentEditResourceContentItem.elementDataType"
              :disabled="!isElementDataTypeEnabled"
              :style="{
                borderRadius: 'var(--x-input-bd-radius)',
                border: 'var(--x-input-bd)',
                background: 'var(--x-input-bg)',
              }"
            ></XSelect>
          </div>
        </div>
        <div class="input-row">
          <div class="input-with-top-label">
            <div class="label-with-required-mark">
              <span class="x-input x-input-label"
                >Required<span v-if="isWip" class="x-input-label-required-mark">*</span>
              </span>
            </div>
            <XSelect
              class="x-input"
              min-width="260px"
              max-width="260px"
              menu-min-width="260px"
              :disabled="!isWip"
              :data-options="requiredOptions"
              :model-value="currentEditResourceContentItem?.required?.toString().toUpperCase()"
              @update:model-value="(value) => (currentEditResourceContentItem.required = value === 'TRUE')"
              :style="{
                borderRadius: 'var(--x-input-bd-radius)',
                border: 'var(--x-input-bd)',
                background: 'var(--x-input-bg)',
              }"
            ></XSelect>
          </div>
          <XInputText
            v-if="isWip"
            label="Default"
            style="width: 100%"
            placeholder="Enter Default"
            v-model:model-value="currentEditResourceContentItem.defaultValue"
          ></XInputText>
        </div>

        <div v-if="isWip" class="input-row">
          <XInputTextWithInfo
            style="width: 100%"
            label="Enum"
            placeholder="abc|def|ghi"
            content="A set of members for enumerations whose data type are specified in Data Type."
            :disabled="!isEnumEnabled"
            v-model:model-value="currentEditResourceContentItem.enumString"
            :error="!!currentEditResourceContentItem.errorEnumString"
            :message="currentEditResourceContentItem.errorEnumString"
          ></XInputTextWithInfo>
        </div>
        <div class="input-row">
          <div class="input-with-top-label" :style="{ width: '100%' }">
            <span class="x-input x-input-label">Source</span>
            <XTextAreaWithLineLimits
              :content="currentEditResourceContentItem.source"
              @update:content="(value: string) => {currentEditResourceContentItem.source = value; validateSource();}"
              placeholder="Enter Source"
              :line-limits="2000"
              :min-line="6"
              :error="!!currentEditResourceContentItem.errorSource"
              :message="currentEditResourceContentItem.errorSource"
            />
          </div>
        </div>
        <div class="input-row">
          <div class="input-with-top-label" :style="{ width: '100%' }">
            <span class="x-input x-input-label">Source Note</span>
            <XTextAreaWithLineLimits
              :content="currentEditResourceContentItem.sourceNote"
              @update:content="(value: string) => {currentEditResourceContentItem.sourceNote = value; validateSourceNote();}"
              placeholder="Enter Source Note"
              :line-limits="2000"
              :min-line="6"
              :error="!!currentEditResourceContentItem.errorSourceNote"
              :message="currentEditResourceContentItem.errorSourceNote"
            />
          </div>
        </div>
        <div class="input-row">
          <div class="input-with-top-label" :style="{ width: '100%' }">
            <span class="x-input x-input-label">Note</span>
            <XTextAreaWithLineLimits
              :content="currentEditResourceContentItem.note"
              @update:content="(value: string) => {currentEditResourceContentItem.note = value; validateNote();}"
              placeholder="Enter Note"
              :line-limits="2000"
              :min-line="6"
              :error="!!currentEditResourceContentItem.errorNote"
              :message="currentEditResourceContentItem.errorNote"
            />
          </div>
        </div>
        <div class="input-row">
          <div class="input-with-top-label" :style="{ width: '100%' }">
            <div class="label-with-tooltip">
              <span class="x-input x-input-label" :style="{ margin: '0' }">Tags</span>
              <XTooltip
                content="Reference sources. For example,
[FHIR]patient.gender and [NHI]糖尿病試辦計畫."
                :options="{
                  theme: 'blue-card',
                  arrow: false,
                }"
              >
                <XIcon icon="info-outline" color="var(--xv-text--disabled-text)"></XIcon>
              </XTooltip>
            </div>
            <XTextAreaWithLineLimits
              :content="currentEditResourceContentItem.tags"
              @update:content="(value: string) => {currentEditResourceContentItem.tags = value; validateTag();}"
              placeholder="Separate each tag with a new line"
              :line-limits="2000"
              :min-line="2"
              :error="!!currentEditResourceContentItem.errorTags"
              :message="currentEditResourceContentItem.errorTags"
            />
          </div>
        </div>
      </div>
      <div class="action-container">
        <div class="button-group">
          <XButton theme="tertiary" outline size="lg" min-width="128px" @click="$emit('on-modal-close')"
            >Cancel</XButton
          >
          <XButton size="lg" min-width="128px" @click="onModalSave">Save</XButton>
        </div>
      </div>
    </div>
  </XDialogue>
</template>
<script lang="ts">
import '@/assets/scss/scroll_bar.scss';
import { XButton, XDialogue, XTooltip, XIcon, XInputText, XSelect, XTextArea, SelectMenuItem } from '@asus-aics/xui';
import XInputTextWithInfo from '@/components/xui_custom/XInputTextWithInfo.vue';
import XTextAreaWithLineLimits from './XTextAreaWithLineLimits.vue';
import { computed, defineComponent, ref } from 'vue';
import { useConstantDataStore } from '@/store/constantData';
import { storeToRefs } from 'pinia';
import { useResourceContentPageDataStore } from '@/store/resourceContentPageData';
export default defineComponent({
  name: 'ResourceContentEditModal',
  components: {
    XButton,
    XDialogue,
    XTooltip,
    XIcon,
    XInputText,
    XSelect,
    XTextArea,
    XInputTextWithInfo,
    XTextAreaWithLineLimits,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    isWip: {
      type: Boolean,
      default: false,
    },
  },
  // emits: ['on-modal-close', 'on-dont-save', 'on-delete', 'on-modal-save'],
  emits: {
    'on-modal-close': () => true,
    'on-modal-save': () => true,
  },
  setup(_, { emit }) {
    const source = ref('');

    const constantDataStore = useConstantDataStore();
    const resourceContentDataStore = useResourceContentPageDataStore();
    const { constantData } = storeToRefs(constantDataStore);
    const { currentEditResourceContentItem, parentOptions: _parentOptions } = storeToRefs(resourceContentDataStore);

    const {
      validatePropertyName,
      validatePropertyNameCn,
      validateNote,
      validateSource,
      validateSourceNote,
      validateTag,
      validateFieldDataType,
    } = resourceContentDataStore;

    const arrayToSelectOptions = (array: string[]): SelectMenuItem[] => {
      if (!array) {
        return [];
      }
      return array.map((item) => {
        return {
          label: item,
          value: item,
        };
      });
    };
    const dataTypeOptions = computed(() => {
      if (currentEditResourceContentItem.value.children?.length > 0) return arrayToSelectOptions(['Array', 'Object']);
      return arrayToSelectOptions(constantData.value.dataTypes);
    });

    const elementDataTypeOptions = computed(() => {
      if (currentEditResourceContentItem.value.children?.length > 0) return arrayToSelectOptions(['Object']);
      return arrayToSelectOptions(constantData.value.elementDataTypes);
    });

    const parentOptions = computed(() => arrayToSelectOptions(_parentOptions.value));
    const requiredOptions = computed(() => arrayToSelectOptions(['TRUE', 'FALSE']));

    const isElementDataTypeEnabled = computed(
      () =>
        currentEditResourceContentItem.value.fieldDataType?.startsWith('Array') ||
        ['Array'].includes(currentEditResourceContentItem.value.fieldDataType)
    );
    const isEnumEnabled = computed(
      () =>
        ['String', 'Number', 'Long'].includes(currentEditResourceContentItem.value.fieldDataType) ||
        (['String', 'Number', 'Long'].includes(currentEditResourceContentItem.value.elementDataType) &&
          isElementDataTypeEnabled)
    );
    const isArray = computed(() => currentEditResourceContentItem.value?.fieldDataType?.startsWith('Array') ?? false);

    const onModalSave = () => {
      emit('on-modal-save');
    };
    return {
      source,
      dataTypeOptions,
      elementDataTypeOptions,
      currentEditResourceContentItem,
      parentOptions,
      requiredOptions,
      onModalSave,
      isElementDataTypeEnabled,
      isEnumEnabled,
      isArray,
      validatePropertyName,
      validatePropertyNameCn,
      validateNote,
      validateSource,
      validateSourceNote,
      validateTag,
      validateFieldDataType,
    };
  },
});
</script>
<style scoped lang="scss">
.dialogue-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 32px;
  gap: 0;
}

.action-container {
  display: flex;
  justify-content: flex-end;
  padding: 24px 0px 32px 32px;
}

.button-group {
  display: flex;
  gap: 16px;
}

.input {
  &-container {
    display: flex;
    flex-direction: column;
    height: 644px;
    padding: 36px 0;

    gap: 8px;
    overflow-y: auto;
  }
  &-with-top-label {
    display: flex;
    flex-direction: column;

    &:deep(textarea) {
      font-size: 18px;
    }
  }
  &-row {
    display: flex;
    flex-direction: row;
    gap: 24px;
  }
}

.label-with-required-mark {
  display: flex;
}

.label-with-tooltip {
  display: flex;
  align-items: center;
  gap: 2px;
}
.medium-emphasis-text {
  color: var(--xv-text--medium-emphasis-text);
}
</style>
