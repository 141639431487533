<template>
  <div class="main-page">
    <Navbar
      :click-self="openCollectionList"
      :is-editing="isEditing || isCodeGenerateTabEditing"
      initialTabName="Collection List"
    />
    <div class="main-page-body-container">
      <div class="main-page-body">
        <div class="main-page-sidebar">
          <div v-if="isEditing || isCodeGenerateTabEditing" class="sidebar-mask"></div>
          <div class="action-bar">
            <XInputText
              type="search"
              placeholder="Search"
              fill
              :model-value="searchInput"
              @update:model-value="onUpdateSearchInput"
              @input-focus="onFocusSearchInput"
            />
          </div>
          <div class="drawer-wrapper">
            <CollectionDrawer
              style="padding: 0 0 0 8px"
              :active-key="collectionDrawerActiveKey"
              @load-page="whenLoadPage"
              @load-page-code-generate="whenLoadPage"
            />
          </div>
        </div>
        <CodeGenerateTab
          v-if="isCodeGenerateTabOpen"
          :is-editing="isCodeGenerateTabEditing"
          :wip-id="wipId"
          :tab-name="currentTab"
          @cancel="closeCodeGenerateTab"
          @cancel-editing="cancelEditCodeGenerateTab"
          @full-page-loading="showLoading(false)"
          @set-is-editing="setIsCodeGenerateTabEditing"
          @save-completed="whenCodeGenerateSaveCompleted"
          @on-generate-code-click="onCodeGenerateClick"
          @on-save-click="onCodeGenerateSaveClick"
          @on-delete-click="onShowConfirmDeleteCollectionModalClick"
          @on-show-delete-index-modal="(index) => onShowDeleteIndex(index)"
          @on-show-delete-field-modal="(index, fieldIndex) => onShowDeleteField(index, fieldIndex)"
          @edit-resource-content-item="(item) => onEditResourceContentItem(item)"
          @on-new-property-click="onNewResourceContentItem"
          @copy-resource-content-item="(item) => onCopyResourceContentItem(item)"
          @on-tab-change="(tabName, shouldFetchData, wipId) => switchTab(tabName, shouldFetchData, wipId)"
          @on-copy-click="onCopyDataClick"
        />
        <div v-else-if="isSearchResultListOpen" class="search-result-list-container">
          <SearchResultList :is-searching="isSearching" @close-list="closeSearchResultList" @load-page="whenLoadPage" />
        </div>
        <div v-else-if="!isCollectionListOpen" class="detailed-page-container">
          <div class="detailed-page-body">
            <div class="body-wrapper">
              <div class="subtitle">
                <div v-if="isEditing && currentTab === 'content'" class="editing-subtitle-wrapper">
                  <span class="high-emphasis-text xv-text--title-sm">
                    {{ contents.collectionName }}
                  </span>
                  <XInputText
                    v-model="editingContents.collectionNameCn"
                    placeholder="Collection Name (CN)"
                    type="search"
                    size="sm"
                    style="width: 320px"
                  >
                    <template #prefix><span></span></template>
                  </XInputText>
                </div>
                <span v-else class="high-emphasis-text xv-text--title-sm">
                  {{ contents.collectionName + ' (' + contents.collectionNameCn + ')' }}
                </span>
                <div class="subtitle-control-panel">
                  <template v-if="!isEditing">
                    <span class="medium-emphasis-text">{{ updatedDate }}</span>
                    <XButton
                      v-if="user.isAdmin"
                      size="sm"
                      theme="tertiary"
                      outline
                      @click="() => createWipCollection(true)"
                      >Copy To Work In Progress</XButton
                    >
                  </template>
                  <template v-else>
                    <template v-if="isUploading">
                      <div class="loading-wrapper">
                        <LoadingIcon />
                        <span class="medium-emphasis-text">1 image uploading</span>
                      </div>
                    </template>
                    <template v-if="currentTab === 'content'">
                      <XButton size="sm" theme="tertiary" outline @click="onShowUnsavedChangesModalClick"
                        >Cancel</XButton
                      >
                      <XButton size="sm" theme="primary" @click="saveAction">Save</XButton>
                    </template>
                  </template>
                </div>
              </div>
              <div class="button-wrapper">
                <div class="slide-tabs">
                  <TabSwitch
                    :item-options="itemOptions"
                    :item-value="currentTab"
                    @update:item-value="(item) => switchTab(item)"
                  ></TabSwitch>
                  <XSelect
                    v-if="isEditing && currentTab === 'content'"
                    :data-options="categoryOptions"
                    :modelValue="categoryOptionValue"
                    @update:modelValue="(value: string) => (categoryOptionValue = value)"
                  ></XSelect>
                </div>
                <XButton
                  v-if="user.isAdmin && !isEditing && currentTab === 'content'"
                  size="sm"
                  theme="primary"
                  icon="edit"
                  outline
                  @click="setIsEditing(true)"
                  >Edit Content</XButton
                >
                <XButton
                  v-if="user.isAdmin && currentTab === 'sourceImage' && !isEditing"
                  @click="openImageUploadDialog"
                  >Upload Image</XButton
                >
              </div>
              <component
                :is="tabComponents[currentTab]"
                class="tab-component"
                v-bind="tabProps[currentTab]"
                @delete-picture="onDeleteImageClick"
                @edit-resource-content-item="onEditResourceContentItem"
                @copy-resource-content-item="(item) => onCopyResourceContentItem(item)"
                @edit-picture="setIsEditing(true)"
                @cancel-edit-picture="setIsEditing(false)"
                @save-edit-picture="(index) => saveEditPicture(index)"
              ></component>
            </div>
          </div>
        </div>
        <div v-else class="collection-list-container">
          <CollectionList @onRowClick="whenLoadPage" @new-collection-click="() => createWipCollection()" />
        </div>
      </div>
    </div>
  </div>
  <CannotEditWarningModal :show="showCannotEditWarningModal" @on-modal-close="onCloseCannotEditWarningModal" />
  <UnsavedChangesModal
    :show="showUnsavedChangesModal"
    @on-modal-close="onCloseUnsavedChangesModal"
    @on-dont-save="onDontSaveChangesModal"
    @on-save="onSaveChangesModal"
  />
  <UnsavedChangesModal
    :show="showCodeGenerateTabUnsavedChangeModal"
    @on-modal-close="onCloseCodeGenerateTabUnsavedChangeModal"
    @on-dont-save="onDontSaveCodeGenerateTabUnsavedChangeModal"
    @on-save="
      onSaveCodeGenerateTabUnsavedChangeModal(
        wipId,
        editingContents.wipName,
        editingContents.collectionName,
        editingContents.collectionNameCn
      )
    "
  />
  <ConfirmDeleteImageModal
    :show="showConfirmDeleteImageModal"
    @on-delete="onDeleteConfirmDeleteImageModalClick"
    @on-modal-close="onCloseConfirmDeleteImageModal"
  />
  <ConfirmDeleteCollectionModal
    :show="showConfirmDeleteCollectionModal"
    :collection-name="contents.collectionName"
    :collection-name-cn="contents.collectionNameCn"
    @on-delete="onDeleteCollectionModalClick(wipId, openPage)"
    @on-modal-close="onCloseCollectionModal"
  />
  <SchemaCodeModal :show="showSchemaCodeModal" @on-modal-close="onCloseShowSchemaCodeModal" />
  <TwoButtonWarningModal
    :show="showTwoButtonWarningModal"
    :title="twoButtonWarningModalOptions.title"
    :description="twoButtonWarningModalOptions.description"
    :primary-button-text="twoButtonWarningModalOptions.primaryButtonText"
    :secondary-button-text="twoButtonWarningModalOptions.secondaryButtonText"
    :primary-action="twoButtonWarningModalOptions.primaryAction"
    @on-cancel-click="onCloseTwoButtonWarningModal"
  />
  <ResourceContentEditModal
    :show="showResourceContentEditModal"
    :is-wip="wipId !== ''"
    @on-modal-close="showResourceContentEditModal = false"
    @on-modal-save="onSaveResourceContentItem"
  />
  <FullResourceContentModal />
  <input ref="inputElement" type="file" accept="image/*" style="display: none" :onchange="handleImageUpload" />
  <div v-if="isUploadDialogOpened" class="full-page-mask"></div>
</template>

<script lang="ts">
import '@/assets/scss/scroll_bar.scss';
import { ref, defineComponent, computed, watch, ComputedRef, onMounted } from 'vue';
import {
  XNavTab,
  XTag,
  XIcon,
  XIconButton,
  XButton,
  XInputText,
  XChipGroup,
  XSlideTabs,
  XSelect,
  SelectMenuItem,
  XNotificationType,
} from '@asus-aics/xui';

import { getUserInfoAndUpdateStore, logoutByAzure } from '@/api/authApi';

import CollectionDrawer from '../components/CollectionDrawer.vue';
import ContentTab from '../components/ContentTab.vue';
import ResourceContentTab from '../components/ResourceContentTab.vue';
import SourceImageTab from '@/components/SourceImageTab.vue';
import SampleDataTab from '@/components/views/MainPage/SampleDataTab.vue';
import FilterSection from '../components/FilterSection.vue';
import CannotEditWarningModal from '../components/CannotEditWarningModal.vue';
import UnsavedChangesModal from '../components/UnsavedChangesModal.vue';
import ConfirmDeleteImageModal from '../components/ConfirmDeleteImageModal.vue';
import TwoButtonWarningModal from '@/components/TwoButtonWarningModal.vue';
import ConfirmDeleteCollectionModal from '../components/ConfirmDeleteCollectionModal.vue';
import SchemaCodeModal from '@/components/SchemaCodeModal.vue';
import ResourceContentEditModal from '@/components/ResourceContentEditModal.vue';
import CollectionList from '../components/CollectionList.vue';
import SearchResultList from '@/components/SearchResultList.vue';
import CodeGenerateTab from '@/components/CodeGenerateTab.vue';
import LoadingIcon from '@/components/LoadingIcon.vue';
import { useCollectionDrawerData } from '@/store/collectionDrawerData';
import { useContentPageDataStore } from '@/store/contentPageData';
import { storeToRefs } from 'pinia';
import {
  getCategoriesAndUpdateStore,
  getCollectionsAndUpdateStore,
  getContentByCollectionNameAndUpdateStore,
  getResourceContentByCollectionNameAndUpdateStore,
  saveContentByCollectionNameAndUpdateStore,
  saveResourceContentByFieldDataAndUpdateStore,
  searchContentByKeywordAndUpdateStore,
  getImagesByCollectionNameAndUpdateStore,
  saveImageAndUpdateStore,
  createWipCollectionAndUpdateStore,
  getContentByWipIdAndUpdateStore,
  getResourceContentByWipIdAndUpdateStore,
  patchWipSchemaAndUpdateStore,
  getGenerateCodeAndUpdateStore,
  getConstantDataAndUpdateStore,
  editWipResourceContentAndUpdateStore,
  saveWipResourceContentItemAndUpdateStore,
  copyDataFromWipToCollectionAndGoToCollection,
  getHospitalListAndUpdateStore,
  clearCategoriesAndUpdateStore,
  clearCollectionsAndUpdateStore,
  getSampleDataAndUpdateStore,
} from '@/utils/dataService';
import {
  useResourceContentPageDataStore,
  createResourceContentWithMaxLineHeight,
  ResourceContent,
} from '@/store/resourceContentPageData';
import { cloneDeep, debounce } from 'lodash';
import { useSourceImageDataStore } from '@/store/sourceImageData';
import { handleImageUpload as _handleImageUpload } from '@/utils/imageUtils';
import { useInfoDataStore } from '@/store/infoData';
import { formatDate } from '@asus-aics/x-fe-utils';
import {
  useCannotEditWarningModal,
  useConfirmDeleteImageModal,
  useSchemaCodeModal,
  useUnsavedChangesModal,
  useCodeGenerateTabUnsavedChangesModal,
  useConfirmDeleteCollectionModal,
  useResourceContentEditModal,
} from '@/composables/useModal';
import { useNotification } from '@/composables/useNotification';
import { useLoadingStore } from '@/store/shared/useLoadingStore';
import { useMainPageState } from '@/composables/views/MainPage/useMainPageState';
import TabSwitch from '@/components/TabSwitch.vue';
import { useModalState } from '@/store/modalState';
import FullResourceContentModal from '@/components/modals/FullResourceContentModal.vue';
import Navbar from '@/components/shared/Navbar.vue';
import { GENERIC_ERROR_MESSAGE } from '@/utils/shared/constants';
import { setApiVersion } from '@/api/dataApi';

export default defineComponent({
  name: 'MainPage',
  components: {
    XNavTab,
    XTag,
    XIcon,
    XIconButton,
    XButton,
    XInputText,
    XChipGroup,
    CollectionDrawer,
    XSlideTabs,
    FilterSection,
    XSelect,
    CannotEditWarningModal,
    UnsavedChangesModal,
    ConfirmDeleteImageModal,
    ConfirmDeleteCollectionModal,
    TwoButtonWarningModal,
    CollectionList,
    SearchResultList,
    CodeGenerateTab,
    SchemaCodeModal,
    LoadingIcon,
    TabSwitch,
    ResourceContentEditModal,
    FullResourceContentModal,
    Navbar,
  },
  setup() {
    const isFirstLoad = ref(true);
    const useLoading = useLoadingStore();
    const { isEditing, isCodeGenerateTabEditing, setIsEditing, setIsCodeGenerateTabEditing } = useMainPageState();
    const { hideLoading, showLoading } = useLoading;
    const { showNotification } = useNotification();
    // #region store
    const contentStore = useContentPageDataStore();
    const resourceContentStore = useResourceContentPageDataStore();
    const collectionDrawerDataStore = useCollectionDrawerData();
    const sourceImageDataStore = useSourceImageDataStore();
    const infoDataStore = useInfoDataStore();

    const { contents, editingContents, wipId } = storeToRefs(contentStore);
    const { currentSelectedCategory, categories } = storeToRefs(collectionDrawerDataStore);
    const { resourceContent, editingResourceContent } = storeToRefs(resourceContentStore);
    const { pictureList } = storeToRefs(sourceImageDataStore);
    const { user, currentHospital, previousHospital } = storeToRefs(infoDataStore);

    const { clearEditingResourceContentPageData, validateCurrentEditResourceContentItem } = resourceContentStore;
    const { isEditingContentValid, isEditingContentChanged } = contentStore;
    const { restorePreviousHospital } = infoDataStore;
    // #endregion store

    const collectionDrawerActiveKey = ref('0');
    const setCollectionDrawerActiveKey = (key: string) => {
      collectionDrawerActiveKey.value = key;
    };
    const originUpdatedDate = ref<string>('');
    const updatedDate = computed(() => {
      if (originUpdatedDate.value !== '') {
        return 'Last Updated ' + formatDate(originUpdatedDate.value);
      }
      return '';
    });
    //#region slide-tabs
    const currentTab = ref('content');
    const itemOptions = computed(() => [
      {
        label: 'Content',
        value: 'content',
        disabled: isEditing.value && currentTab.value !== 'content',
      },
      {
        label: 'Resource Content',
        value: 'resourceContent',
        disabled: isEditing.value && currentTab.value !== 'resourceContent',
      },
      {
        label: 'Source (Image)',
        value: 'sourceImage',
        disabled: isEditing.value && currentTab.value !== 'sourceImage',
      },
      {
        label: 'Sample Data',
        value: 'sampleData',
        disabled: isEditing.value && currentTab.value !== 'sampleData',
      },
    ]);

    const switchTab = (value: string, shouldFetchData: boolean = true, wipId: string = '') => {
      currentTab.value = value;
      if (shouldFetchData)
        whenLoadPage(
          currentSelectedCategory.value,
          contents.value.collectionName,
          contents.value.collectionNameCn,
          value,
          wipId
        );
    };
    const tabComponents = {
      content: ContentTab,
      resourceContent: ResourceContentTab,
      sourceImage: SourceImageTab,
      sampleData: SampleDataTab,
    };
    const tabProps = computed(() => {
      return {
        content: {
          globalIsEditing: isEditing.value,
          isWip: wipId.value !== '',
        },
        resourceContent: {
          globalIsEditing: isEditing.value,
        },
        sourceImage: {
          isEditing: isEditing.value,
        },
      };
    });
    //#endregion slide-tabs

    // #region categoryOptions
    const categoryOptions: ComputedRef<SelectMenuItem[]> = computed(() => {
      const options = [];
      for (const category of categories.value) {
        options.push({
          label: category.category_name,
          value: category.category_name,
        });
      }
      return options;
    });
    const categoryOptionValue = ref<string>(currentSelectedCategory.value);
    // #endregion categoryOptions

    // #region save changes
    const saveContent = async () => {
      const response = await saveContentByCollectionNameAndUpdateStore(
        categoryOptionValue.value,
        contents.value.collectionName,
        editingContents.value.collectionNameCn,
        editingContents.value.scopeAndUsage,
        editingContents.value.backgroundAndContext,
        editingContents.value.serviceToThisResource
      );
      return response;
    };

    const saveEditPicture = async (_) => {
      showLoading();
      const response = await saveImageAndUpdateStore(contents.value.collectionName);
      if (response.status === 'success') {
        showNotification(XNotificationType.Success, 'Saved');
      } else {
        showNotification(XNotificationType.Error, 'Error');
      }
      setIsEditing(false);
      hideLoading();
    };

    const saveAction = async () => {
      showLoading();
      const res = await saveContent();

      let isSuccess = true;
      if (!(res.status === 'success' || res.status === 'success_no_change')) {
        isSuccess = false;
      }

      if (isSuccess) {
        showNotification(XNotificationType.Success, 'Saved');
      } else {
        showNotification(XNotificationType.Error, 'Please correct all errors before saving');
      }
      setIsEditing(false);
      hideLoading();
    };

    const onSaveResourceContentItem = async () => {
      if (!validateCurrentEditResourceContentItem()) {
        showNotification(XNotificationType.Error, 'Please correct all errors and try saving again');
        return;
      }
      showLoading();
      let response;
      if (wipId.value === '') {
        response = await saveResourceContentByFieldDataAndUpdateStore(contents.value.collectionName);
      } else {
        response = await saveWipResourceContentItemAndUpdateStore(wipId.value);
      }
      if (response.status === 'success') {
        showNotification(XNotificationType.Success, 'Saved');
        showResourceContentEditModal.value = false;
      } else {
        for (const key in response.data) {
          for (const message of response.data[key]) {
            showNotification(XNotificationType.Error, message);
          }
        }
      }
      hideLoading();
    };
    // #endregion save changes

    // #region code generate
    const isCodeGenerateTabOpen = ref(false);
    const createWipCollection = async (isCopy = false) => {
      showLoading(false);
      const createParams = {
        collectionName: isCopy ? contents.value.collectionName : null,
      };
      const response = await createWipCollectionAndUpdateStore(createParams.collectionName);
      const collection = collectionDrawerDataStore.getChildByWipId(response.wip_id);
      hideLoading();
      await whenLoadPage(
        collection.category,
        collection.children[0].title,
        collection.children[0].titleCn,
        'content',
        response.wip_id
      );
    };

    const closeCodeGenerateTab = () => {
      isCodeGenerateTabOpen.value = false;
      setIsCodeGenerateTabEditing(false);
    };
    const cancelEditCodeGenerateTab = () => {
      if (isEditingContentChanged()) {
        showCodeGenerateTabUnsavedChangeModal.value = true;
        const pendingHospital = currentHospital.value;
        codeGenerateTabAddPendingFunction(() => (currentHospital.value = pendingHospital));
        restorePreviousHospital();
        return true;
      }
      onDontSaveCodeGenerateTabUnsavedChangeModal();
      return false;
    };

    const onCodeGenerateSaveClick = async (wipId, wipName, newName, newNameCh) => {
      if (!isEditingContentValid()) {
        showNotification(XNotificationType.Error, 'Please fix all errors before saving');
        return;
      }
      try {
        showLoading(false);
        await patchWipSchemaAndUpdateStore(wipId, wipName, newName, newNameCh);
        await whenCodeGenerateSaveCompleted(wipId, newName, newNameCh);
      } catch (error) {
        console.error(error);
        const response = error.response;
        for (const key in response.data) {
          showNotification(XNotificationType.Error, response.data[key]);
        }
        hideLoading();
      }
    };
    const whenCodeGenerateSaveCompleted = async (wipId, newName, newNameCh) => {
      await whenLoadPage(currentSelectedCategory.value, newName, newNameCh, 'content', wipId);
      setIsCodeGenerateTabEditing(false);
      hideLoading();
      showNotification(XNotificationType.Success, 'Saved');
    };

    const onCodeGenerateClick = async () => {
      showLoading(false);
      await getGenerateCodeAndUpdateStore(wipId.value);
      hideLoading();
      showSchemaCodeModal.value = true;
    };

    const onCopyDataClick = async () => {
      showLoading(false, 'Copying to collection');
      const response = await copyDataFromWipToCollectionAndGoToCollection(wipId.value);
      if ('collectionName' in response) {
        const { collectionName } = response;
        showNotification(XNotificationType.Success, 'Collection has been updated');
        const collection = collectionDrawerDataStore.getChildByCollectionName(collectionName);
        if (collection === null) showNotification(XNotificationType.Error, 'Collection not found');
        else whenLoadPage(collection.category, collection.children[0].title, collection.children[0].titleCn, 'content');
      } else {
        showNotification(XNotificationType.Error, response.errorMessage);
      }
      hideLoading();
    };
    // #endregion code generate

    // #region modal
    const { showCannotEditWarningModal, onCloseCannotEditWarningModal } = useCannotEditWarningModal();
    const {
      showUnsavedChangesModal,
      onCloseUnsavedChangesModal,
      onDontSaveChangesModal,
      onSaveChangesModal,
      addPendingFunction,
    } = useUnsavedChangesModal(setIsEditing, saveAction);

    const onShowUnsavedChangesModalClick = () => {
      if (isEditingContentChanged()) {
        showUnsavedChangesModal.value = true;
        const pendingHospital = currentHospital.value;
        addPendingFunction(() => (currentHospital.value = pendingHospital));
        restorePreviousHospital();
        return true;
      }
      onDontSaveChangesModal();
      return false;
    };
    const {
      showConfirmDeleteImageModal,
      onDeleteImageClick,
      onCloseConfirmDeleteImageModal,
      onDeleteConfirmDeleteImageModal,
    } = useConfirmDeleteImageModal();

    const onDeleteConfirmDeleteImageModalClick = async () => {
      showLoading();
      const responses = await onDeleteConfirmDeleteImageModal(contents.value.collectionName);
      if (responses.every((response) => response.status === 'success')) {
        showNotification(XNotificationType.Success, 'Deleted');
      } else {
        showNotification(XNotificationType.Error, 'Error');
      }
      hideLoading();
    };
    const { showSchemaCodeModal, onCloseShowSchemaCodeModal } = useSchemaCodeModal();
    const {
      showCodeGenerateTabUnsavedChangeModal,
      onCloseCodeGenerateTabUnsavedChangeModal,
      onDontSaveCodeGenerateTabUnsavedChangeModal,
      onSaveCodeGenerateTabUnsavedChangeModal,
      addPendingFunction: codeGenerateTabAddPendingFunction,
    } = useCodeGenerateTabUnsavedChangesModal(setIsCodeGenerateTabEditing, onCodeGenerateSaveClick);

    const {
      showConfirmDeleteCollectionModal,
      onShowConfirmDeleteCollectionModalClick,
      onDeleteCollectionModalClick,
      onCloseCollectionModal,
    } = useConfirmDeleteCollectionModal();

    const {
      showResourceContentEditModal,
      onNewResourceContentEditModal,
      onOpenResourceContentEditModal,
      onCopyResourceContentEditModal,
      onCloseResourceContentEditModal,
    } = useResourceContentEditModal();

    const modalState = useModalState();
    const { showTwoButtonWarningModal, twoButtonWarningModalOptions, showFullResourceContentModal } =
      storeToRefs(modalState);
    const { onShowTwoButtonWarningModalClick, onCloseTwoButtonWarningModal } = modalState;
    const { deleteIndex, deleteField } = contentStore;
    const onShowDeleteIndex = (index: number) => {
      onShowTwoButtonWarningModalClick({
        title: 'Are you sure you want to delete this index?',
        description: 'This index and all of its settings will be deleted',
        primaryButtonText: 'Delete',
        secondaryButtonText: 'Cancel',
        primaryAction: () => deleteIndex(index),
      });
    };
    const onShowDeleteField = (index: number, fieldIndex: number) => {
      onShowTwoButtonWarningModalClick({
        title: 'Are you sure you want to delete this field?',
        description: '',
        primaryButtonText: 'Delete',
        secondaryButtonText: 'Cancel',
        primaryAction: () => deleteField(index, fieldIndex),
      });
    };

    // #endregion modal

    const previousLoadTitle = ref('');

    const updateCollectionStoreBasedOnContentTabType = async (
      _contentTabType: string,
      _titleInCollectionList: string,
      _searchRank: number | null,
      searchKeyword: string | null
    ) => {
      switch (_contentTabType) {
        case 'content':
          await getContentByCollectionNameAndUpdateStore(_titleInCollectionList, _searchRank, searchKeyword);
          break;
        case 'resourceContent':
          await getResourceContentByCollectionNameAndUpdateStore(_titleInCollectionList, _searchRank, searchKeyword);
          break;
        case 'sourceImage':
          await getImagesByCollectionNameAndUpdateStore(_titleInCollectionList);
          break;
        case 'sampleData':
          await getSampleDataAndUpdateStore(_titleInCollectionList);
      }
    };

    const updateWipStoreBasedOnContentTabType = async (
      _contentTabType: string,
      _wipId: string,
      _searchRank: number | null,
      searchKeyword: string | null
    ) => {
      switch (_contentTabType) {
        case 'content':
          await getContentByWipIdAndUpdateStore(_wipId, _searchRank, searchKeyword);
          break;
        case 'resourceContent':
          await getResourceContentByWipIdAndUpdateStore(_wipId, _searchRank, searchKeyword);
      }
    };

    const whenLoadPage = async (
      _category: string,
      _titleInCollectionList: string,
      _titleCn: string,
      _contentTabType = 'content',
      _wipId = '',
      _updatedAt = '',
      _searchRank = null
    ) => {
      currentTab.value = _contentTabType;
      wipId.value = _wipId;
      collectionDrawerDataStore.setCurrentSelectedCategory(_category);
      showLoading();
      try {
        let activeKeyTitle = '';
        const searchKeyword = _searchRank ? searchInput.value : null;
        if (_wipId === '') {
          openPage('detailedPage');
          if (previousLoadTitle.value !== _titleInCollectionList) {
            originUpdatedDate.value = _updatedAt;
          }
          // from search may go to resourceContent tab, so need to fetch content to get collectionName/Ch, and other needed data
          if (_searchRank !== null && _contentTabType !== 'content') {
            await getContentByCollectionNameAndUpdateStore(_titleInCollectionList);
          }

          await updateCollectionStoreBasedOnContentTabType(
            _contentTabType,
            _titleInCollectionList,
            _searchRank,
            searchKeyword
          );

          activeKeyTitle = _titleInCollectionList;
        } else {
          openPage('codeGeneratePage');
          // from search may go to resourceContent tab, so need to fetch content to get collectionName/Ch, and other needed data
          if (_searchRank !== null && _contentTabType !== 'content') {
            await getContentByWipIdAndUpdateStore(_wipId);
          }
          await updateWipStoreBasedOnContentTabType(_contentTabType, _wipId, _searchRank, searchKeyword);
          activeKeyTitle = contents.value.wipName;
        }
        setCollectionDrawerActiveKey(`${_category}/${activeKeyTitle}`);
        previousLoadTitle.value = _titleInCollectionList;
      } catch (error) {
        showNotification(XNotificationType.Error, GENERIC_ERROR_MESSAGE);
      }
      hideLoading();
    };

    // #region collection list
    const isCollectionListOpen = ref(true);
    // #endregion collection list

    // #region search input
    const searchInput = ref('');
    const isSearching = ref(false);
    const performSearch = async (value) => {
      await searchContentByKeywordAndUpdateStore(value);
      isSearching.value = false;
    };
    const debounceSearch = debounce(performSearch, 500);

    const onFocusSearchInput = () => {
      if (searchInput.value !== '') {
        openSearchResultList();
      }
    };
    const onUpdateSearchInput = (value: string) => {
      searchInput.value = value;
      if (value === '') {
        performSearch('');
        openPage(previousPage.value);
        return;
      }
      openSearchResultList();
      isSearching.value = true;
      debounceSearch(value);
    };

    // #endregion search input

    // #region search result list
    const isSearchResultListOpen = ref(false);

    const openSearchResultList = () => {
      openPage('searchResultList');
    };

    const closeSearchResultList = () => {
      openPage(previousPage.value);
    };
    // #endregion search result list

    // #region image upload
    const inputElement = ref(null);
    const isUploading = ref(false);

    const isUploadDialogOpened = ref(false);
    const handleImageUpload = async (event) => {
      isUploading.value = true;
      const result = await _handleImageUpload(event, contents.value.collectionName);
      isUploading.value = false;

      if (result.state === 'success') {
        showNotification(XNotificationType.Success, 'Successfully uploaded');
      } else {
        console.error('error');
      }
    };

    const checkIfUploadDialogClosed = () => {
      isUploadDialogOpened.value = false;
      document.body.onfocus = null;
    };
    const openImageUploadDialog = () => {
      const input = inputElement.value as HTMLInputElement;
      input.value = null;
      input.click();
      isUploadDialogOpened.value = true;
      document.body.onfocus = checkIfUploadDialogClosed;
    };
    // #endregion image upload

    watch(
      () => isEditing.value,
      (newBool, _) => {
        if (newBool) {
          categoryOptionValue.value = currentSelectedCategory.value;
          contentStore.setEditingContentPageData(cloneDeep(contents.value));
          editingResourceContent.value = createResourceContentWithMaxLineHeight(resourceContent.value);
          sourceImageDataStore.setEditingPictureList(cloneDeep(pictureList.value));
        } else {
          contentStore.clearEditingContentPageData();
          clearEditingResourceContentPageData();
          sourceImageDataStore.clearEditingPictureList();
        }
      }
    );

    const openCollectionList = () => {
      let isModalShown = false;
      if (isEditing.value) {
        const isUnsavedModalShow = onShowUnsavedChangesModalClick();
        isModalShown = isUnsavedModalShow || isModalShown;
      } else if (isCodeGenerateTabEditing.value) {
        const isUnsavedModalShow = cancelEditCodeGenerateTab();
        isModalShown = isUnsavedModalShow || isModalShown;
      }
      if (isModalShown) return false;
      openPage('collectionList');
      return true;
    };
    const onClickLogoutByAzure = async () => {
      await logoutByAzure();
    };

    // eslint-disable-next-line @typescript-eslint/naming-convention
    type pageType = 'collectionList' | 'searchResultList' | 'detailedPage' | 'codeGeneratePage';
    const previousPage = ref<pageType>('collectionList');
    const currentPage = computed(() => {
      if (isCollectionListOpen.value) {
        return 'collectionList';
      } else if (isSearchResultListOpen.value) {
        return 'searchResultList';
      } else if (isCodeGenerateTabOpen.value) {
        return 'codeGeneratePage';
      } else {
        return 'detailedPage';
      }
    });
    const openPage = (page: pageType) => {
      isCollectionListOpen.value = false;
      isCodeGenerateTabOpen.value = false;
      isSearchResultListOpen.value = false;
      if (page === 'collectionList') {
        isCollectionListOpen.value = true;
      } else if (page === 'searchResultList') {
        isSearchResultListOpen.value = true;
      } else if (page === 'codeGeneratePage') {
        isCodeGenerateTabOpen.value = true;
      }
    };
    const onEditResourceContentItem = (item: ResourceContent) => {
      if (wipId.value !== '') {
        editWipResourceContentAndUpdateStore(wipId.value, item.id);
      }
      onOpenResourceContentEditModal(item);
    };

    const onNewResourceContentItem = () => {
      editWipResourceContentAndUpdateStore(wipId.value);
      onNewResourceContentEditModal();
    };

    const onCopyResourceContentItem = (item: ResourceContent) => {
      editWipResourceContentAndUpdateStore(wipId.value);
      onCopyResourceContentEditModal(item);
    };

    watch(
      () => currentPage.value,
      (_, oldPage) => {
        previousPage.value = oldPage;
      }
    );

    watch(
      () => currentHospital.value,
      async () => {
        if (previousHospital.value === currentHospital.value) return;
        showLoading(false);
        if (!openCollectionList()) {
          hideLoading();
          return;
        }
        searchInput.value = '';
        setCollectionDrawerActiveKey('0');
        clearCategoriesAndUpdateStore();
        clearCollectionsAndUpdateStore();
        await getConstantDataAndUpdateStore();
        await getCategoriesAndUpdateStore();
        await getCollectionsAndUpdateStore();
        hideLoading();

        if (isFirstLoad.value) {
          isFirstLoad.value = false;
        } else {
          showNotification(XNotificationType.Success, `You are now logged in to: ${currentHospital.value}`);
        }
      }
    );
    onMounted(async () => {
      await setApiVersion();
      await getUserInfoAndUpdateStore();
      if (user.value.isAdmin) {
        await getHospitalListAndUpdateStore();
      }
    });

    return {
      currentTab,
      itemOptions,
      ContentTab,
      isEditing,
      tabComponents,
      tabProps,
      setIsEditing,
      onClickLogoutByAzure,
      categoryOptions,
      categoryOptionValue,
      showCannotEditWarningModal,
      onCloseCannotEditWarningModal,
      showUnsavedChangesModal,
      onCloseUnsavedChangesModal,
      whenLoadPage,
      isCollectionListOpen,
      onDontSaveChangesModal,
      saveAction,
      contents,
      onSaveChangesModal,
      isSearchResultListOpen,
      openSearchResultList,
      closeSearchResultList,
      searchInput,
      onUpdateSearchInput,
      isSearching,
      showConfirmDeleteImageModal,
      onDeleteConfirmDeleteImageModal,
      onCloseConfirmDeleteImageModal,
      openImageUploadDialog,
      inputElement,
      handleImageUpload,
      isUploadDialogOpened,
      isCodeGenerateTabOpen,
      createWipCollection,
      closeCodeGenerateTab,
      isCodeGenerateTabEditing,
      showLoading,
      setIsCodeGenerateTabEditing,
      showSchemaCodeModal,
      onCloseShowSchemaCodeModal,
      whenCodeGenerateSaveCompleted,
      onDeleteImageClick,
      isUploading,
      updatedDate,
      cancelEditCodeGenerateTab,
      openCollectionList,
      wipId,
      onCodeGenerateSaveClick,
      showCodeGenerateTabUnsavedChangeModal,
      onCloseCodeGenerateTabUnsavedChangeModal,
      onDontSaveCodeGenerateTabUnsavedChangeModal,
      onSaveCodeGenerateTabUnsavedChangeModal,
      showConfirmDeleteCollectionModal,
      onShowConfirmDeleteCollectionModalClick,
      onDeleteCollectionModalClick,
      onCloseCollectionModal,
      onCodeGenerateClick,
      currentPage,
      previousPage,
      openPage,
      onFocusSearchInput,
      collectionDrawerActiveKey,
      setCollectionDrawerActiveKey,
      user,
      showTwoButtonWarningModal,
      onCloseTwoButtonWarningModal,
      twoButtonWarningModalOptions,
      onShowDeleteIndex,
      onShowDeleteField,
      editingContents,
      onEditResourceContentItem,
      showResourceContentEditModal,
      onCloseResourceContentEditModal,
      saveWipResourceContentItemAndUpdateStore,
      onSaveResourceContentItem,
      onNewResourceContentItem,
      onCopyResourceContentItem,
      showFullResourceContentModal,
      onDeleteConfirmDeleteImageModalClick,
      saveEditPicture,
      onShowUnsavedChangesModalClick,
      switchTab,
      onCopyDataClick,
    };
  },
});
</script>

<style scoped lang="scss">
.main-page {
  background-color: var(--xv-container--background);
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.main-page-header {
  display: flex;
  width: 100%;
  padding: 12px 24px;
  justify-content: space-between;
  align-items: center;
}

.header-left {
  display: flex;
  align-items: center;
  gap: 16px;
}

.high-emphasis-text {
  color: var(--xv-text--high-emphasis-text);
}

.header-right {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 40px;
}

.medium-emphasis-text {
  color: var(--xv-text--medium-emphasis-text);
}

.dividing-line {
  width: 1px;
  height: 24px;
  background-color: var(--xv-text--dividing-line);
}

.main-page-body-container {
  display: flex;
  padding: 0px 20px 20px 20px;
  flex-direction: column;
  align-self: stretch;
  flex: 1 0 0;
}

.main-page-body {
  display: flex;
  flex-direction: row;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 16px;
  background-color: var(--xv-container--surface);
  overflow: hidden;
}

.main-page-sidebar {
  box-sizing: content-box;
  width: 303px;
  padding: 16px 0px 12px 0px;
  gap: 12px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--xv-text--dividing-line);
  position: relative;
}

.sidebar-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 16px 0px 16px 16px;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 10;
}
.action-bar {
  padding: 0 12px;
}

.drawer-wrapper {
  flex: 1 0 0;
  overflow-y: auto;
  scrollbar-gutter: stable;
}
.detailed-page-container {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  overflow-x: auto;
}

.collection-list-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 16px 0 16px 20px;
  gap: 8px;
  scrollbar-gutter: stable;
}

.search-result-list-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 16px 20px;
  scrollbar-gutter: stable;
}
.detailed-page-body {
  padding: 16px 20px 12px 20px;
  display: flex;
  height: inherit;
  width: inherit;
  flex: 1 0 0;
  gap: 20px;
  align-items: flex-start;
  scrollbar-gutter: stable;
  // background-color: rgb(207, 207, 207);
}

.body-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  height: inherit;
  // overflow-y: auto;
}
.subtitle {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.editing-subtitle-wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
}

.subtitle-control-panel {
  display: flex;
  align-items: center;
  gap: 16px;
}

.slide-tabs {
  display: flex;
  align-items: center;
  gap: 20px;
}
.tab-component {
  height: calc(100vh - 280px);
}

.full-page-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.loading-wrapper {
  display: flex;
  gap: 4px;
}

.button-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
</style>
