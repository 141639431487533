<template>
  <main>
    <router-view v-slot="{ Component }">
      <component :is="Component" />
    </router-view>
  </main>
  <XNotificationNew
    v-for="placement in XNotificationPlacement"
    :key="placement"
    :groupName="placement"
    :placement="placement"
  />
  <XToast v-model:visible="isToastShow" :content="toastText" :theme="toastTheme" placement="bottom" />
  <FullPageLoading :is-show="isLoading" :message="description" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { XNotificationPlacement, XNotificationNew, XToast } from '@asus-aics/xui';
import { useLoadingStore } from '@/store/shared/useLoadingStore';
import { storeToRefs } from 'pinia';
import FullPageLoading from '@/components/FullPageLoading.vue';
import { useToast } from '@/composables/shared/useToast';
export default defineComponent({
  components: {
    XNotificationNew,
    XToast,
    FullPageLoading,
  },
  setup() {
    const loadingStore = useLoadingStore();
    const { isLoading, description } = storeToRefs(loadingStore);
    const { isToastShow, toastText, toastTheme } = useToast();

    return {
      XNotificationPlacement,
      isLoading,
      description,
      isToastShow,
      toastText,
      toastTheme,
    };
  },
});
</script>

<style scoped lang="scss">
html {
  overflow: hidden;
  width: 100vw;
  height: 100vh;

  body {
    width: 100%;
    height: 100%;
  }
}

#app {
  width: 100%;
  height: 100%;
  // App background color should align with design ui
  // May check this color after if there is any change.
  background-color: #efeef2;
}
</style>
