<template>
  <div class="code-generate-tab-container">
    <div class="subtitle">
      <div v-if="!isEditing" class="subtitle-name-container">
        <div class="subtitle-and-name">
          <span class="medium-emphasis-text xv-text--body-md">Branch Name</span>
          <span class="high-emphasis-text xv-text--title-sm">{{ contents.wipName }}</span>
        </div>
        <div class="subtitle-and-name">
          <span class="medium-emphasis-text xv-text--body-md">Collection Name</span>
          <span class="high-emphasis-text xv-text--title-sm">{{
            contents.collectionNameCn
              ? `${contents.collectionName} (${contents.collectionNameCn})`
              : contents.collectionName
          }}</span>
        </div>
        <div class="subtitle-and-name">
          <span class="medium-emphasis-text xv-text--body-md">Owner</span>
          <span class="high-emphasis-text xv-text--title-sm">{{ contents.owner }}</span>
        </div>
      </div>
      <div v-else class="grid-container">
        <span class="medium-emphasis-text xv-text--body-md">Branch Name</span>
        <XInputText
          id="wip-edit-name-input"
          type="search"
          size="sm"
          :error="editingContents.wipNameError"
          :message="editingContents.wipNameErrorMessage"
          style="width: 444px"
          :model-value="editingContents.wipName"
          @update:model-value="
            (value) => {
              editingContents.wipName = value;
              validateWipName();
            }
          "
        >
          <template #prefix><span></span></template>
        </XInputText>
        <div class="blank-div"></div>
        <div class="blank-div"></div>
        <span class="medium-emphasis-text xv-text--body-md">Collection Name</span>
        <XInputText
          id="wip-edit-name-input"
          type="search"
          size="sm"
          :error="editingContents.collectionNameError"
          :message="editingContents.collectionNameErrorMessage"
          style="width: 444px"
          :model-value="editingContents.collectionName"
          @update:model-value="
            (value) => {
              editingContents.collectionName = value;
              validateCollectionName();
            }
          "
        >
          <template #prefix><span></span></template>
        </XInputText>
        <span class="medium-emphasis-text xv-text--body-md">Collection Name (CN)</span>
        <XInputText
          type="search"
          size="sm"
          :error="editingContents.collectionNameCnError"
          :message="editingContents.collectionNameCnErrorMessage"
          style="width: 460px"
          :model-value="editingContents.collectionNameCn"
          @update:model-value="
            (value) => {
              editingContents.collectionNameCn = value;
              validateCollectionNameCn();
            }
          "
        >
          <template #prefix><span></span></template>
        </XInputText>
      </div>
      <div class="subtitle-control-panel">
        <template v-if="isEditing">
          <XButton size="sm" theme="tertiary" outline @click="$emit('cancel-editing')">Cancel</XButton>
          <XButton size="sm" theme="primary" @click="onSaveClick">Save</XButton>
        </template>
        <template v-else>
          <span class="last-updated-text"></span>
          <XButton v-if="user.isAdmin" size="sm" theme="tertiary" outline @click="$emit('on-delete-click')"
            >Delete Collection</XButton
          >
          <XButton v-if="user.isAdmin" size="sm" theme="tertiary" outline @click="$emit('on-copy-click')"
            >Copy Data</XButton
          >
          <XButton v-if="user.isAdmin" size="sm" theme="primary" @click="onGenerateCodeClick">Generate Code</XButton>
        </template>
      </div>
    </div>
    <div class="tab-container">
      <div class="slide-tabs">
        <TabSwitch
          :item-options="itemOptions"
          :item-value="tabName"
          @update:item-value="(value) => switchTab(value)"
        ></TabSwitch>
        <XButton
          v-if="user.isAdmin && tabName === 'content' && !isEditing"
          size="sm"
          theme="primary"
          icon="edit"
          outline
          @click="$emit('set-is-editing', true)"
          >Edit Content</XButton
        >
        <XButton
          v-if="user.isAdmin && tabName === 'resourceContent'"
          icon="add"
          outline
          size="sm"
          @click="$emit('on-new-property-click')"
          >New Property</XButton
        >
      </div>
      <component
        :is="tabComponents[tabName]"
        class="tab-component"
        v-bind="tabProps[tabName]"
        @on-show-delete-index-modal="(index) => $emit('on-show-delete-index-modal', index)"
        @on-show-delete-field-modal="(index, fieldIndex) => $emit('on-show-delete-field-modal', index, fieldIndex)"
        @edit-resource-content-item="(item) => $emit('edit-resource-content-item', item)"
        @copy-resource-content-item="(item) => $emit('copy-resource-content-item', item)"
      ></component>
    </div>
  </div>
</template>

<script lang="ts">
import '@/assets/scss/scroll_bar.scss';
import { XButton, XInputText, XSlideTabs } from '@asus-aics/xui';
import { ref, defineComponent, computed, watch } from 'vue';
import ContentTab from '@/components/ContentTab.vue';
import ResourceContentTab from './ResourceContentTab.vue';
import { storeToRefs } from 'pinia';
import { useInfoDataStore } from '@/store/infoData';
import { useContentPageDataStore } from '@/store/contentPageData';
import { cloneDeep } from 'lodash';
import { editWipContentAndUpdateStore } from '@/utils/dataService';
import TabSwitch from '@/components/TabSwitch.vue';
export default defineComponent({
  name: 'CodeGenerateTab',
  components: { XButton, XInputText, XSlideTabs, ContentTab, ResourceContentTab, TabSwitch },
  props: {
    isEditing: {
      type: Boolean,
      required: true,
    },
    wipId: {
      type: String,
      required: true,
    },
    tabName: {
      type: String,
      required: true,
    },
  },
  emits: [
    'cancel',
    'cancel-editing',
    'full-page-loading',
    'set-is-editing',
    'on-generate-code-click',
    'save-completed',
    'on-save-click',
    'on-delete-click',
    'on-copy-click',
    'on-show-delete-index-modal',
    'on-show-delete-field-modal',
    'edit-resource-content-item',
    'on-new-property-click',
    'copy-resource-content-item',
    'on-tab-change',
  ],
  setup(props, { emit }) {
    const InfoData = useInfoDataStore();
    const contentStore = useContentPageDataStore();

    const { user } = storeToRefs(InfoData);
    const { contents, editingContents } = storeToRefs(contentStore);

    const { validateCollectionName, validateCollectionNameCn, validateWipName } = contentStore;
    //#region item-options
    const itemOptions = computed(() => [
      {
        label: 'Content',
        value: 'content',
        disabled: false,
      },
      {
        label: 'Resource Content',
        value: 'resourceContent',
        disabled: props.isEditing,
      },
    ]);
    //#endregion item-options

    const tabComponents = {
      content: ContentTab,
      resourceContent: ResourceContentTab,
    };

    const tabProps = computed(() => {
      return {
        content: {
          globalIsEditing: props.isEditing,
          isWip: props.wipId !== '',
        },
        resourceContent: {
          globalIsEditing: false,
        },
      };
    });

    const onSaveClick = async () => {
      emit(
        'on-save-click',
        props.wipId,
        editingContents.value.wipName,
        editingContents.value.collectionName,
        editingContents.value.collectionNameCn
      );
    };

    const onGenerateCodeClick = () => {
      emit('on-generate-code-click');
    };

    const nameError = ref<boolean>(false);
    const nameErrorMessage = ref<string>('');
    const nameCnError = ref<boolean>(false);
    const nameCnErrorMessage = ref<string>('');

    const switchTab = (value: string) => {
      emit('on-tab-change', value, true, props.wipId);
    };

    watch(
      () => props.isEditing,
      (value) => {
        if (value) {
          editingContents.value.collectionName = contents.value.collectionName;
          editingContents.value.collectionNameCn = contents.value.collectionNameCn;
          emit('on-tab-change', 'content', false, props.wipId);
          editingContents.value.wipName = contents.value.wipName;
          editWipContentAndUpdateStore(props.wipId);
          contentStore.setEditingContentPageData(cloneDeep(contents.value));
        }
      },
      { immediate: true }
    );
    return {
      tabComponents,
      tabProps,
      onSaveClick,
      onGenerateCodeClick,
      nameError,
      nameErrorMessage,
      nameCnError,
      nameCnErrorMessage,
      user,
      itemOptions,
      contents,
      editingContents,
      validateCollectionName,
      validateCollectionNameCn,
      validateWipName,
      switchTab,
    };
  },
});
</script>

<style scoped lang="scss">
.code-generate-tab-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 16px 20px;
  gap: 16px;
}

.grid-container {
  display: grid;
  grid-template-columns: 127px 460px 170px 460px;
  column-gap: 8px;
  row-gap: 8px;
  align-items: center;
}

.subtitle {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.subtitle-first-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.subtitle-and-name {
  display: flex;
  gap: 8px;
  align-items: center;
  height: 28px;
}
.subtitle-name-container {
  display: flex;
  flex-direction: column;
}
.subtitle-control-panel {
  display: flex;
  gap: 16px;
}

.name-edit-container {
  display: flex;
  gap: 24px;
}
.name-edit-wrapper {
  display: flex;
  gap: 8px;
}

.medium-emphasis-text {
  color: var(--xv-text--medium-emphasis-text);
}
.high-emphasis-text {
  color: var(--xv-text--high-emphasis-text);
}
.tab-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  gap: 16px;
  position: relative;
}

.last-updated-text {
  color: var(--xv-text--medium-emphasis-text);
  display: flex;
  align-items: center;
}

.slide-tabs {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
</style>
