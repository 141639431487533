import { defineStore } from 'pinia';

export interface ResourceContent {
  id: number;
  propertyName: string;
  errorMessagePropertyName?: string;
  propertyNameCn: string;
  errorMessagePropertyNameCn?: string;
  fieldPath: string;
  fieldDataType: string;
  errorMessageFieldDataType?: string;
  elementDataType: string | null;
  required: boolean;
  source: string;
  errorSource?: string;
  sourceNote: string;
  errorSourceNote?: string;
  note: string;
  errorNote?: string;
  tags: string;
  layer: number;
  parentName: string;
  enum?: string[];
  enumString?: string;
  errorEnumString?: string;
  errorTags?: string;
  defaultValue?: string;
  children?: ResourceContent[];
}

export interface ResourceContentWithMaxLine extends ResourceContent {
  maxLine: number;
}

const camelCaseRegex = /^[a-z][a-zA-Z0-9]*$/;

export const createResourceContentWithMaxLineHeight = (resourceContent: ResourceContent[]) => {
  return resourceContent.map((item) => {
    const newItem: ResourceContentWithMaxLine = {
      ...item,
      maxLine: 1, // Set the default value of maxLine to 1
    };

    // Recursively handle children array
    if (item.children && item.children.length > 0) {
      newItem.children = createResourceContentWithMaxLineHeight(item.children);
    }

    return newItem;
  });
};

export const useResourceContentPageDataStore = defineStore('resourceContentPageData', {
  state: () => ({
    resourceContent: [] as ResourceContent[],
    resourceContentCount: 0,
    currentEditResourceContentItem: {} as ResourceContent,
    editingResourceContent: [] as ResourceContentWithMaxLine[],
    parentOptions: [] as string[],
  }),
  getters: {},
  actions: {
    setResourceContentPageData(resourceContent: ResourceContent[]) {
      this.resourceContent = resourceContent;
    },
    setResourceContentCount(resourceContentCount: number) {
      this.resourceContentCount = resourceContentCount;
    },
    clearResourceContentPageData() {
      this.resourceContent = [];
    },

    clearEditingResourceContentPageData() {
      this.editingResourceContent = [];
    },
    clearCurrentEditResourceContentItemError() {
      this.currentEditResourceContentItem.errorMessagePropertyName = null;
      this.currentEditResourceContentItem.errorMessagePropertyNameCn = null;
      this.currentEditResourceContentItem.errorMessagePropertyName = null;
      this.currentEditResourceContentItem.errorMessageFieldDataType = null;
      this.currentEditResourceContentItem.errorSource = null;
      this.currentEditResourceContentItem.errorSourceNote = null;
      this.currentEditResourceContentItem.errorNote = null;
      this.currentEditResourceContentItem.errorEnumString = null;
      this.currentEditResourceContentItem.errorTags = null;
    },
    validatePropertyName() {
      if (this.currentEditResourceContentItem.propertyName.length > 100) {
        this.currentEditResourceContentItem.errorMessagePropertyName = `${this.currentEditResourceContentItem.propertyName.length} / 100 characters`;
        return false;
      } else if (!camelCaseRegex.test(this.currentEditResourceContentItem.propertyName)) {
        this.currentEditResourceContentItem.errorMessagePropertyName = 'Property name should follow camelCase format';
        return false;
      }
      this.currentEditResourceContentItem.errorMessagePropertyName = null;
      return true;
    },
    validatePropertyNameCn() {
      if (this.currentEditResourceContentItem.propertyNameCn.length > 100) {
        this.currentEditResourceContentItem.errorMessagePropertyNameCn = `${this.currentEditResourceContentItem.propertyNameCn.length} / 100 characters`;
        return false;
      }
      this.currentEditResourceContentItem.errorMessagePropertyNameCn = null;
      return true;
    },
    validateSource() {
      if (this.currentEditResourceContentItem.source.length > 1000) {
        this.currentEditResourceContentItem.errorSource = `${this.currentEditResourceContentItem.source.length} / 1000 characters`;
        return false;
      }
      this.currentEditResourceContentItem.errorSource = null;
      return true;
    },
    validateSourceNote() {
      if (this.currentEditResourceContentItem.sourceNote.length > 10000) {
        this.currentEditResourceContentItem.errorSourceNote = `${this.currentEditResourceContentItem.sourceNote.length} / 10000 characters`;
        return false;
      }
      this.currentEditResourceContentItem.errorSourceNote = null;
      return true;
    },
    validateNote() {
      if (this.currentEditResourceContentItem.note.length > 1000) {
        this.currentEditResourceContentItem.errorNote = `${this.currentEditResourceContentItem.note.length} / 1000 characters`;
        return false;
      }
      this.currentEditResourceContentItem.errorNote = null;
      return true;
    },
    validateTag() {
      if (this.currentEditResourceContentItem.tags?.length > 200) {
        this.currentEditResourceContentItem.errorTags = `${this.currentEditResourceContentItem.tags.length} / 200 characters`;
        return false;
      }
      this.currentEditResourceContentItem.errorTags = null;
      return true;
    },
    validateFieldDataType() {
      if (!this.currentEditResourceContentItem.fieldDataType) {
        this.currentEditResourceContentItem.errorMessageFieldDataType = 'Field data type cannot be empty';
        return false;
      }
      this.currentEditResourceContentItem.errorMessageFieldDataType = null;
      return true;
    },
    validateCurrentEditResourceContentItem() {
      const validateEnumFunction = (text: string) => {
        if (!text) {
          return true;
        }
        const reg = /^(?:[^|]+(?:\|[^|]+)+|[^|]+)$/;
        return reg.test(text);
      };

      this.clearCurrentEditResourceContentItemError();
      if (this.currentEditResourceContentItem.propertyName.length === 0) {
        this.currentEditResourceContentItem.errorMessagePropertyName = 'Property name cannot be blank';
      }
      if (!validateEnumFunction(this.currentEditResourceContentItem.enumString)) {
        this.currentEditResourceContentItem.errorEnumString = 'Enum format is not correct';
      }
      this.validatePropertyName();
      this.validatePropertyNameCn();
      this.validateSource();
      this.validateSourceNote();
      this.validateNote();
      this.validateTag();
      this.validateFieldDataType();
      return !(
        this.currentEditResourceContentItem.errorMessagePropertyName ||
        this.currentEditResourceContentItem.errorMessagePropertyNameCn ||
        this.currentEditResourceContentItem.errorSource ||
        this.currentEditResourceContentItem.errorSourceNote ||
        this.currentEditResourceContentItem.errorNote ||
        this.currentEditResourceContentItem.errorEnumString ||
        this.currentEditResourceContentItem.errorTags ||
        this.currentEditResourceContentItem.errorMessageFieldDataType
      );
    },
  },
});
