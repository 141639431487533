export const PASSWORDS_NOT_MATCH_ERROR = 'Passwords do not match';
export const PASSWORD_REQUIREMENTS_ERROR = 'Password does not meet requirements';
export const GENERIC_ERROR_MESSAGE = 'An error has occurred, please try again later';
export const TOO_MANY_ATTEMPTS_ERROR = 'Too many attempts, please try again later';
export const UNAUTHORIZED_ERROR = 'Unauthorized: User is not authenticated';

export const VERIFICATION_CODE_SENT_MESSAGE = 'Verification code sent';

export const MIN_PASSWORD_LENGTH = 12;
export const RESEND_CODE_COUNTDOWN = 30;
