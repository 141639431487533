import { defineStore } from 'pinia';
import { SelectMenuItem, XNotificationType } from '@asus-aics/xui';
import { isEqual, uniqueId } from 'lodash';
import { useNotification } from '@/composables/useNotification';
interface Field {
  name: string;
  sort: string;
  uuid: string;
  doesFieldExist: boolean;
  isError?: boolean;
  errorMessage?: string;
}

interface ContentIndex {
  name: string;
  nameError?: boolean;
  nameErrorMessage?: string;
  fields: Field[];
  uuid: string;
  useDefaultName?: boolean;
  unique?: boolean;
  expireAfterSeconds?: number;
  expireAfterSecondsError?: boolean;
  wipId?: string;
}

interface Content {
  collectionName: string;
  collectionNameError?: boolean;
  collectionNameErrorMessage?: string;
  collectionNameCn: string;
  collectionNameCnError?: boolean;
  collectionNameCnErrorMessage?: string;
  wipId?: string;
  wipName?: string;
  wipNameError?: boolean;
  wipNameErrorMessage?: string;
  category_name?: string;
  wipCollectionNameError?: boolean;
  wipCollectionNameErrorMessage?: string;
  scopeAndUsage: string;
  scopeAndUsageError?: boolean;
  scopeAndUsageErrorMessage?: string;
  backgroundAndContext: string;
  backgroundAndContextError?: boolean;
  backgroundAndContextErrorMessage?: string;
  serviceToThisResource: string;
  serviceToThisResourceError?: boolean;
  serviceToThisResourceErrorMessage?: string;
  index: ContentIndex[];
  owner?: string;
}

const MAX_COLLECTION_NAME_LENGTH = 100;
const MAX_COLLECTION_NAME_CN_LENGTH = 100;
const MAX_WIP_NAME_LENGTH = 100;
const MAX_LENGTH_SCOPE_AND_USAGE = 10000;
const MAX_LENGTH_BACKGROUND_AND_CONTEXT = 10000;
const MAX_LENGTH_SERVICE_TO_THIS_RESOURCE = 1000;
const useContentPageDataStore = defineStore('contentPageData', {
  state: () => ({
    contents: {} as Content,
    editingContents: {} as Content,
    wipId: '',
    fieldList: [] as SelectMenuItem[],
    sortList: [] as SelectMenuItem[],
  }),
  getters: {},
  actions: {
    clearContentPageData() {
      this.contents = {} as Content;
    },
    setContentPageData(contents: Content) {
      this.contents = contents;
    },
    clearEditingContentPageData() {
      this.editingContents = {} as Content;
    },
    setEditingContentPageData(contents: Content) {
      this.editingContents = contents;
    },
    addNewIndex() {
      this.editingContents.index.push({
        name: '',
        fields: [{ name: '', sort: 'asc' }],
        uuid: uniqueId(),
        useDefaultName: false,
        unique: false,
      });
    },
    addNewField(index: number) {
      this.editingContents.index[index].fields.push({ name: '', sort: 'asc', uuid: uniqueId(), doesFieldExist: true });
    },
    deleteIndex(index: number) {
      this.editingContents.index.splice(index, 1);
    },
    deleteField(index: number, fieldIndex: number) {
      this.editingContents.index[index].fields.splice(fieldIndex, 1);
    },
    setFieldList(fieldList: string[]) {
      this.fieldList = fieldList.map((field) => ({ value: field, label: field }));
    },
    setSortList(sortList: string[]) {
      this.sortList = sortList.map((sort) => ({ value: sort, label: sort }));
    },
    isEditingContentChanged() {
      const { editingContents, contents } = this;
      return !isEqual(editingContents, contents);
    },
    //#region validate
    isFieldExistInIndex(index: number, fieldIndex: number, fieldName: string) {
      const fieldList = this.editingContents.index[index].fields;
      return fieldList.some((field, currentIndex) => field.name === fieldName && fieldIndex !== currentIndex);
    },
    validateWipName() {
      if (this.editingContents.wipName.length > MAX_WIP_NAME_LENGTH) {
        this.editingContents.wipNameError = true;
        this.editingContents.wipNameErrorMessage = `${this.editingContents.wipName.length} / ${MAX_WIP_NAME_LENGTH} characters`;
      } else {
        this.editingContents.wipNameError = false;
        this.editingContents.wipNameErrorMessage = '';
      }
    },
    validateCollectionName() {
      const regex = /^[a-z][a-zA-Z0-9]*$/;
      if (!regex.test(this.editingContents.collectionName)) {
        this.editingContents.collectionNameError = true;
        this.editingContents.collectionNameErrorMessage = 'Collection name must be camelCase.';
      } else if (this.editingContents.collectionName.length > MAX_COLLECTION_NAME_LENGTH) {
        this.editingContents.collectionNameError = true;
        this.editingContents.collectionNameErrorMessage = `${this.editingContents.collectionName.length} / ${MAX_COLLECTION_NAME_LENGTH} characters`;
      } else {
        this.editingContents.collectionNameError = false;
        this.editingContents.collectionNameErrorMessage = '';
      }
    },
    validateCollectionNameCn() {
      if (this.editingContents.collectionNameCn.length > MAX_COLLECTION_NAME_CN_LENGTH) {
        this.editingContents.collectionNameCnError = true;
        this.editingContents.collectionNameCnErrorMessage = `${this.editingContents.collectionNameCn.length} / ${MAX_COLLECTION_NAME_CN_LENGTH} characters`;
      } else {
        this.editingContents.collectionNameCnError = false;
        this.editingContents.collectionNameCnErrorMessage = '';
      }
    },
    validateField(index: number, fieldIndex: number, fieldName: string) {
      const isFieldNoLongerExist = !this.editingContents.index[index].fields[fieldIndex].doesFieldExist;
      const isFieldExist = this.isFieldExistInIndex(index, fieldIndex, fieldName);
      if (isFieldNoLongerExist) {
        this.editingContents.index[index].fields[fieldIndex].isError = true;
        this.editingContents.index[index].fields[fieldIndex].errorMessage = 'This field no longer exists';
      } else if (isFieldExist) {
        this.editingContents.index[index].fields[fieldIndex].isError = true;
        this.editingContents.index[index].fields[fieldIndex].errorMessage = 'This field already exists';
      } else {
        this.editingContents.index[index].fields[fieldIndex].isError = false;
        this.editingContents.index[index].fields[fieldIndex].errorMessage = '';
      }
    },
    validateScopeAndUsage() {
      if (this.editingContents.scopeAndUsage.length > MAX_LENGTH_SCOPE_AND_USAGE) {
        this.editingContents.scopeAndUsageError = true;
        this.editingContents.scopeAndUsageErrorMessage = `${this.editingContents.scopeAndUsage.length} / ${MAX_LENGTH_SCOPE_AND_USAGE} characters`;
      } else {
        this.editingContents.scopeAndUsageError = false;
        this.editingContents.scopeAndUsageErrorMessage = '';
      }
    },
    validateBackgroundAndContext() {
      if (this.editingContents.backgroundAndContext.length > MAX_LENGTH_BACKGROUND_AND_CONTEXT) {
        this.editingContents.backgroundAndContextError = true;
        this.editingContents.backgroundAndContextErrorMessage = `${this.editingContents.backgroundAndContext.length} / ${MAX_LENGTH_BACKGROUND_AND_CONTEXT} characters`;
      } else {
        this.editingContents.backgroundAndContextError = false;
        this.editingContents.backgroundAndContextErrorMessage = '';
      }
    },
    validateServiceToThisResource() {
      if (this.editingContents.serviceToThisResource.length > MAX_LENGTH_SERVICE_TO_THIS_RESOURCE) {
        this.editingContents.serviceToThisResourceError = true;
        this.editingContents.serviceToThisResourceErrorMessage = `${this.editingContents.serviceToThisResource.length} / ${MAX_LENGTH_SERVICE_TO_THIS_RESOURCE} characters`;
      } else {
        this.editingContents.serviceToThisResourceError = false;
        this.editingContents.serviceToThisResourceErrorMessage = '';
      }
    },
    isEditingContentValid() {
      const { editingContents } = this;
      return (
        !editingContents.wipNameError &&
        !editingContents.collectionNameError &&
        !editingContents.collectionNameCnError &&
        !editingContents.scopeAndUsageError &&
        !editingContents.backgroundAndContextError &&
        !editingContents.serviceToThisResourceError &&
        !editingContents.wipCollectionNameError &&
        editingContents.index.every((index) => {
          return index.fields.every((field) => !field.isError);
        }) &&
        editingContents.index.every((index) => {
          // if namError is null, blank or undefined, it is valid
          return !index.nameError;
        })
      );
    },
    validateIndexName(index: number) {
      // only accept a-z, A-Z, 0-9, _, ., -
      const regex = /^[a-zA-Z0-9_.-]*$/;
      if (!regex.test(this.editingContents.index[index].name)) {
        this.editingContents.index[index].nameError = true;
        this.editingContents.index[index].nameErrorMessage = 'Index name must be a-z, A-Z, 0-9, _, ., -.';
      } else {
        this.editingContents.index[index].nameError = false;
        this.editingContents.index[index].nameErrorMessage = '';
      }
    },
    validateExpireAfterSeconds(index: number) {
      const { showNotification } = useNotification();
      const expireAfterSecondsString: string = this.editingContents.index[index].expireAfterSeconds;
      if (!expireAfterSecondsString) return;
      const expireAfterSeconds = Number(expireAfterSecondsString);

      // if expireAfterSeconds is not integer, set it to floor integer
      this.editingContents.index[index].expireAfterSecondsError = true;
      if (!Number.isInteger(Number(expireAfterSeconds))) {
        showNotification(XNotificationType.Error, 'The TTL index expireAfterSeconds value must be an integer.');
        this.editingContents.index[index].expireAfterSeconds = Math.floor(expireAfterSeconds);
      } else if (expireAfterSeconds < 0 || expireAfterSeconds > 2147483647) {
        this.editingContents.index[index].expireAfterSeconds = 0;

        showNotification(
          XNotificationType.Error,
          'The TTL index expireAfterSeconds value must be within 0 and 2147483647 inclusive.'
        );
      } else {
        this.editingContents.index[index].expireAfterSeconds = expireAfterSeconds;
        this.editingContents.index[index].expireAfterSecondsError = false;
      }
    },
    //#endregion validate
  },
});

export { Content, useContentPageDataStore, Field };
