import { defineStore } from 'pinia';

export const useSampleDataStore = defineStore('sampleData', {
  state: () => ({
    sampleData: [] as string[],
  }),
  getters: {},
  actions: {
    setSampleData(data: string[]) {
      this.sampleData = data;
    },
    clearSampleData() {
      this.sampleData = [];
    },
  },
});
