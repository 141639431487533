<template>
  <div class="main-page-header">
    <div class="header-left">
      <span class="high-emphasis-text xv-title--title-lg clickable" @click="onTitleClick">xHIS CDP Portal</span>
      <XNavTab
        :tab-list="tabList"
        :current-tab-name="currentTabName"
        @change="(name) => onTabChange(name)"
        size="lg"
        :style="{ height: '46px', cursor: 'pointer' }"
      />
    </div>
    <div class="header-right">
      <template v-if="currentTabName === 'Collection List'">
        <span class="medium-emphasis-text xv-body--body-sm">Version {{ version }}</span>
        <XTag
          theme="primary"
          rounded
          :style="{
            cursor: user.isAicsUser ? 'pointer' : '',
            backgroundColor:
              user.isAicsUser && isHospitalTagHover ? 'var(--xv-primary--200)' : 'var(--xv-primary--100)',
          }"
          @mouseover="isHospitalTagHover = true"
          @mouseleave="isHospitalTagHover = false"
          @click="
            () => {
              if (user.isAicsUser) onShowHospitalSelectorModal();
            }
          "
          >{{ currentHospital !== '' ? currentHospital : '&nbsp' }}</XTag
        >
      </template>
      <template v-else>
        <span class="medium-emphasis-text xv-body--body-sm">Commit: {{ commitVersion }}</span>
      </template>
      <XIconButton icon="notification" size="sm" />
      <div class="dividing-line"></div>
      <XIconButton icon="avatar" :text="user.username" long size="sm" />
      <XButton outline size="md" style="margin-left: 8px" @click="logout">Log Out</XButton>
    </div>
  </div>
  <HospitalSelectorModal />
</template>

<script lang="ts">
import { logoutByAzure } from '@/api/authApi';
import { TabEntry, XIconButton, XButton, XNavTab, XTag } from '@asus-aics/xui';
import { computed, defineComponent, PropType, ref, watch } from 'vue';
import router, { tabNameTranslate } from '@/router';
import { useInfoDataStore } from '@/store/infoData';
import { storeToRefs } from 'pinia';
import HospitalSelectorModal from '@/components/modals/HospitalSelectorModal.vue';
import { useModalState } from '@/store/modalState';

export default defineComponent({
  name: 'Components',
  components: { XIconButton, XButton, XNavTab, XTag, HospitalSelectorModal },
  props: {
    clickSelf: {
      type: Function,
      default: () => {},
    },
    initialTabName: {
      type: String as PropType<string>,
      default: '',
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const currentTabName = ref<string>(props.initialTabName);
    const isHospitalTagHover = ref<boolean>(false);
    const infoDataStore = useInfoDataStore();
    const modalState = useModalState();

    const { version, user, commitVersion, currentHospital } = storeToRefs(infoDataStore);
    const { onShowHospitalSelectorModal } = modalState;

    const tabList = computed<TabEntry[]>(() => [{ name: 'Collection List', displayName: 'Collection List' }]);
    const onTitleClick = () => {
      router.push('/');
    };
    const logout = async () => {
      await logoutByAzure();
    };

    const onTabChange = (name: string): void => {
      if (currentTabName.value === name) {
        props.clickSelf();
        return;
      }
      currentTabName.value = name;
    };

    watch(
      () => currentTabName.value,
      () => {
        const routerPath = tabNameTranslate(currentTabName.value);
        router.push(`/${routerPath}/`);
      }
    );
    return {
      tabList,
      currentTabName,
      onTitleClick,
      logout,
      onTabChange,
      user,
      version,
      commitVersion,
      isHospitalTagHover,
      onShowHospitalSelectorModal,
      currentHospital,
    };
  },
});
</script>

<style scoped lang="scss">
.main-page-header {
  display: flex;
  width: 100%;
  padding: 12px 24px;
  justify-content: space-between;
  align-items: center;
}

.header-left {
  display: flex;
  align-items: center;
  gap: 16px;
}

.high-emphasis-text {
  color: var(--xv-text--high-emphasis-text);
}
.medium-emphasis-text {
  color: var(--xv-text--medium-emphasis-text);
}

.header-right {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 40px;
}

.clickable {
  cursor: pointer;
}

.dividing-line {
  width: 1px;
  height: 24px;
  background-color: var(--xv-text--dividing-line);
}
</style>
