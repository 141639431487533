import { defineStore } from 'pinia';

interface User {
  username: string;
  first_name: string;
  last_name: string;
  groups: string[];
  isAdmin: boolean;
  isAicsUser: boolean;
}

const checkIsAdmin = (groups: string[]): boolean => {
  return groups.includes('CDP-Admin');
};

const checkIsAicsUser = (groups: string[]): boolean => {
  return groups.includes('CDP-AICS-User');
};

export const useInfoDataStore = defineStore('infoData', {
  state: () => ({
    version: '',
    user: {
      email: '',
      username: '',
      first_name: '',
      last_name: '',
      groups: [],
      isAdmin: false,
      isAicsUser: false,
    } as User,
    commitVersion: '',
    currentHospital: '',
    previousHospital: '',
  }),
  getters: {},
  actions: {
    setVersions(version: string) {
      this.version = version;
    },
    setUser(user: Omit<User, 'isAdmin' | 'isAicsUser'>) {
      this.user = { ...user, isAdmin: checkIsAdmin(user.groups), isAicsUser: checkIsAicsUser(user.groups) };
    },
    setCommitVersion(commitVersion: string) {
      this.commitVersion = commitVersion;
    },
    setCurrentHospital(hospital: string) {
      this.previousHospital = this.currentHospital;
      this.currentHospital = hospital;
    },
    restorePreviousHospital() {
      if (this.previousHospital === '') this.previousHospital = this.currentHospital;
      this.currentHospital = this.previousHospital;
    },
  },
});
