import { defineStore } from 'pinia';

const LOADING_DELAY = 1000;

export const useLoadingStore = defineStore('loadingStore', {
  state: () => ({
    isLoading: false,
    description: '',
    timeoutIds: [] as NodeJS.Timeout[],
  }),
  getters: {},
  actions: {
    showLoading(delayed = true, description: string = ''): void {
      this.description = description;
      if (delayed) {
        const timeoutId = setTimeout(() => {
          this.isLoading = true;
          this.timeoutIds.splice(this.timeoutIds.indexOf(timeoutId), 1);
        }, LOADING_DELAY);
        this.timeoutIds.push(timeoutId);
      } else {
        this.isLoading = true;
      }
    },
    hideLoading(): void {
      this.timeoutIds.forEach((timeoutId) => {
        clearTimeout(timeoutId);
      });
      this.timeoutIds.length = 0;
      this.isLoading = false;
    },
  },
});
