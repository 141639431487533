<template>
  <ul
    :style="{
      listStylePosition: 'inside',
      listStyleType: 'disc',
      color: 'var(--xv-text--medium-emphasis-text)',
    }"
  >
    <li>
      <span class="xv-body--body-md" :class="{ 'error-message': !isPasswordLengthValid }"
        >At least {{ MIN_PASSWORD_LENGTH }} characters</span
      >
    </li>
    <li>
      <span class="xv-body--body-md" :class="{ 'error-message': !isPasswordContainUppercase }"
        >Contain at least 1 uppercase letter</span
      >
    </li>
    <li>
      <span class="xv-body--body-md" :class="{ 'error-message': !isPasswordContainNumber }"
        >Contain at least 1 number (0-9)</span
      >
    </li>
  </ul>
</template>

<script lang="ts">
import { MIN_PASSWORD_LENGTH } from '@/utils/shared/constants';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PasswordValidation',
  components: {},
  props: {
    isPasswordLengthValid: {
      type: Boolean,
      default: false,
    },
    isPasswordContainUppercase: {
      type: Boolean,
      default: false,
    },
    isPasswordContainNumber: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return { MIN_PASSWORD_LENGTH };
  },
});
</script>

<style scoped lang="scss">
.error-message {
  color: var(--xv-status--error);
}
</style>
