<template>
  <div class="vgs" @click.stop="$emit('closeGallery')">
    <div class="vgs__container" @click.stop>
      <img class="vgs__container__img" :src="imageInfo?.src" :alt="imageInfo?.alt" />
    </div>

    <div v-if="isMultiple || index === null" class="vgs__gallery" @click.stop>
      <div class="vgs__gallery__container x-scroll-bar">
        <div
          v-for="(img, idx) in thumbnailImages"
          :key="idx"
          :ref="galleryItemRefs.el"
          :style="handleThumbnailStyle(img, idx)"
          role="thumbnailImage"
          class="vgs__gallery__container__img"
          @click.stop="onClickThumb(idx)"
        ></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, Ref, watch, computed, PropType, CSSProperties, ComputedRef, nextTick } from 'vue';

import { OpPictureItem, OpOriginPictureItem } from '@/utils/useTableData';

export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Gallery',
  props: {
    thumbnailImages: {
      default: () => [],
      type: Array as PropType<Array<OpPictureItem>>,
    },
    originImages: {
      default: () => [],
      type: Array as PropType<Array<OpOriginPictureItem>>,
    },
    index: {
      default: null,
      type: Number as PropType<number | null>,
    },
  },
  emits: ['closeGallery', 'update:imgIndex'],
  setup(props, { emit }) {
    const galleryItemRefs: { el: Ref<HTMLElement[]> } = { el: ref([]) };

    const imageInfo: ComputedRef<{ src?: string; alt?: string }> = computed(() => {
      if (props.index === null || props.originImages.length === 0) {
        return {};
      }

      return {
        src: 'data:image/jpeg;base64,' + props.originImages[props.index]?.pictureData,
        alt: props.originImages[props.index]?.pictureId,
      };
    });

    const isMultiple = computed(() => props.originImages.length > 1);

    const handleThumbnailStyle = (img: OpPictureItem, idx: number): CSSProperties => {
      if (props.index === null) {
        return {};
      }
      if (idx !== props.index) {
        return {
          background:
            'linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),' +
            'url(' +
            'data:image/jpeg;base64,' +
            img.thumbnail +
            ')',
        };
      } else {
        return {
          background: 'url(' + 'data:image/jpeg;base64,' + img.thumbnail + ')',
        };
      }
    };

    const onClickThumb = (index: number) => {
      emit('update:imgIndex', index);
      scrollToTargetThumbnail();
    };

    const scrollToTargetThumbnail = () => {
      if (props.index === null || galleryItemRefs.el.value?.length === 0) {
        return;
      }
      galleryItemRefs.el.value[props.index].scrollIntoView({
        behavior: 'smooth',
        inline: 'center',
      });
    };

    // call scrollToTargetThumbnail when popup
    watch(
      () => props.index,
      (newIndex, prevIndex) => {
        if (prevIndex === null && newIndex !== null) {
          nextTick(() => {
            scrollToTargetThumbnail();
          });
        }
      }
    );

    return {
      imageInfo,
      isMultiple,
      galleryItemRefs,
      onClickThumb,
      handleThumbnailStyle,
    };
  },
});
</script>

<style lang="scss" scoped>
$white-background: rgba(245, 245, 245, 0.85);
$black: #000;
$white: #fff;
$radius-large: 12px;

@mixin modal-base() {
  transition: opacity 0.2s ease;
  position: fixed;
  z-index: 9998;
}
@mixin modal-mask() {
  @include modal-base();
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  height: 100vh;
  background-color: $white-background;
}
.vgs {
  @include modal-mask();
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
}

.vgs__container {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);

  cursor: auto;
  margin: auto;
  height: 75%;
  &__img {
    // width: 100%;
    // height: 100%;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.vgs__gallery {
  position: absolute;
  bottom: 2%;
  margin: auto;
  cursor: auto;

  max-width: 100%;

  &__container {
    display: block;
    text-align: center;
    width: 100%;
    height: 120px;

    white-space: nowrap;
    overflow: auto;

    &__img {
      object-fit: cover;
      display: inline-block;
      float: none;

      width: 100px;
      height: 100px;
      margin: 0px 10px;
      border-radius: 8px;
      cursor: pointer;
    }
  }
}
</style>
