<template>
  <div class="loading-wrapper"><XIcon icon="spinner" class="rotating-spinner" /></div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { XIcon } from '@asus-aics/xui';
export default defineComponent({
  name: 'LoadingIcon',
  components: { XIcon },
  setup() {
    return {};
  },
});
</script>

<style scoped lang="scss">
.loading-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotating-spinner {
  animation: rotate 2s linear infinite;
  height: 20px;
  width: 20px;
  color: var(--xv-status--primary);
}
</style>
