<template>
  <XTextArea
    :class="{ 'input-text-container--error': error }"
    :style="{ borderColor: error ? 'rgb(var(--x-input-primary))' : '' }"
    ref="textAreaRef"
    :model-value="modalEdit ? modalShortText : content"
    :readonly="modalEdit"
    :placeholder="placeholder"
    @update:model-value="(value) => onInput(value)"
    @click="showModalAndFocus"
  />
  <XErrorMessage v-if="error" :message="message" />
  <TableInputEditModal
    v-if="modalEdit && showModal"
    :show="showModal"
    :content="modalText"
    @onModalClose="showModal = false"
    @update:content="(value) => onModalInput(value)"
    @onSave="saveTags"
  />
</template>

<script lang="ts">
import { ref, defineComponent, onMounted, watch, computed, nextTick } from 'vue';
import { XTextArea } from '@asus-aics/xui';
import TableInputEditModal from '@/components/TableInputEditModal.vue';
import XErrorMessage from '@/components/XErrorMessage.vue';
export default defineComponent({
  name: 'XTextAreaWithLineLimits',
  components: {
    XTextArea,
    TableInputEditModal,
    XErrorMessage,
  },
  props: {
    lineLimits: {
      type: Number,
      default: 5,
    },
    content: {
      type: String,
      default: '',
    },
    modalEdit: {
      type: Boolean,
      default: false,
    },
    minLine: {
      type: Number,
      required: false,
    },
    currentLine: {
      type: Number,
      default: 1,
    },
    placeholder: {
      type: String,
      default: '',
    },
    error: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: '',
    },
  },
  emits: ['update:content', 'update:new-max-line'],
  setup(props, { emit }) {
    const onInput = (value: string) => {
      autoResizeTextArea();
      emit('update:content', value);
    };

    const modalText = ref('');
    const onModalInput = (value: string) => {
      modalText.value = value;
    };

    const saveTags = () => {
      onInput(modalText.value);
    };

    const modalShortText = computed(() => {
      if (!props.content) {
        return '';
      }
      if (props.content.length > 5) {
        return props.content.substring(0, 3) + '...';
      } else {
        return props.content;
      }
    });
    const textAreaRef = ref(null);
    const updateNewMaxLine = (newMaxLine: number) => {
      emit('update:new-max-line', newMaxLine);
    };
    const autoResizeTextArea = () => {
      if (textAreaRef.value || textAreaRef.value === '') {
        const textAreaHtmlElement = textAreaRef.value.$el as HTMLTextAreaElement;
        textAreaHtmlElement.style.height = 'auto';
        textAreaHtmlElement.style.height = `${textAreaHtmlElement.scrollHeight}px`;
        const lineHeight = parseInt(getComputedStyle(textAreaHtmlElement).lineHeight);

        // maxLine is 5
        const maxHeight = lineHeight * (props.lineLimits + 1);

        // Set the minimum height to a fixed line height
        const minLine = props.minLine ? props.minLine : 1;
        textAreaHtmlElement.style.height = lineHeight * minLine + 'px';
        let currentLine = 1;
        // Adjust the height until it does not exceed the maximum height or number of text lines
        while (
          textAreaHtmlElement.scrollHeight > textAreaHtmlElement.offsetHeight &&
          textAreaHtmlElement.offsetHeight < maxHeight
        ) {
          textAreaHtmlElement.style.height = textAreaHtmlElement.offsetHeight + lineHeight + 'px';
          currentLine++;
        }
        if (currentLine > props.currentLine) {
          updateNewMaxLine(currentLine);
        } else {
          while (currentLine < props.currentLine) {
            textAreaHtmlElement.style.height = textAreaHtmlElement.offsetHeight + lineHeight + 'px';
            currentLine++;
          }
        }
        // prevent scrollbar showup before overflow
        const basicHeight =
          lineHeight * minLine > textAreaHtmlElement.offsetHeight
            ? lineHeight * minLine
            : textAreaHtmlElement.offsetHeight;
        textAreaHtmlElement.style.height = basicHeight + 5 + 'px';
      }
    };

    const showModal = ref(false);

    const showModalAndFocus = () => {
      if (!props.modalEdit) {
        return;
      }
      showModal.value = true;
      nextTick(() => {
        const textAreaHtmlElement = document.querySelector('#tags-text-area');
        (textAreaHtmlElement as HTMLTextAreaElement).focus();
      });
    };
    watch(
      () => props.currentLine,
      () => {
        autoResizeTextArea();
      },
      { immediate: true }
    );

    watch(
      () => showModal.value,
      () => {
        modalText.value = props.content;
      }
    );
    onMounted(() => {
      if (props.modalEdit) {
        const textAreaHtmlElement = textAreaRef.value.$el as HTMLTextAreaElement;
        textAreaHtmlElement.rows = 1;
        textAreaHtmlElement.classList.remove('autoResizeTextArea');
        textAreaHtmlElement.classList.add('noAutoResizeTextArea');
      } else {
        autoResizeTextArea();
      }
    });

    return {
      onInput,
      modalShortText,
      textAreaRef,
      showModal,
      modalText,
      onModalInput,
      saveTags,
      showModalAndFocus,
    };
  },
});
</script>

<style scoped lang="scss">
.autoResizeTextArea {
  overflow-y: auto;
  resize: none;
  margin: 8px 0;
  width: 100%;
}

.noAutoResizeTextArea {
  resize: none;
  margin: 8px 0;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.input-text-container--error {
  --x-input-primary: var(--xv-status--error--rgb);
  border-color: rgb(var(--x-input-primary));
}
</style>
