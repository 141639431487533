import { uploadImageAndUpdateStore } from './dataService';

type ImageUploadResult = { state: 'success'; data: any } | { state: 'failed'; error: { state: string; error: any } };

const handleImageUpload = async (event, collectionName): Promise<ImageUploadResult> => {
  const file = event.target.files[0];
  const response = await uploadImageAndUpdateStore(collectionName, file);
  if (response.status === 'success') {
    return { state: 'success', data: response };
  }
  return { state: 'failed', error: response };
};

export { handleImageUpload };
