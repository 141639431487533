import { MIN_PASSWORD_LENGTH } from '@/utils/shared/constants';
import { ref, Ref } from 'vue';

export const usePasswordValidator = (password: Ref<string>, confirmPassword: Ref<string>) => {
  const isPasswordLengthValid = ref(true);
  const isPasswordContainUppercase = ref(true);
  const isPasswordContainNumber = ref(true);
  const isPasswordMatching = ref(true);
  const isPasswordValid = ref(true);

  const updatePasswordMatching = () => {
    isPasswordMatching.value = password.value === confirmPassword.value;
  };

  const updatePasswordValidation = () => {
    isPasswordLengthValid.value = password.value.length >= MIN_PASSWORD_LENGTH;
    isPasswordContainUppercase.value = /[A-Z]/.test(password.value);
    isPasswordContainNumber.value = /\d/.test(password.value);
    isPasswordValid.value =
      isPasswordLengthValid.value && isPasswordContainUppercase.value && isPasswordContainNumber.value;
  };

  return {
    isPasswordLengthValid,
    isPasswordContainUppercase,
    isPasswordContainNumber,
    isPasswordValid,
    isPasswordMatching,
    updatePasswordMatching,
    updatePasswordValidation,
  };
};
