<template>
  <div class="title-bar">
    <XIconButton icon="arrow-left" @click="$emit('close-list')" />
    <span class="title-text xv-title--title-md">Search results</span>
  </div>
  <div v-if="searchResults.length !== 0" class="list-item-container">
    <template v-for="result in searchResults" :key="result.key">
      <SearchResultListItem
        :search-text="result.searchText"
        :category="result.category"
        :collection-name="result.collectionName"
        :collection-name-cn="result.collectionNameCn"
        :contentType="result.contentType"
        :wip-id="result.wipId"
        @click="
          (category, collectionName, collectionNameCn, contentType, wipId) =>
            $emit(
              'load-page',
              category,
              collectionName,
              collectionNameCn,
              contentType,
              wipId,
              getLastUpdatedByTitleFromCollection(collectionName),
              result.rank
            )
        "
      />
    </template>
  </div>
  <div v-else class="status-container">
    <XStatus v-if="isSearching" type="loading">　</XStatus>
    <XStatus v-else type="notFound">
      No matching results
      <template #description>Try searching different keywords</template>
    </XStatus>
  </div>
</template>

<script lang="ts">
import '@/assets/scss/scroll_bar.scss';
import { defineComponent } from 'vue';
import { v4 as uuidv4 } from 'uuid';
import { XIconButton, XStatus } from '@asus-aics/xui';
import SearchResultListItem from './SearchResultListItem.vue';
import { useSearchResultDataStore } from '@/store/searchResultData';
import { storeToRefs } from 'pinia';
import { useCollectionDrawerData } from '@/store/collectionDrawerData';

export default defineComponent({
  name: 'SearchResultList',
  components: { XIconButton, SearchResultListItem, XStatus },
  props: {
    isSearching: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['close-list', 'load-page'],
  setup() {
    const searchResultStore = useSearchResultDataStore();
    const { searchResults } = storeToRefs(searchResultStore);
    const collectionDrawerDataStore = useCollectionDrawerData();
    const getLastUpdatedByTitleFromCollection = (title) => collectionDrawerDataStore.getLastUpdatedByTitle(title);
    // Load search results from store

    return {
      searchResults,
      uuidv4,
      getLastUpdatedByTitleFromCollection,
    };
  },
});
</script>

<style scoped lang="scss">
.title-bar {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 8px 0;
}

.title-text {
  color: var(--xv-text--high-emphasis-text);
}

.list-item-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  scrollbar-gutter: stable;
  // height: 100%;
}

.status-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
</style>
