import { defineStore } from 'pinia';

interface ModalOptions {
  title: string;
  description: string;
  primaryButtonText: string;
  secondaryButtonText: string;
  primaryAction: () => void;
}

interface FullResourceContentModalOptions {
  propertyName: string;
  propertyNameCn: string;
  parent: string;
  type: string;
  required: boolean;
  default: string;
  source: string;
  sourceNote: string;
  note: string;
  tags: string;
}

interface HospitalSelectorModalOptions {
  hospitalList: string[];
}

const getDefaultStringIfEmptyOrNull = (str: string | null | undefined): string => {
  if (str === null || str === undefined || str === '') {
    return '--';
  }
  return str;
};

export const useModalState = defineStore('modalState', {
  state: () => ({
    showTwoButtonWarningModal: false,
    twoButtonWarningModalOptions: {} as ModalOptions,

    showFullResourceContentModal: false,
    fullResourceContentModalOptions: {} as FullResourceContentModalOptions,

    showHospitalSelectorModal: false,
    hospitalSelectorModalOptions: { hospitalList: [] } as HospitalSelectorModalOptions,
  }),
  getters: {},
  actions: {
    onShowTwoButtonWarningModalClick(_options: ModalOptions) {
      this.showTwoButtonWarningModal = true;
      this.twoButtonWarningModalOptions.title = _options.title;
      this.twoButtonWarningModalOptions.description = _options.description;
      this.twoButtonWarningModalOptions.primaryButtonText = _options.primaryButtonText;
      this.twoButtonWarningModalOptions.secondaryButtonText = _options.secondaryButtonText;
      this.twoButtonWarningModalOptions.primaryAction = _options.primaryAction;
    },
    onCloseTwoButtonWarningModal() {
      this.showTwoButtonWarningModal = false;
    },
    onShowFullResourceContentModalClick(_options: FullResourceContentModalOptions) {
      this.showFullResourceContentModal = true;
      this.fullResourceContentModalOptions.propertyName = getDefaultStringIfEmptyOrNull(_options.propertyName);
      this.fullResourceContentModalOptions.propertyNameCn = getDefaultStringIfEmptyOrNull(_options.propertyNameCn);
      this.fullResourceContentModalOptions.parent = getDefaultStringIfEmptyOrNull(_options.parent);
      this.fullResourceContentModalOptions.type = getDefaultStringIfEmptyOrNull(_options.type);
      this.fullResourceContentModalOptions.required = _options.required;
      this.fullResourceContentModalOptions.default = getDefaultStringIfEmptyOrNull(_options.default);
      this.fullResourceContentModalOptions.source = getDefaultStringIfEmptyOrNull(_options.source);
      this.fullResourceContentModalOptions.sourceNote = getDefaultStringIfEmptyOrNull(_options.sourceNote);
      this.fullResourceContentModalOptions.note = getDefaultStringIfEmptyOrNull(_options.note);
      this.fullResourceContentModalOptions.tags = getDefaultStringIfEmptyOrNull(_options.tags);
    },
    onCloseFullResourceContentModal() {
      this.showFullResourceContentModal = false;
    },
    setHospitalSelectorModalOptions(_options: HospitalSelectorModalOptions) {
      this.hospitalSelectorModalOptions.hospitalList = _options.hospitalList;
    },
    onShowHospitalSelectorModal() {
      this.showHospitalSelectorModal = true;
    },
    onCloseHospitalSelectorModal() {
      this.showHospitalSelectorModal = false;
    },
  },
});
