interface SignUpErrorMessages {
  username?: string[];
  password?: string[];
  firstName?: string[];
  lastName?: string[];
}
class SignUpError extends Error {
  signUpErrorMessages: SignUpErrorMessages;

  constructor(errorMessage: string, errorMessageGroup: SignUpErrorMessages) {
    super(errorMessage);
    this.name = 'SignUpError';
    this.signUpErrorMessages = errorMessageGroup;
  }
}

class TooManyRequestsError extends Error {
  statusCode: number;

  constructor(message: string) {
    super(message);
    this.name = 'TooManyRequestsError';
    this.statusCode = 429;
  }
}

const getSignUpErrorMessages = (error: SignUpError) => {
  const errorMessages = [];
  const firstNameError = error.signUpErrorMessages.firstName?.length > 0;
  const lastNameError = error.signUpErrorMessages.lastName?.length > 0;
  const emailError = error.signUpErrorMessages.username?.length > 0;
  const passwordError = error.signUpErrorMessages.password?.length > 0;

  if (firstNameError) {
    error.signUpErrorMessages.firstName.forEach((message) => {
      errorMessages.push(message);
    });
  }
  if (lastNameError) {
    error.signUpErrorMessages.lastName.forEach((message) => {
      errorMessages.push(message);
    });
  }
  if (emailError) {
    error.signUpErrorMessages.username.forEach((message) => {
      errorMessages.push(message);
    });
  }
  if (passwordError) {
    error.signUpErrorMessages.password.forEach((message) => {
      errorMessages.push(message);
    });
  }

  return { firstNameError, lastNameError, emailError, passwordError, errorMessages };
};

export { SignUpErrorMessages, SignUpError, TooManyRequestsError, getSignUpErrorMessages };
