<template>
  <XTable
    lazy-render
    :options="resourceContentTableColSchema"
    :data="resourceContent"
    :custom-category-options="customCategoryOptions"
    key-index="propertyName"
    childrenKey="children"
    :decreaseTreeLevel="2"
    :toggleOptions="{
      isDefaultExpand: resourceContentCount < 500,
      toggleIndex: 'propertyName',
    }"
    :enable-shadow="!isResourceContentEmpty"
    :style="emptyDataStyle"
    interactive
    :interactiveOptions="{ rowColor: 'rgba(38, 38, 38, 0.09)', columnColor: null, intersectionColor: null }"
    @row-click="(item, _, event: MouseEvent) => {
      const element = (event.target as HTMLElement);
      if (element.classList.contains('action-button') || element.classList.contains('x-icon')) return;
      handleRowClick(item);
      }"
    @row-context-menu="handleRowContextMenu"
  >
    <template #cell-propertyName="{ item }">
      <XTooltipWithLineLimits
        v-if="item.propertyName !== '' && item.propertyName !== undefined"
        :content="item.propertyName"
        ellipsis
        :options="{
          theme: 'blue-card',
          arrow: false,
        }"
      >
        {{ item.propertyName }}
      </XTooltipWithLineLimits>
      <span v-else>--</span>
    </template>
    <template #cell-propertyNameCn="{ item }">
      <XTooltipWithLineLimits
        v-if="item.propertyNameCn !== '' && item.propertyNameCn !== undefined"
        :content="item.propertyNameCn"
        ellipsis
        :options="{
          theme: 'blue-card',
          arrow: false,
        }"
      >
        {{ item.propertyNameCn }}
      </XTooltipWithLineLimits>
      <span v-else>--</span>
    </template>

    <template #cell-type="{ item }">
      <XTooltip
        v-if="item.fieldDataType !== '' && item.fieldDataType !== undefined"
        :content="getTypeTooltip(item)"
        ellipsis
        :options="{
          theme: 'blue-card',
          arrow: false,
        }"
      >
        {{ getTypeText(item) }}
      </XTooltip>
      <span v-else>--</span>
    </template>
    <template #cell-required="{ item }">
      <XTooltipWithLineLimits
        :content="covertRequiredText(item.required)"
        ellipsis
        :options="{
          theme: 'blue-card',
          arrow: false,
        }"
      >
        {{ covertRequiredText(item.required) }}
      </XTooltipWithLineLimits>
    </template>
    <template #cell-source="{ item }">
      <XTooltipWithLineLimits
        v-if="item.source !== '' && item.source !== undefined"
        :content="item.source"
        ellipsis
        :options="{
          theme: 'blue-card',
          arrow: false,
        }"
      >
        {{ item.source }}
      </XTooltipWithLineLimits>
      <span v-else>--</span>
    </template>
    <template #cell-sourceNote="{ item }">
      <XTooltipWithLineLimits
        v-if="item.sourceNote !== '' && item.sourceNote !== undefined"
        :content="item.sourceNote"
        ellipsis
        :options="{
          theme: 'blue-card',
          arrow: false,
        }"
      >
        {{ item.sourceNote }}
      </XTooltipWithLineLimits>
      <span v-else>--</span>
    </template>
    <template #cell-note="{ item }">
      <XTooltipWithLineLimits
        v-if="item.note !== '' && item.note !== undefined"
        :content="item.note"
        ellipsis
        :options="{
          theme: 'blue-card',
          arrow: false,
        }"
      >
        {{ item.note }}
      </XTooltipWithLineLimits>
      <span v-else>--</span>
    </template>
    <template #cell-tags="{ item }">
      <XTooltip
        v-if="item.tags !== '' && item.tags !== undefined"
        :content="item.tags"
        ellipsis
        :options="{
          theme: 'blue-card',
          arrow: false,
        }"
      >
        {{ item.tags }}
      </XTooltip>
      <span v-else>--</span>
    </template>
    <template #cell-action="{ item }">
      <div v-if="user.isAdmin && wipId" class="action-button-container" @click.stop>
        <XDropdown>
          <template #default="{ isMenuOpened }"
            ><XIconButton icon="more-hori" :style="{ width: '32px', height: '32px' }"></XIconButton
          ></template>
          <template #dropdownList="{ hideMenu }">
            <XList>
              <XListItem
                @click.stop="
                  () => {
                    editResourceContentItem(item);
                    hideMenu();
                  }
                "
                >Edit</XListItem
              >
              <XListItem
                @click.stop="
                  () => {
                    copyResourceContentItem(item);
                    hideMenu();
                  }
                "
                >Copy</XListItem
              >
              <XListItem
                @click.stop="
                  () => {
                    deleteResourceContentField(item.id);
                    hideMenu();
                  }
                "
                >Delete</XListItem
              >
            </XList>
          </template>
        </XDropdown>
      </div>
      <XIconButton v-if="user.isAdmin && !wipId" icon="edit" @click="editResourceContentItem(item)"></XIconButton>
    </template>
  </XTable>
  <div v-if="isResourceContentEmpty" class="empty-state-block">
    <span class="xv-body--body-lg low-emphasis-text">No items</span>
  </div>
  <XList ref="contextmenuRef">
    <XListItem
      @click="
        () => {
          editResourceContentItem(currentItem);
          hideContextmenu();
        }
      "
      >Edit</XListItem
    >
    <XListItem
      v-if="wipId"
      @click="
        () => {
          copyResourceContentItem(currentItem);
          hideContextmenu();
        }
      "
      >Copy</XListItem
    >
    <XListItem
      v-if="wipId"
      @click="
        () => {
          deleteResourceContentField(currentItem.id);
          hideContextmenu();
        }
      "
      >Delete</XListItem
    >
  </XList>
</template>

<script lang="ts">
import { ref, defineComponent, watch, onMounted, nextTick, computed } from 'vue';
import { XTable, XTooltip, XDropdown, XList, XListItem, XIconButton } from '@asus-aics/xui';
import XTooltipWithLineLimits from './XTooltipWithLineLimits.vue';
import XTextAreaWithLineLimits from './XTextAreaWithLineLimits.vue';
import {
  useResourceContentPageDataStore,
  ResourceContentWithMaxLine,
  ResourceContent,
} from '@/store/resourceContentPageData';
import { storeToRefs } from 'pinia';

import { resourceContentTableColSchema } from '@/utils/resourceContentTableOptions.options';
import { deleteResourceContentAndUpdateStore } from '@/utils/dataService';
import { useModalState } from '@/store/modalState';
import { useContentPageDataStore } from '@/store/contentPageData';
import { useContextmenu } from '@/composables/shared/useContextMenu';
import { useInfoDataStore } from '@/store/infoData';
export default defineComponent({
  name: 'ResourceContentTab',
  components: {
    XTable,
    XTooltip,
    XDropdown,
    XList,
    XListItem,
    XTooltipWithLineLimits,
    XTextAreaWithLineLimits,
    XIconButton,
  },
  props: {
    globalIsEditing: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['edit-resource-content-item', 'delete-resource-content-item', 'copy-resource-content-item'],
  setup(props, { emit }) {
    const infoStore = useInfoDataStore();
    const { user } = storeToRefs(infoStore);
    const isEditing = ref(false);
    const customCategoryOptions = {
      isHeader: (item: Record<string, unknown>) => item.categoryHeader,
      tdClass: (item: Record<string, unknown>, _: string) => {
        const cssClasses = [];
        const layer = String(item.layer);
        cssClasses.push(`table-custom-category--layer-${layer}`);
        return cssClasses.join(' ');
      },
    };

    const covertRequiredText = (required: boolean) => {
      if (required == undefined) return '--';
      return required ? 'TRUE' : 'FALSE';
    };

    const contentPageDataStore = useContentPageDataStore();
    const resourceContentPageDataStore = useResourceContentPageDataStore();
    const { resourceContent, editingResourceContent, resourceContentCount } = storeToRefs(resourceContentPageDataStore);
    const { wipId } = storeToRefs(contentPageDataStore);
    const isResourceContentEmpty = computed(() => {
      return resourceContent.value.length === 0;
    });

    const emptyDataStyle = computed(() => {
      if (isResourceContentEmpty.value) {
        return {
          height: '50px',
        };
      }
      return {};
    });

    const updateMaxLine = (item: ResourceContentWithMaxLine, newMaxLine: number) => {
      item.maxLine = newMaxLine;
    };

    const editResourceContentItem = (item: ResourceContent) => {
      emit('edit-resource-content-item', item);
      nextTick(() => {
        const textAreaHtmlElement = document.querySelector('#resource-content-edit-modal-property-name');
        (textAreaHtmlElement as HTMLTextAreaElement).focus();
      });
    };
    const copyResourceContentItem = (item: ResourceContent) => {
      emit('copy-resource-content-item', item);
    };

    const deleteResourceContentField = (id: number) => {
      const modalState = useModalState();
      const { onShowTwoButtonWarningModalClick } = modalState;
      onShowTwoButtonWarningModalClick({
        title: 'Are you sure you want to delete this property?',
        description: '',
        primaryButtonText: 'Delete',
        secondaryButtonText: 'Cancel',
        primaryAction: () => {
          deleteResourceContentAndUpdateStore(id)
            .then(() => {})
            .catch((err) => {
              console.error(err);
            });
        },
      });
    };

    const getTypeTooltip = (item: ResourceContent) => {
      if (item.enum && item.enum.length > 0) {
        const enumConcatString = () => {
          let enumString = '';
          item.enum.forEach((element) => {
            enumString += `${element}|\n`;
          });
          return enumString.slice(0, -2);
        };
        return `${item.fieldDataType}\n\nEnum: ${enumConcatString()}`;
      }
      return item.fieldDataType;
    };

    const getTypeText = (item: ResourceContent) => {
      if (item.enum && item.enum.length > 0) {
        return `Enum[${item.fieldDataType}]`;
      } else {
        return item.fieldDataType;
      }
    };

    const handleRowClick = (item: ResourceContent) => {
      const modalState = useModalState();
      const { onShowFullResourceContentModalClick } = modalState;
      onShowFullResourceContentModalClick({
        propertyName: item.propertyName,
        propertyNameCn: item.propertyNameCn,
        parent: item.parentName,
        type: item.fieldDataType,
        required: item.required,
        source: item.source,
        sourceNote: item.sourceNote,
        note: item.note,
        tags: item.tags,
        default: item.defaultValue,
      });
    };
    const contextMenuZIndex = 900;
    const { contextmenuRef, handleContextmenu, hideContextmenu } = useContextmenu(true, { zIndex: contextMenuZIndex });
    const currentItem = ref<ResourceContent>(null);

    const handleRowContextMenu = async (entry: ResourceContent, key: string, event: MouseEvent) => {
      if (!user.value.isAdmin) return;
      currentItem.value = entry;
      handleContextmenu(event);
    };

    onMounted(() => {
      nextTick(() => {
        isEditing.value = props.globalIsEditing;
      });
    });
    watch(
      () => props.globalIsEditing,
      (newBool, _) => {
        isEditing.value = newBool;
      }
    );
    return {
      resourceContentTableColSchema,
      customCategoryOptions,
      isEditing,
      covertRequiredText,
      resourceContent,
      updateMaxLine,
      editingResourceContent,
      isResourceContentEmpty,
      emptyDataStyle,
      editResourceContentItem,
      deleteResourceContentField,
      getTypeTooltip,
      getTypeText,
      wipId,
      copyResourceContentItem,
      handleRowClick,
      contextmenuRef,
      hideContextmenu,
      handleRowContextMenu,
      currentItem,
      user,
      resourceContentCount,
    };
  },
});
</script>

// eslint-disable vue-scoped-css/enforce-style-type
<style lang="scss">
.x-table-td {
  &:first-child {
    &.table-custom-category {
      &--layer-1 {
        padding-left: 0px;
      }
      &--layer-2 {
        padding-left: 32px;
      }
      &--layer-3 {
        padding-left: 64px;
      }
      &--layer-4 {
        padding-left: 96px;
      }
    }
  }
  &.table-custom-category {
    &--layer-1 {
      background-color: #fafafa;
    }
    &--layer-2 {
      background-color: #efeff0;
    }
    &--layer-3 {
      background-color: #e4e4e6;
    }
    &--layer-4 {
      background-color: #d8d8d8;
    }
  }
}
</style>

<style scoped lang="scss">
.property-name-text {
  overflow: hidden;
  overflow-wrap: break-word;
  color: var(--xv-text--high-emphasis-text);
}

.source-note-text {
  overflow: hidden;
  overflow-wrap: break-word;
}

.low-emphasis-text {
  color: var(--xv-text--low-emphasis-text);
}

.empty-state-block {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.action-button-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
</style>
